import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import { PhotoCamera, Phone as PhoneIcon } from '@mui/icons-material';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import { Student, Board } from '../types';
import imageCompression from 'browser-image-compression';

// Constants
const STUDENT_STATUSES = ['active', 'inactive', 'cancelled', 'admission_due', 'admission_confirmed'] as const;
const BOARDS: Board[] = ['IGCSE', 'CBSE', 'SSC', 'NIOS', 'IB', 'AS/A IBDP', 'Others'];
const GRADES = [
  'Playschool',
  'Nursery',
  'Jr. KG',
  'Sr. KG',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12'
];

interface StudentEditDialogProps {
  open: boolean;
  student: Student | null;
  onClose: () => void;
  onUpdate: () => void;
}

export default function StudentEditDialog({ 
  open, 
  student, 
  onClose, 
  onUpdate 
}: StudentEditDialogProps) {
  const [editedStudent, setEditedStudent] = useState<Student | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (student) {
      setEditedStudent(student);
    }
  }, [student]);

  const handleSave = async () => {
    if (!editedStudent) return;

    setLoading(true);
    try {
      const studentRef = doc(db, 'students', editedStudent.id);
      await updateDoc(studentRef, {
        ...editedStudent,
        updatedAt: new Date().toISOString()
      });
      
      toast.success('Student updated successfully');
      onUpdate();
      onClose();
    } catch (error) {
      console.error('Error updating student:', error);
      toast.error('Failed to update student');
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files[0] || !editedStudent) return;

    setUploadingPhoto(true);
    try {
      const file = event.target.files[0];
      
      // Compress image before upload
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 800
      });

      const storageRef = ref(storage, `student-photos/${editedStudent.id}`);
      
      // Delete existing photo if any
      if (editedStudent.photo) {
        try {
          await deleteObject(ref(storage, editedStudent.photo));
        } catch (error) {
          console.error('Error deleting old photo:', error);
        }
      }

      // Upload new photo
      const snapshot = await uploadBytes(storageRef, compressedFile);
      const photoURL = await getDownloadURL(snapshot.ref);

      setEditedStudent({
        ...editedStudent,
        photo: photoURL
      });

      toast.success('Photo uploaded successfully');
    } catch (error) {
      console.error('Error uploading photo:', error);
      toast.error('Failed to upload photo');
    } finally {
      setUploadingPhoto(false);
    }
  };

  if (!editedStudent) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Edit Student
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {/* Student Photo */}
          <Grid item xs={12}>
            <Box 
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 2
              }}
            >
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: '50%',
                  overflow: 'hidden',
                  position: 'relative',
                  bgcolor: 'grey.200',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {editedStudent.photo ? (
                  <img 
                    src={editedStudent.photo} 
                    alt={editedStudent.name}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                ) : (
                  <Typography variant="h3" color="text.secondary">
                    {editedStudent.name?.charAt(0)}
                  </Typography>
                )}
              </Box>
              <Box>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handlePhotoUpload}
                />
                <Button
                  variant="outlined"
                  startIcon={<PhotoCamera />}
                  onClick={() => fileInputRef.current?.click()}
                  disabled={uploadingPhoto}
                >
                  {uploadingPhoto ? 'Uploading...' : 'Change Photo'}
                </Button>
              </Box>
            </Box>
          </Grid>

          {/* Basic Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Basic Information
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              fullWidth
              value={editedStudent.name || ''}
              onChange={(e) => setEditedStudent({ ...editedStudent, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Student ID"
              fullWidth
              value={editedStudent.studentId || ''}
              onChange={(e) => setEditedStudent({ ...editedStudent, studentId: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Grade</InputLabel>
              <Select
                value={editedStudent.grade || ''}
                label="Grade"
                onChange={(e) => setEditedStudent({ ...editedStudent, grade: e.target.value })}
              >
                {GRADES.map((grade) => (
                  <MenuItem key={grade} value={grade}>
                    {grade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Board</InputLabel>
              <Select
                value={editedStudent.board || ''}
                label="Board"
                onChange={(e) => setEditedStudent({ ...editedStudent, board: e.target.value as Board })}
              >
                {BOARDS.map((board) => (
                  <MenuItem key={board} value={board}>
                    {board}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="School"
              fullWidth
              value={editedStudent.school || ''}
              onChange={(e) => setEditedStudent({ ...editedStudent, school: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Division"
              fullWidth
              value={editedStudent.division || ''}
              onChange={(e) => setEditedStudent({ ...editedStudent, division: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={editedStudent.status || 'active'}
                label="Status"
                onChange={(e) => setEditedStudent({ ...editedStudent, status: e.target.value })}
              >
                {STUDENT_STATUSES.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              fullWidth
              multiline
              rows={3}
              value={editedStudent.notes || ''}
              onChange={(e) => setEditedStudent({ ...editedStudent, notes: e.target.value })}
            />
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="WhatsApp Link"
              fullWidth
              value={editedStudent.whatsappLink || ''}
              onChange={(e) => setEditedStudent({ ...editedStudent, whatsappLink: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSave}
          variant="contained"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}