import axios from 'axios';

const NEXTCLOUD_URL = 'https://drive.inkstall.us';
const WEBDAV_PATH = '/remote.php/webdav';

// Get credentials from Vite environment variables
const NEXTCLOUD_USERNAME = import.meta.env.VITE_NEXTCLOUD_USERNAME;
const NEXTCLOUD_PASSWORD = import.meta.env.VITE_NEXTCLOUD_PASSWORD;

// Debug log
console.log('Environment variables check:', {
  username: NEXTCLOUD_USERNAME ? 'Set' : 'Not set',
  password: NEXTCLOUD_PASSWORD ? 'Set' : 'Not set'
});

// Create axios instance with default config
const nextcloudAxios = axios.create({
  baseURL: NEXTCLOUD_URL,
  headers: {
    'Authorization': `Basic ${btoa(`${NEXTCLOUD_USERNAME}:${NEXTCLOUD_PASSWORD}`)}`,
    'OCS-APIRequest': 'true',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, MKCOL, OPTIONS',
    'Access-Control-Allow-Headers': 'Authorization, Content-Type',
  },
  withCredentials: true
});

export const uploadFileToNextcloud = async (file: File, path: string): Promise<void> => {
  try {
    if (!NEXTCLOUD_USERNAME || !NEXTCLOUD_PASSWORD) {
      throw new Error('Nextcloud credentials not configured');
    }

    const fullUrl = `${WEBDAV_PATH}/${path}`;
    
    // First try an OPTIONS request
    try {
      await nextcloudAxios.options(fullUrl);
    } catch (error) {
      console.log('Options request failed, continuing with upload');
    }

    const response = await nextcloudAxios.put(fullUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    if (response.status !== 201 && response.status !== 204) {
      throw new Error('Failed to upload file to Nextcloud');
    }
    
    console.log('File uploaded successfully to Nextcloud');
  } catch (error) {
    console.error('Error uploading to Nextcloud:', error);
    throw error;
  }
};

export const getNextcloudPath = (
  subject: string,
  grade: string,
  type: 'Test Sheet' | 'K Sheet',
  fileName: string
): string => {
  // Clean up grade if it's a special case
  let cleanGrade = grade;
  if (grade.toLowerCase().includes('jr kg')) {
    cleanGrade = 'Jr KG';
  } else if (grade.toLowerCase().includes('sr kg')) {
    cleanGrade = 'Sr KG';
  }

  return `Tests and K-Sheets/${subject}/${type}/${cleanGrade}/${fileName}`;
};

export const createStudentFolder = async (studentName: string, board: string): Promise<void> => {
  try {
    if (!NEXTCLOUD_USERNAME || !NEXTCLOUD_PASSWORD) {
      console.error('Missing credentials:', {
        username: !!NEXTCLOUD_USERNAME,
        password: !!NEXTCLOUD_PASSWORD
      });
      throw new Error('Nextcloud credentials not configured');
    }

    const folderPath = `${board}/${studentName}`;
    const fullUrl = `${WEBDAV_PATH}/${folderPath}`;

    // First try an OPTIONS request
    try {
      await nextcloudAxios.options(fullUrl);
    } catch (error) {
      console.log('Options request failed, continuing with folder creation');
    }

    // Create the folder
    await nextcloudAxios.request({
      method: 'MKCOL',
      url: fullUrl,
    });

    console.log('Student folder created successfully');
  } catch (error) {
    console.error('Error creating student folder:', error);
    throw error;
  }
};
