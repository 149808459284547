import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  DashboardIcon,
  AttendanceIcon,
  UpdatesIcon,
  TasksIcon,
  StudentsIcon,
  TrophyIcon,
  PerformanceIcon,
  AdminIcon,
  SettingsIcon,
  LogoutIcon,
  ClipboardIcon,
  TrackerIcon,
  UserIcon
} from './icons';

interface SidebarProps {
  userRole: 'teacher' | 'admin';
  onSignOut: () => void;
  onNavigate?: () => void;
  setIsSidebarOpen: (isOpen: boolean) => void;
}

export default function Sidebar({ userRole, onSignOut, setIsSidebarOpen }: SidebarProps) {
  const location = useLocation();
  const navigate = useNavigate();
  
  const menuItems = [
    { id: '/', label: 'Today Attendance', icon: DashboardIcon },
    { id: '/attendance', label: 'My Attendance', icon: AttendanceIcon },
    { id: '/updates', label: 'Daily Updates', icon: UpdatesIcon },
    { id: '/test-submission', label: 'Test Submission', icon: ClipboardIcon },
    { id: '/tasks', label: 'Tasks', icon: TasksIcon },
    { id: '/students', label: 'Students', icon: StudentsIcon, adminOnly: true },
    { id: '/students-db', label: 'Students Database', icon: StudentsIcon, adminOnly: true },
    { id: '/students-tracker', label: 'Students Tracker', icon: TrackerIcon, adminOnly: true },
    { id: '/activity', label: (
      <div className="flex items-center gap-2">
        <span>Activity Points</span>
        <img 
          src="https://static.wixstatic.com/media/abaee8_d543e28c553d4b91a517d1781404fc35~mv2.png" 
          alt="Points Coin"
          className="w-5 h-5 object-contain"
        />
      </div>
    ), icon: TrophyIcon },
    { id: '/performance', label: 'Student Performance', icon: PerformanceIcon },
    { id: '/admin', label: 'Admin', icon: AdminIcon, adminOnly: true },
    { id: '/settings', label: 'Settings', icon: SettingsIcon, adminOnly: true },
    { id: '/teachers', label: 'Teachers', icon: UserIcon, adminOnly: true }
  ];

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsSidebarOpen(false);
  };

  return (
    <div className="h-full flex flex-col bg-white">
      <div className="p-6 border-b">
        <img 
          src="https://static.wixstatic.com/shapes/abaee8_dc6d6d64fba440848d2b9769e4f2e998.svg" 
          alt="Inkstall Logo" 
          className="w-32 h-auto mb-4"
        />
        <p className="text-sm text-gray-600 mt-1 capitalize">{userRole} Dashboard</p>
      </div>
      
      <nav className="flex-1 mt-6 overflow-y-auto">
        {menuItems
          .filter(item => 
            (!item.adminOnly || userRole === 'admin')
          )
          .map((item) => {
            const Icon = item.icon;
            const isActive = location.pathname === item.id;
            return (
              <button
                key={item.id}
                onClick={() => handleNavigation(item.id)}
                className={`w-full flex items-center px-6 py-3 text-gray-700 hover:bg-primary/10 transition-colors ${
                  isActive ? 'bg-yellow-50 border-r-4 border-yellow-400 font-medium' : ''
                }`}
              >
                <Icon className="w-5 h-5 mr-3 shrink-0" />
                <span className="truncate">{item.label}</span>
              </button>
            );
          })}
      </nav>

      <div className="border-t">
        <button 
          onClick={onSignOut}
          className="w-full flex items-center px-6 py-4 text-gray-700 hover:bg-red-50 transition-colors"
        >
          <LogoutIcon className="w-5 h-5 mr-3 shrink-0" />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
}