import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Stack,
  Chip,
  Divider,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Switch,
  FormControlLabel,
  Tooltip,
  Grid
} from '@mui/material';
import { X, Plus, Trash2, Save } from 'lucide-react';
import { Student } from '../types';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface FeeDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  student: Student | null;
  onUpdate?: (updatedStudent: Student) => void;
  onStudentChange?: (updatedStudent: Student) => void;
}

const AVAILABLE_SUBJECTS = [
  'Mathematics',
  'Physics',
  'Chemistry',
  'Biology',
  'Science',
  'Computer Science',
  'Economics',
  'Business Studies',
  'Accountancy',
  'English',
  'Hindi',
  'Social Studies',
  'Environmental Science',
  'Political Science',
  'Geography',
  'History'
];

const calculateDaysBetween = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffTime = Math.abs(end.getTime() - start.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end dates
};

const getBoardBaseFee = (board: string, grade: number, subject: string): number => {
  switch (board?.toLowerCase()) {
    case 'igcse':
      const baseFee = 1200; // Base fee for Grade 1 and below
      if (grade <= 1) return baseFee;
      // Calculate compound increase for grades above 1
      const multiplier = Math.pow(1.1, grade - 1);
      return baseFee * multiplier;
    
    case 'ib':
      return 1500;
    
    case 'nios':
      return 2000;
    
    case 'as/a ibdp':
    case 'as/a':
    case 'ibdp':
      if (grade <= 10) return 0;
      if (grade === 11) return 7000;
      if (grade === 12) return 8000;
      return 0;
    
    default:
      return 0;
  }
};

export default function FeeDetailsDialog({ open, onClose, student, onUpdate, onStudentChange }: FeeDetailsDialogProps) {
  const [editedStudent, setEditedStudent] = useState<Student | null>(null);
  const [newSubject, setNewSubject] = useState('');
  const [oneToOneEnabled, setOneToOneEnabled] = useState(false);
  const [oneToOnePercentage, setOneToOnePercentage] = useState(0);
  const [scholarshipEnabled, setScholarshipEnabled] = useState(false);
  const [scholarshipPercentage, setScholarshipPercentage] = useState(0);
  const [scholarshipNotes, setScholarshipNotes] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [gstEnabled, setGstEnabled] = useState(false);

  const feeDetails = useMemo(() => {
    if (!editedStudent || !startDate || !endDate) return null;

    // Calculate total days
    const totalDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    if (totalDays <= 0) return null;

    // Calculate daily rate for each subject
    const subjectFees = editedStudent.subjects.map(subject => {
      const monthlyFee = 1200; // Fixed monthly fee as per your system
      const dailyFee = Math.round((monthlyFee * 12) / 365); // More accurate daily rate calculation
      
      return {
        subject: typeof subject === 'string' ? subject : subject.name,
        monthlyFee,
        dailyFee,
        totalFee: dailyFee * totalDays
      };
    });

    // Calculate total base amount
    const subtotal = subjectFees.reduce((sum, subject) => sum + subject.totalFee, 0);

    // Calculate scholarship amount if enabled
    const scholarshipDisc = scholarshipEnabled && scholarshipPercentage > 0 
      ? Math.round(subtotal * (scholarshipPercentage / 100)) 
      : 0;

    // Calculate amount after scholarship
    const baseAmount = subtotal - scholarshipDisc;

    // Calculate GST if enabled
    const gstAmount = gstEnabled ? Math.round(baseAmount * 0.18) : 0;

    // Calculate final total
    const total = baseAmount + gstAmount;

    return {
      subjectFees,
      subtotal,
      scholarshipPercentage: scholarshipEnabled ? scholarshipPercentage : 0,
      scholarshipDisc,
      baseAmount,
      gstAmount,
      total,
      totalDays
    };
  }, [editedStudent, startDate, endDate, scholarshipEnabled, scholarshipPercentage, gstEnabled]);

  useEffect(() => {
    if (student) {
      setEditedStudent(student);
      setScholarshipEnabled(student.showScholarship || false);
      setScholarshipPercentage(student.scholarshipPercentage || 10);
      setOneToOneEnabled(student.oneToOneEnabled || false);
      setOneToOnePercentage(student.oneToOnePercentage || 10);
    }
  }, [student]);

  const handleClose = () => {
    // Reset all states to their initial values from student data
    if (student) {
      setEditedStudent(student);
      setScholarshipEnabled(student.showScholarship || false);
      setScholarshipPercentage(student.scholarshipPercentage || 10);
      setOneToOneEnabled(student.oneToOneEnabled || false);
      setOneToOnePercentage(student.oneToOnePercentage || 10);
    }
    onClose();
  };

  const handleAddSubject = () => {
    if (!newSubject || !editedStudent) return;

    const updatedSubjects = [...(editedStudent.subjects || [])];
    const existingSubject = updatedSubjects.find(s => 
      (typeof s === 'string' ? s : s.name) === newSubject
    );

    if (existingSubject) {
      toast.error('Subject already added');
      return;
    }

    // Use current dates or defaults
    const currentStartDate = startDate || new Date();
    const currentEndDate = endDate || new Date(new Date().setMonth(new Date().getMonth() + 12));

    updatedSubjects.push({
      name: newSubject,
      startDate: currentStartDate.toISOString(),
      endDate: currentEndDate.toISOString()
    });

    setEditedStudent({
      ...editedStudent,
      subjects: updatedSubjects
    });
    setNewSubject('');
  };

  const handleRemoveSubject = (index: number) => {
    const updatedSubjects = [...(editedStudent.subjects || [])];
    updatedSubjects.splice(index, 1);
    setEditedStudent({
      ...editedStudent,
      subjects: updatedSubjects
    });
  };

  const handleSave = async () => {
    if (!editedStudent || !startDate || !endDate) return;

    const updatedStudent = {
      ...editedStudent,
      oneToOneEnabled,
      oneToOnePercentage,
      feeCalculation: {
        ...feeDetails,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        oneToOne: {
          enabled: oneToOneEnabled,
          percentage: oneToOnePercentage
        },
        scholarshipEnabled,
        scholarshipPercentage,
        scholarshipNotes,
        gstEnabled
      }
    };

    try {
      // Update student in database
      const studentRef = doc(db, 'students', editedStudent.id);
      await updateDoc(studentRef, updatedStudent);
      
      if (onUpdate) {
        onUpdate(updatedStudent);
      }
      onClose();
      toast.success('Fee details updated successfully');
    } catch (error) {
      console.error('Error updating fee details:', error);
      toast.error('Failed to update fee details');
    }
  };

  const handleScholarshipToggle = () => {
    const newValue = !scholarshipEnabled;
    setScholarshipEnabled(newValue);
    
    // Update the edited student with new scholarship value
    if (editedStudent && onStudentChange) {
      const updatedStudent = {
        ...editedStudent,
        showScholarship: newValue,
        scholarshipPercentage: newValue ? scholarshipPercentage : 0,
        feeCalculation: {
          ...editedStudent.feeCalculation,
          discount: {
            ...editedStudent.feeCalculation?.discount,
            scholarshipDiscount: {
              percentage: newValue ? scholarshipPercentage : 0,
              amount: 0 // This will be recalculated
            }
          }
        }
      };
      onStudentChange(updatedStudent);
    }
  };

  const handleScholarshipPercentageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newPercentage = Number(event.target.value);
    setScholarshipPercentage(newPercentage);
    
    if (editedStudent && onStudentChange) {
      const updatedStudent = {
        ...editedStudent,
        scholarshipPercentage: newPercentage,
        feeCalculation: {
          ...editedStudent.feeCalculation,
          discount: {
            ...editedStudent.feeCalculation?.discount,
            scholarshipDiscount: {
              percentage: newPercentage,
              amount: 0 // This will be recalculated
            }
          }
        }
      };
      onStudentChange(updatedStudent);
    }
  };

  if (!editedStudent) return null;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5" component="div">
            Fee Details - {editedStudent.name}
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <X size={20} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        {/* Date Range Selection */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
            Fee Duration
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: !startDate,
                      helperText: !startDate ? 'Start date is required' : ''
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  minDate={startDate || undefined}
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: !endDate || (startDate && endDate && endDate < startDate),
                      helperText: !endDate ? 'End date is required' : 
                                (startDate && endDate && endDate < startDate) ? 'End date must be after start date' : ''
                    }
                  }}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>

        {/* Subject Management */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
            Manage Subjects
          </Typography>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <FormControl fullWidth size="small">
              <InputLabel>Add Subject</InputLabel>
              <Select
                value={newSubject}
                onChange={(e) => setNewSubject(e.target.value)}
                label="Add Subject"
              >
                {AVAILABLE_SUBJECTS.filter(subject => 
                  !editedStudent.subjects?.some(s => 
                    (typeof s === 'string' ? s : s.name) === subject
                  )
                ).map(subject => (
                  <MenuItem key={subject} value={subject}>{subject}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              startIcon={<Plus size={20} />}
              onClick={handleAddSubject}
              disabled={!newSubject}
            >
              Add
            </Button>
          </Stack>
        </Box>

        {/* GST Toggle */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={gstEnabled}
                onChange={(e) => setGstEnabled(e.target.checked)}
              />
            }
            label="Apply GST (18%)"
          />
        </Box>

        {/* Fee Details Section */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>Fee Details</Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Period: {startDate?.toLocaleDateString()} to {endDate?.toLocaleDateString()} ({feeDetails?.totalDays} days)
          </Typography>
          
          {/* Subject-wise Fees */}
          <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>Subject-wise Fees</Typography>
          {editedStudent?.subjects.map((subject, index) => {
            const subjectName = typeof subject === 'string' ? subject : subject.name;
            const feeInfo = feeDetails?.subjectFees.find(f => f.subject === subjectName);
            
            return (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2">{subjectName}</Typography>
                <Typography variant="body2">₹{feeInfo?.totalFee?.toLocaleString()}</Typography>
              </Box>
            );
          })}

          {/* Fee Breakdown */}
          <Box sx={{ mt: 2, p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography>Subtotal:</Typography>
                {scholarshipEnabled && scholarshipPercentage > 0 && (
                  <Typography color="error">Scholarship ({scholarshipPercentage}%):</Typography>
                )}
                {gstEnabled && (
                  <Typography>GST (18%):</Typography>
                )}
                <Typography sx={{ fontWeight: 'bold', mt: 1 }}>Total Amount:</Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <Typography>₹{feeDetails?.subtotal?.toLocaleString()}</Typography>
                {scholarshipEnabled && scholarshipPercentage > 0 && (
                  <Typography color="error">-₹{feeDetails?.scholarshipDisc?.toLocaleString()}</Typography>
                )}
                {gstEnabled && (
                  <Typography>₹{feeDetails?.gstAmount?.toLocaleString()}</Typography>
                )}
                <Typography sx={{ fontWeight: 'bold', mt: 1 }}>
                  ₹{feeDetails?.total?.toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Subject-wise Fee Breakdown */}
        <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', mb: 2, mt: 3 }}>
          Subject-wise Fee Breakdown
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: '#f8f9fa' }}>
                <TableCell>Subject</TableCell>
                <TableCell align="right">Monthly Fee</TableCell>
                <TableCell align="right">Days</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(editedStudent?.subjects || []).map((subject, index) => {
                const subjectName = typeof subject === 'string' ? subject : subject.name;
                const feeInfo = feeDetails?.subjectFees.find(f => f.subject === subjectName);
                
                return (
                  <TableRow key={index}>
                    <TableCell>{subjectName}</TableCell>
                    <TableCell align="right">₹{feeInfo?.monthlyFee?.toLocaleString()}/month</TableCell>
                    <TableCell align="right">{feeDetails?.totalDays}</TableCell>
                    <TableCell align="right">₹{feeInfo?.totalFee?.toLocaleString()}</TableCell>
                    <TableCell align="right">
                      <IconButton 
                        onClick={() => handleRemoveSubject(index)}
                        size="small"
                        color="error"
                      >
                        <Trash2 size={18} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* 1:1 Mode Settings */}
        <Box sx={{ mb: 3 }}>
          <FormControlLabel
            control={
              <Switch
                checked={oneToOneEnabled}
                onChange={(e) => setOneToOneEnabled(e.target.checked)}
              />
            }
            label="Enable 1:1 Mode"
          />
          {oneToOneEnabled && (
            <TextField
              type="number"
              label="1:1 Mode Percentage"
              value={oneToOnePercentage}
              onChange={(e) => setOneToOnePercentage(Number(e.target.value))}
              InputProps={{
                inputProps: { min: 10, max: 60 }
              }}
              size="small"
              sx={{ ml: 2 }}
            />
          )}
        </Box>

        {/* Scholarship Settings */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
            Scholarship Details
          </Typography>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={scholarshipEnabled}
                    onChange={handleScholarshipToggle}
                    color="primary"
                  />
                }
                label="Scholarship"
              />
              {scholarshipEnabled && (
                <FormControl size="small" sx={{ minWidth: 120 }}>
                  <Select
                    value={scholarshipPercentage}
                    onChange={handleScholarshipPercentageChange}
                  >
                    <MenuItem value={10}>10%</MenuItem>
                    <MenuItem value={20}>20%</MenuItem>
                    <MenuItem value={30}>30%</MenuItem>
                    <MenuItem value={40}>40%</MenuItem>
                    <MenuItem value={50}>50%</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>
            {scholarshipEnabled && (
              <TextField
                label="Scholarship Notes"
                value={scholarshipNotes}
                onChange={(e) => setScholarshipNotes(e.target.value)}
                fullWidth
                size="small"
                multiline
                rows={2}
              />
            )}
          </Stack>
        </Box>

      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          startIcon={<Save size={20} />}
          onClick={handleSave}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
