import React, { useState, useEffect, useCallback } from 'react';
import { CheckCircle, Plus, X, Upload } from 'lucide-react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { DailyUpdate, Board, KSheetStatus, Subject, Student } from '../types';
import StudentSelect from './StudentSelect';
import { useAuth } from '../hooks/useAuth';
import { useTeacherPoints } from '../hooks/useTeacherPoints';
import toast from 'react-hot-toast';
import { format, subDays } from 'date-fns';
import { Autocomplete, TextField } from '@mui/material';
import { uploadFileToNextcloud, getNextcloudPath } from '../utils/nextcloud';

const SUBJECTS: Subject[] = [
  // Languages
  'English - First Language',
  'English as a Second Language',
  'French',
  'Spanish',
  'German',
  'Mandarin Chinese',
  'Hindi',
  'Marathi',
  'Sanskrit',
  
  // Sciences
  'Biology',
  'Chemistry',
  'Physics',
  'Combined Science',
  'Co-ordinated Sciences',
  'Environmental Management',
  
  // Mathematics
  'Mathematics - Core and Extended',
  'Additional Mathematics',
  'International Mathematics',
  
  // Humanities and Social Sciences
  'Economics',
  'Geography',
  'History',
  'Global Perspectives',
  'Sociology',
  'Psychology',
  
  // Commerce and Business
  'Accounting',
  'Business Studies',
  'Commerce',
  
  // Technology
  'Computer Science',
  'Design and Technology',
  'ICT',
  
  // Arts and Physical Education
  'Art and Design',
  'Drama',
  'Music',
  'Physical Education (PE)',
  
  // Others
  'Phonics',
  'UOI (Unit of inquiry)'
];

interface StudentData {
  studentName: string;
  grade: string;
  board: Board;
}

const initialStudentData: StudentData = {
  studentName: '',
  grade: '',
  board: 'IGCSE'
};

interface FormData {
  subject: Subject;
  chapterName: string;
  notes: string;
  chapterCompletion: 25 | 50 | 75 | 100;
  date: string;
  kSheetFile: File | null;
  kSheetUrl: string;
  kSheetPath: string;
}

const initialFormData: FormData = {
  subject: '' as Subject,
  chapterName: '',
  notes: '',
  chapterCompletion: 25,
  date: format(new Date(), 'yyyy-MM-dd'),
  kSheetFile: null,
  kSheetUrl: '',
  kSheetPath: ''
};

export default function DailyUpdateForm() {
  const { user } = useAuth();
  const { addPoints } = useTeacherPoints(user?.id || '', user?.name || '');
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [students, setStudents] = useState<StudentData[]>([{ ...initialStudentData }]);
  const [kSheetStatus, setKSheetStatus] = useState<KSheetStatus>('no');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  const today = format(new Date(), 'yyyy-MM-dd');
  const yesterday = format(subDays(new Date(), 1), 'yyyy-MM-dd');

  const addStudent = () => {
    if (students.length < 6) {
      setStudents([...students, { ...initialStudentData }]);
    }
  };

  const removeStudent = (index: number) => {
    if (students.length > 1) {
      setStudents(students.filter((_, i) => i !== index));
    }
  };

  const updateStudent = (index: number, selectedStudent: Student) => {
    const updatedStudents = [...students];
    updatedStudents[index] = {
      studentName: selectedStudent.name,
      grade: selectedStudent.grade,
      board: selectedStudent.board
    };
    setStudents(updatedStudents);
  };

  const validateForm = () => {
    if (!user) {
      toast.error('You must be logged in to submit updates');
      return false;
    }

    if (!formData.subject) {
      setErrors(prev => ({ ...prev, subject: 'Subject is required' }));
      return false;
    }

    if (!formData.chapterName.trim()) {
      setErrors(prev => ({ ...prev, chapterName: 'Chapter name is required' }));
      return false;
    }

    if (!formData.notes.trim()) {
      setErrors(prev => ({ ...prev, notes: 'Notes are required' }));
      return false;
    }

    if (!students[0].studentName) {
      toast.error('Please select at least one student');
      return false;
    }

    return true;
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !user?.id) return;

    const allowedTypes = [
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/pdf', // .pdf
      'image/jpeg', // .jpg, .jpeg
      'image/png', // .png
    ];

    if (!allowedTypes.includes(file.type)) {
      toast.error('Invalid file type. Please upload a .doc, .docx, .pdf, .jpeg, .png, or .jpg file');
      return;
    }

    try {
      setIsUploading(true);
      
      // Get the first student's grade (assuming all students are from same grade)
      const studentGrade = students[0]?.grade || '';
      if (!studentGrade || !formData.subject) {
        toast.error('Please select a student and subject before uploading K-sheet');
        return;
      }

      // Generate Nextcloud path
      const nextcloudPath = getNextcloudPath(formData.subject, studentGrade, true);

      // Upload to Nextcloud
      await uploadFileToNextcloud(file, nextcloudPath);

      setFormData(prev => ({
        ...prev,
        kSheetFile: file,
        kSheetUrl: 'Stored in Nextcloud',
        kSheetPath: nextcloudPath
      }));

      toast.success('File uploaded successfully to Nextcloud!');
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file. Please try again.');
    } finally {
      setIsUploading(false);
      if (event.target) {
        event.target.value = ''; // Reset the input
      }
    }
  };

  const handleRemoveFile = async () => {
    setFormData(prev => ({
      ...prev,
      kSheetFile: null,
      kSheetUrl: '',
      kSheetPath: ''
    }));
    toast.success('File reference removed successfully');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm() || isSubmitting) return;

    try {
      setIsSubmitting(true);
      const now = Timestamp.now();
      
      const validStudents = students.filter(student => student.studentName);

      const updates = validStudents.map(student => ({
        teacherId: user!.id,
        teacherName: user!.role === 'admin' ? 'Admin' : user!.name,
        subject: formData.subject,
        chapterName: formData.chapterName.trim(),
        notes: formData.notes.trim(),
        chapterCompletion: formData.chapterCompletion,
        studentName: student.studentName.trim(),
        grade: student.grade.trim(),
        board: student.board || 'IGCSE',
        kSheetGiven: kSheetStatus,
        kSheetUrl: formData.kSheetUrl,
        kSheetPath: formData.kSheetPath,
        createdAt: now,
        updatedAt: now,
        timestamp: Timestamp.fromDate(new Date(formData.date))
      }));

      await Promise.all(updates.map(update => addDoc(collection(db, 'dailyUpdates'), update)));

      await addPoints('dailyUpdate');
      
      if (kSheetStatus === 'yes') {
        await addPoints('kSheet');
      }

      toast.success(`Daily update${updates.length > 1 ? 's' : ''} submitted successfully`);
      
      setStudents([{ ...initialStudentData }]);
      setFormData(initialFormData);
      setKSheetStatus('no');
    } catch (error) {
      console.error('Error submitting updates:', error);
      toast.error('Failed to submit updates. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <h2 className="text-xl font-bold text-gray-700 mb-2">Please Log In</h2>
          <p className="text-gray-500">You must be logged in to submit daily updates.</p>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="max-w-3xl mx-auto py-8 space-y-8">
      <div className="bg-white rounded-lg shadow-md p-6 space-y-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <CheckCircle className="w-6 h-6 text-accent mr-2" />
            <h2 className="text-xl font-bold text-secondary">Daily Update Form</h2>
          </div>
          <div className="text-sm text-gray-500">
            Submitting as: <span className="font-medium text-accent">
              {user.role === 'admin' ? 'Admin' : user.name}
            </span>
          </div>
        </div>

        <div className="space-y-6">
          {students.map((student, index) => (
            <div key={index} className="relative border border-gray-200 rounded-lg p-4">
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => removeStudent(index)}
                  className="absolute top-2 right-2 p-1 text-gray-400 hover:text-red-500"
                >
                  <X className="w-5 h-5" />
                </button>
              )}

              <div className="grid grid-cols-1 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Student {index + 1} {index === 0 && <span className="text-red-500">*</span>}
                  </label>
                  <StudentSelect
                    onSelect={(selectedStudent) => updateStudent(index, selectedStudent)}
                  />
                </div>

                {student.studentName && (
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Grade</label>
                      <input
                        type="text"
                        value={student.grade || ''}
                        className="input-field bg-gray-50"
                        disabled
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Board</label>
                      <input
                        type="text"
                        value={student.board || 'IGCSE'}
                        className="input-field bg-gray-50"
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}

          {students.length < 6 && (
            <button
              type="button"
              onClick={addStudent}
              className="text-accent hover:text-accent/80 font-medium flex items-center"
            >
              <Plus className="w-4 h-4 mr-1" />
              Add another student
            </button>
          )}

          <div className="mb-6">
            <Autocomplete
              value={formData.subject || null}
              onChange={(_, newValue) => {
                setFormData(prev => ({
                  ...prev,
                  subject: newValue as Subject || '' as Subject
                }));
                if (errors.subject) {
                  setErrors(prev => ({
                    ...prev,
                    subject: ''
                  }));
                }
              }}
              options={SUBJECTS}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Subject"
                  variant="outlined"
                  error={!!errors.subject}
                  helperText={errors.subject || ''}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '56px',
                      fontSize: '1.1rem',
                      fontFamily: 'sweater-school, sans-serif',
                    },
                    '& .MuiInputLabel-root': {
                      fontFamily: 'sweater-school, sans-serif',
                      fontSize: '1.2rem',
                      '&.Mui-focused': {
                        color: '#4F46E5'
                      }
                    },
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#4F46E5',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4F46E5',
                      }
                    }
                  }}
                />
              )}
              fullWidth
              freeSolo={false}
              disableClearable
              sx={{
                '& .MuiAutocomplete-listbox': {
                  fontFamily: 'sweater-school, sans-serif',
                  fontSize: '1.1rem',
                },
                '& .MuiAutocomplete-option': {
                  fontFamily: 'sweater-school, sans-serif',
                  fontSize: '1.1rem',
                }
              }}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Chapter Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.chapterName}
              onChange={(e) => setFormData({ ...formData, chapterName: e.target.value })}
              className="input-field"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Notes <span className="text-red-500">*</span>
            </label>
            <textarea
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              className="input-field min-h-[100px]"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Date
            </label>
            <select
              value={formData.date}
              onChange={(e) => setFormData({ ...formData, date: e.target.value })}
              className="input-field"
            >
              <option value={today}>Today ({format(new Date(today), 'dd MMM yyyy')})</option>
              <option value={yesterday}>Yesterday ({format(new Date(yesterday), 'dd MMM yyyy')})</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">
              K-Sheet Given
            </label>
            <div className="flex gap-6">
              {(['no', 'textual', 'yes'] as const).map((status) => (
                <label
                  key={status}
                  className={`relative flex flex-col items-center gap-2 cursor-pointer transition-all duration-300 p-4 rounded-xl ${kSheetStatus === status ? 'bg-primary/10 ring-2 ring-primary shadow-lg scale-105' : 'bg-gray-50 hover:bg-gray-100'}`}
                >
                  <input
                    type="radio"
                    name="kSheetStatus"
                    value={status}
                    checked={kSheetStatus === status}
                    onChange={(e) => {
                      setKSheetStatus(e.target.value as KSheetStatus);
                      if (e.target.value !== 'yes') {
                        handleRemoveFile();
                      }
                    }}
                    className="sr-only"
                  />
                  <div className={`w-12 h-12 rounded-lg flex items-center justify-center transition-all duration-300 ${kSheetStatus === status ? 'bg-primary text-white' : 'bg-white text-gray-400 border-2 border-gray-200'}`}>
                    {status === 'yes' && (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                    {status === 'no' && (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    )}
                    {status === 'textual' && (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                    )}
                  </div>
                  <span className={`text-sm font-medium capitalize transition-colors duration-300 ${kSheetStatus === status ? 'text-primary' : 'text-gray-600'}`}>
                    {status}
                  </span>
                  <div className={`absolute -inset-0.5 rounded-xl transition-opacity duration-300 bg-gradient-to-br from-primary/20 to-primary/5 ${kSheetStatus === status ? 'opacity-100' : 'opacity-0'}`} />
                </label>
              ))}
            </div>

            {kSheetStatus === 'yes' && (
              <div className="mt-4 space-y-4">
                {formData.kSheetFile ? (
                  <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center">
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">{formData.kSheetFile.name}</p>
                        <p className="text-sm text-gray-500">
                          {(formData.kSheetFile.size / 1024 / 1024).toFixed(2)} MB
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={handleRemoveFile}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                ) : (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Upload K-Sheet
                    </label>
                    <label className="relative flex items-center justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-primary focus:outline-none">
                      <div className="space-y-1 text-center">
                        <Upload className="mx-auto h-8 w-8 text-gray-400" />
                        <div className="flex text-sm text-gray-600">
                          <span className="relative cursor-pointer rounded-md font-medium text-primary hover:text-primary/80 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2">
                            {isUploading ? 'Uploading...' : 'Upload a file'}
                          </span>
                        </div>
                        <p className="text-xs text-gray-500">
                          DOC, DOCX, PDF, JPEG, PNG up to 10MB
                        </p>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        accept=".doc,.docx,.pdf,.jpeg,.jpg,.png"
                        onChange={handleFileUpload}
                        disabled={isUploading}
                      />
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-6">
              Chapter Completion
            </label>
            <div className="chapter-progress">
              {[25, 50, 75, 100].map((value, index, array) => (
                <div key={value} className={`progress-point ${formData.chapterCompletion === value ? 'active' : ''}`}>
                  <button
                    type="button"
                    className={`progress-button ${formData.chapterCompletion >= value ? 'complete' : 'incomplete'}`}
                    onClick={() => setFormData({ ...formData, chapterCompletion: value })}
                  >
                    {value}%
                  </button>
                  {index < array.length - 1 && (
                    <div className={`progress-line ${formData.chapterCompletion >= array[index + 1] ? 'complete' : ''}`} />
                  )}
                  <span className="progress-label">
                    {value === 25 ? 'Started' :
                     value === 50 ? 'Halfway' :
                     value === 75 ? 'Almost Done' : 'Complete'}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full mt-8 btn-primary flex items-center justify-center"
        >
          <CheckCircle className="w-5 h-5 mr-2" />
          {isSubmitting ? 'Submitting...' : 'Submit Update'}
        </button>
      </div>
    </form>
  );
}