import React from 'react';
import { Clock } from 'lucide-react';
import { format, parse } from 'date-fns';

interface WorkHours {
  [key: string]: { start: string; end: string };
}

interface WorkHoursDisplayProps {
  workHours?: WorkHours;
  expanded?: boolean;
}

const to12Hour = (time24h: string): string => {
  try {
    const date = parse(time24h, 'HH:mm', new Date());
    return format(date, 'hh:mm aa');
  } catch (error) {
    console.error('Error converting time:', error);
    return time24h;
  }
};

export default function WorkHoursDisplay({ workHours, expanded = false }: WorkHoursDisplayProps) {
  if (!workHours) return null;

  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  
  // Get unique time slots
  const uniqueTimeSlots = Array.from(new Set(
    Object.values(workHours).map(({ start, end }) => `${start}-${end}`)
  ));

  // If all days have the same time slot, show a simplified view
  const allSameTime = uniqueTimeSlots.length === 1;
  
  if (!expanded && allSameTime) {
    const { start, end } = workHours[days[0]];
    return (
      <div className="mt-2">
        <p className="flex items-center text-gray-600 text-sm">
          <Clock className="w-4 h-4 mr-2 text-blue-500" />
          <span>
            {to12Hour(start)} - {to12Hour(end)}
          </span>
        </p>
      </div>
    );
  }

  // Show first 3 days in card view if not expanded
  const displayDays = expanded ? days : days.slice(0, 2);

  return (
    <div className="mt-2 space-y-1">
      {displayDays.map((day) => {
        const hours = workHours[day];
        if (!hours) return null;

        return (
          <div key={day} className="flex items-center text-gray-600 text-sm">
            <Clock className="w-4 h-4 mr-2 text-blue-500 shrink-0" />
            <span className="capitalize w-20">{day}:</span>
            <span className="text-gray-700">
              {to12Hour(hours.start)} - {to12Hour(hours.end)}
            </span>
          </div>
        );
      })}
      {!expanded && Object.keys(workHours).length > 2 && (
        <p className="text-sm text-gray-500 pl-6">
          +{Object.keys(workHours).length - 2} more days
        </p>
      )}
    </div>
  );
}
