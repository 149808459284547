import * as XLSX from 'xlsx';
import { Student, Board } from '../types';
import toast from 'react-hot-toast';

const VALID_BOARDS: Board[] = ['IGCSE', 'CBSE', 'SSC', 'NIOS', 'IB', 'AS/A IBDP', 'Others'];
const VALID_BRANCHES = ['Goregaon West', 'Goregaon East', 'Online', 'Borivali', 'Kandivali', 'Others'];

export function generateExcelTemplate() {
  // Create workbook and worksheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([
    ['Student Name', 'School', 'Branch', 'Grade', 'Board', 'WhatsApp Group Link'],
    ['John Doe', 'Sample School', 'Goregaon West', '10', 'IGCSE', 'https://chat.whatsapp.com/...'],
    ['', '', '', '', '', ''],
  ]);

  // Add data validation for Board column
  const boardValidation = {
    type: 'list',
    operator: 'equal',
    formula1: '"' + VALID_BOARDS.join(',') + '"',
    showDropDown: true,
    error: 'Please select a valid board',
    errorTitle: 'Invalid Board'
  };

  // Add data validation for Branch column
  const branchValidation = {
    type: 'list',
    operator: 'equal',
    formula1: '"' + VALID_BRANCHES.join(',') + '"',
    showDropDown: true,
    error: 'Please select a valid branch',
    errorTitle: 'Invalid Branch'
  };

  // Set column widths
  const colWidths = [
    { wch: 20 }, // Student Name
    { wch: 20 }, // School
    { wch: 15 }, // Branch
    { wch: 10 }, // Grade
    { wch: 15 }, // Board
    { wch: 30 }, // WhatsApp Link
  ];
  ws['!cols'] = colWidths;

  // Add validations
  if (!ws['!dataValidation']) ws['!dataValidation'] = [];
  ws['!dataValidation'].push(
    { sqref: 'E2:E1000', ...boardValidation },   // Board validation
    { sqref: 'C2:C1000', ...branchValidation }   // Branch validation
  );

  XLSX.utils.book_append_sheet(wb, ws, 'Students Template');
  XLSX.writeFile(wb, 'students_template.xlsx');
}

export function parseExcelData(file: File): Promise<Omit<Student, 'id'>[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet) as any[];

        const students = jsonData.map((row, index) => {
          // Validate required fields
          if (!row['Student Name'] || !row['School'] || !row['Grade'] || !row['Board'] || !row['Branch']) {
            throw new Error(`Row ${index + 1} is missing required fields`);
          }

          // Validate board
          if (!VALID_BOARDS.includes(row['Board'])) {
            throw new Error(`Invalid board "${row['Board']}" in row ${index + 1}`);
          }

          // Validate branch
          if (!VALID_BRANCHES.includes(row['Branch'])) {
            throw new Error(`Invalid branch "${row['Branch']}" in row ${index + 1}`);
          }

          return {
            name: String(row['Student Name']).trim(),
            school: String(row['School']).trim(),
            branch: row['Branch'],
            grade: String(row['Grade']).trim(),
            board: row['Board'] as Board,
            whatsappLink: String(row['WhatsApp Group Link'] || '').trim()
          };
        });

        resolve(students);
      } catch (error: any) {
        reject(new Error(`Excel parsing error: ${error.message}`));
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };

    reader.readAsArrayBuffer(file);
  });
}