import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs, doc, getDoc, where } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface Teacher {
  id: string;
  name: string;
  points: number;
}

export default function TopTeachers() {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTopTeachers = async () => {
      try {
        // First get all users who are teachers
        const usersRef = collection(db, 'users');
        const teachersSnapshot = await getDocs(query(usersRef, where('role', '==', 'teacher')));
        
        const teacherData: Teacher[] = [];
        
        // For each teacher, get their points from tasks
        for (const teacherDoc of teachersSnapshot.docs) {
          const tasksRef = collection(db, 'tasks');
          const tasksQuery = query(
            tasksRef,
            where('assignedTo', '==', teacherDoc.id),
            where('status', '==', 'completed')
          );
          const tasksSnapshot = await getDocs(tasksQuery);
          
          let totalPoints = 0;
          tasksSnapshot.forEach(taskDoc => {
            const taskData = taskDoc.data();
            totalPoints += taskData.points || 0;
          });
          
          teacherData.push({
            id: teacherDoc.id,
            name: teacherDoc.data().name || 'Unknown Teacher',
            points: totalPoints
          });
        }
        
        // Sort by points and take top 5
        const sortedTeachers = teacherData
          .sort((a, b) => b.points - a.points)
          .slice(0, 5);
        
        setTeachers(sortedTeachers);
      } catch (error) {
        console.error('Error fetching top teachers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopTeachers();
  }, []);

  if (loading) {
    return (
      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md animate-pulse">
        <div className="h-6 bg-gray-200 rounded w-1/3 mb-4"></div>
        <div className="space-y-3">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="flex items-center gap-3">
              <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
              <div className="flex-1 h-4 bg-gray-200 rounded"></div>
              <div className="w-16 h-4 bg-gray-200 rounded"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const getPositionStyle = (index: number) => {
    switch (index) {
      case 0:
        return 'bg-yellow-100 text-yellow-800 border-yellow-300';
      case 1:
        return 'bg-gray-100 text-gray-800 border-gray-300';
      case 2:
        return 'bg-orange-100 text-orange-800 border-orange-300';
      default:
        return 'bg-white text-gray-800 border-gray-200';
    }
  };

  const getPositionEmoji = (index: number) => {
    switch (index) {
      case 0:
        return '👑';
      case 1:
        return '🥈';
      case 2:
        return '🥉';
      default:
        return `${index + 1}`;
    }
  };

  return (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md h-full">
      <h2 className="text-lg sm:text-xl font-bold text-secondary mb-4 sm:mb-6 text-center">Top Performers</h2>
      <div className="space-y-3">
        {teachers.length > 0 ? (
          teachers.map((teacher, index) => (
            <div
              key={teacher.id}
              className={`flex items-center gap-3 p-3 rounded-lg border ${getPositionStyle(index)} transition-transform hover:scale-102`}
            >
              <div className="w-8 h-8 flex items-center justify-center font-bold rounded-full bg-white shadow-sm">
                {getPositionEmoji(index)}
              </div>
              <span className="flex-1 font-medium">{teacher.name}</span>
              <div className="flex items-center gap-2">
                <span className="font-bold">{teacher.points}</span>
                <img 
                  src="https://static.wixstatic.com/media/abaee8_d543e28c553d4b91a517d1781404fc35~mv2.png" 
                  alt="Points" 
                  className="w-5 h-5 object-contain" 
                />
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">
            No teachers found
          </div>
        )}
      </div>
    </div>
  );
}
