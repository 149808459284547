import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import Select from 'react-select';

interface Student {
  id: string;
  name: string;
  subjects: string[];
}

interface Score {
  marks: number;
  totalMarks: number;
  description: string;
  testType: 'inkstall test' | 'school test';
}

interface Attendance {
  date: string;
  status: 'P' | 'A';
  inTime?: string;
  outTime?: string;
}

interface PerformanceEntry {
  id: string;
  studentId: string;
  studentName: string;
  teacherId: string;
  subject: string;
  scores: Score[];
  averagePercentage: number | null;
  createdAt: Date;
  attendance?: Attendance[];
}

const POINTS_FOR_PERFORMANCE_ENTRY = 200;

export default function StudentPerformance() {
  const { user } = useAuth();
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [score, setScore] = useState<Score>({ 
    marks: 0, 
    totalMarks: 100, 
    description: '',
    testType: 'inkstall test'
  });
  const [loading, setLoading] = useState(true);
  const [attendanceData, setAttendanceData] = useState<{ [key: string]: Attendance[] }>({});
  const [performance, setPerformance] = useState<PerformanceEntry[]>([]);
  const [subjects, setSubjects] = useState<string[]>([]);

  // Fetch students from database
  useEffect(() => {
    const fetchStudents = async () => {
      if (!user?.email) return;

      setLoading(true);
      try {
        const studentsRef = collection(db, 'students');
        const querySnapshot = await getDocs(studentsRef);
        const studentsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Student));
        setStudents(studentsList || []);
      } catch (error) {
        console.error('Error fetching students:', error);
        toast.error('Failed to fetch students');
        setStudents([]); // Initialize with empty array on error
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, [user?.email]);

  useEffect(() => {
    const fetchPerformanceAndAttendance = async () => {
      if (!selectedStudent) return;
      
      setLoading(true);
      try {
        // Fetch performance data
        const performanceQuery = query(
          collection(db, 'performance'),
          where('studentId', '==', selectedStudent.id)
        );
        const performanceSnapshot = await getDocs(performanceQuery);
        const performanceData = performanceSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            studentId: data.studentId || '',
            studentName: data.studentName || '',
            teacherId: data.teacherId || '',
            subject: data.subject || '',
            scores: Array.isArray(data.scores) ? data.scores.map(score => ({
              marks: Number(score.marks) || 0,
              totalMarks: Number(score.totalMarks) || 100,
              description: score.description || '',
              testType: score.testType || 'inkstall test'
            })) : [],
            averagePercentage: typeof data.averagePercentage === 'number' ? data.averagePercentage : null,
            createdAt: data.createdAt?.toDate() || new Date(),
            attendance: Array.isArray(data.attendance) ? data.attendance : []
          };
        }) as PerformanceEntry[];
        
        setPerformance(performanceData);

        // Fetch events with this student
        try {
          const eventsQuery = query(
            collection(db, 'events'),
            where('students', 'array-contains', { 
              id: selectedStudent.id, 
              name: selectedStudent.name 
            })
          );
          const eventsSnapshot = await getDocs(eventsQuery);
          
          const attendanceByDate: { [key: string]: Attendance[] } = {};
          
          eventsSnapshot.docs.forEach(doc => {
            const eventData = doc.data();
            if (eventData.start) {
              try {
                // Safely handle the Timestamp conversion
                const startDate = eventData.start instanceof Timestamp 
                  ? eventData.start.toDate() 
                  : new Date(eventData.start);
                  
                const date = format(startDate, 'yyyy-MM-dd');
                const student = eventData.students?.find((s: any) => s.id === selectedStudent.id);
                
                if (student?.attendance) {
                  if (!attendanceByDate[date]) {
                    attendanceByDate[date] = [];
                  }
                  
                  attendanceByDate[date].push({
                    date,
                    status: student.attendance.status || 'A',
                    inTime: student.attendance.inTime,
                    outTime: student.attendance.outTime
                  });
                }
              } catch (error) {
                console.error('Error processing event date:', error);
                // Continue with the next event if there's an error with this one
              }
            }
          });

          setAttendanceData(attendanceByDate);
        } catch (error) {
          console.error('Error fetching events:', error);
          // Don't fail the whole operation if events fetch fails
          setAttendanceData({});
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch student data');
        // Initialize with empty data on error
        setPerformance([]);
        setAttendanceData({});
      } finally {
        setLoading(false);
      }
    };

    fetchPerformanceAndAttendance();
  }, [selectedStudent]);

  useEffect(() => {
    const fetchSubjects = async () => {
      if (!selectedStudent) return;
      
      try {
        // Get subjects from student data
        if (selectedStudent.subjects && selectedStudent.subjects.length > 0) {
          setSubjects(selectedStudent.subjects);
        } else {
          // Fallback to fetching from subjects collection
          const subjectsRef = collection(db, 'subjects');
          const querySnapshot = await getDocs(subjectsRef);
          const subjectsList = querySnapshot.docs.map(doc => doc.data().name);
          setSubjects(subjectsList);
        }
      } catch (error) {
        console.error('Error fetching subjects:', error);
        toast.error('Failed to fetch subjects');
        setSubjects([]);
      }
    };

    fetchSubjects();
  }, [selectedStudent]);

  const handleScoreChange = (field: keyof Score, value: string | number) => {
    setScore(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedStudent || !selectedSubject) {
      toast.error('Please select a student and subject');
      return;
    }

    setLoading(true);
    try {
      // Validate the score
      if (!score.description || score.totalMarks <= 0) {
        toast.error('Please add a valid score entry');
        return;
      }

      const percentage = (score.marks / score.totalMarks) * 100;

      await addDoc(collection(db, 'performance'), {
        studentId: selectedStudent.id,
        studentName: selectedStudent.name,
        teacherId: user?.uid || '',
        subject: selectedSubject,
        scores: [score],
        averagePercentage: percentage,
        createdAt: Timestamp.now()
      });

      toast.success('Performance data saved successfully');
      // Reset form
      setScore({ marks: 0, totalMarks: 100, description: '', testType: 'inkstall test' });
      setSelectedSubject('');
    } catch (error) {
      console.error('Error saving performance:', error);
      toast.error('Failed to save performance data');
    } finally {
      setLoading(false);
    }
  };

  if (loading && students.length === 0) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Student Performance</h1>

        {/* Student Selection */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Student
          </label>
          <Select
            options={(students || []).map(student => ({
              value: student.id,
              label: student.name,
              student: student
            }))}
            value={selectedStudent ? {
              value: selectedStudent.id,
              label: selectedStudent.name,
              student: selectedStudent
            } : null}
            onChange={(option: any) => {
              if (option) {
                setSelectedStudent(option.student);
              } else {
                setSelectedStudent(null);
              }
            }}
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            placeholder="Select a student..."
            isDisabled={loading}
          />
        </div>

        {selectedStudent && (
          <>
            {/* Performance Entry Form */}
            <div className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Add Performance Entry</h2>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Subject
                </label>
                <select
                  value={selectedSubject}
                  onChange={(e) => setSelectedSubject(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  disabled={loading || subjects.length === 0}
                >
                  <option value="">Select a subject...</option>
                  {subjects.map((subject, index) => (
                    <option key={`${subject}-${index}`} value={subject}>
                      {subject}
                    </option>
                  ))}
                </select>
              </div>

              {/* Score Entry Form */}
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <input
                      type="text"
                      value={score.description}
                      onChange={(e) => handleScoreChange('description', e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      placeholder="e.g., Quiz 1"
                      disabled={loading}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Test Type</label>
                    <select
                      value={score.testType}
                      onChange={(e) => handleScoreChange('testType', e.target.value as 'inkstall test' | 'school test')}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      disabled={loading}
                    >
                      <option value="inkstall test">Inkstall Test</option>
                      <option value="school test">School Test</option>
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Marks</label>
                    <input
                      type="number"
                      value={score.marks}
                      onChange={(e) => handleScoreChange('marks', parseInt(e.target.value))}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      min="0"
                      max={score.totalMarks}
                      disabled={loading}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Total Marks</label>
                    <input
                      type="number"
                      value={score.totalMarks}
                      onChange={(e) => handleScoreChange('totalMarks', parseInt(e.target.value))}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      min="1"
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>

              <button
                onClick={handleSubmit}
                disabled={!selectedSubject || loading}
                className="mt-6 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Submit Performance
              </button>
            </div>

            {/* Performance History */}
            <div className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Performance History</h2>
              <div className="space-y-4">
                {performance.map((entry, index) => (
                  <div key={index} className="border rounded-lg p-4">
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <h3 className="font-medium text-gray-900">{entry.subject}</h3>
                        <p className="text-sm text-gray-500">
                          {format(entry.createdAt, 'MMM d, yyyy')}
                        </p>
                      </div>
                      <span className="text-lg font-semibold text-blue-600">
                        {typeof entry.averagePercentage === 'number' 
                          ? `${entry.averagePercentage.toFixed(1)}%`
                          : 'N/A'}
                      </span>
                    </div>
                    <div className="space-y-2">
                      {entry.scores?.map((score, scoreIndex) => (
                        <div key={scoreIndex} className="flex justify-between items-center text-sm">
                          <div className="flex items-center space-x-2">
                            <span className="text-gray-600">{score.description}</span>
                            <span className="px-2 py-1 text-xs rounded-full bg-gray-100 text-gray-600">
                              {score.testType}
                            </span>
                          </div>
                          <span className="text-gray-900">{score.marks}/{score.totalMarks}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                {performance.length === 0 && (
                  <p className="text-gray-500 text-center py-4">No performance data available</p>
                )}
              </div>
            </div>

            {/* Attendance History */}
            <div>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Attendance History</h2>
              <div className="space-y-4">
                {Object.entries(attendanceData)
                  .sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime())
                  .map(([date, attendances]) => (
                    <div key={date} className="border rounded-lg p-4">
                      <h3 className="font-medium text-gray-900 mb-2">
                        {format(new Date(date), 'MMMM d, yyyy')}
                      </h3>
                      <div className="space-y-3">
                        {attendances.map((attendance, index) => (
                          <div key={index} className="flex items-center justify-between text-sm">
                            <div className="flex items-center space-x-3">
                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                attendance.status === 'P' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                              }`}>
                                {attendance.status === 'P' ? 'Present' : 'Absent'}
                              </span>
                              {attendance.status === 'P' && (
                                <div className="flex items-center space-x-2 text-gray-600">
                                  {attendance.inTime && (
                                    <span>IN: {attendance.inTime}</span>
                                  )}
                                  {attendance.outTime && (
                                    <>
                                      <span>•</span>
                                      <span>OUT: {attendance.outTime}</span>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                {Object.keys(attendanceData).length === 0 && (
                  <p className="text-gray-500 text-center py-4">No attendance data available</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
