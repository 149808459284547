import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  Tab,
  Tabs,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Stack,
  Button,
  Collapse,
} from '@mui/material';
import { X, Filter } from 'lucide-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { Student } from '../types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface FeeAnalyticsDialogProps {
  open: boolean;
  onClose: () => void;
  students: Student[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

export default function FeeAnalyticsDialog({ open, onClose, students }: FeeAnalyticsDialogProps) {
  const [value, setValue] = useState(0);
  const [filterBoard, setFilterBoard] = useState<string>('');
  const [filterGrade, setFilterGrade] = useState<string>('');
  const [filterBranch, setFilterBranch] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filteredStudents, setFilteredStudents] = useState<Student[]>(students);

  // Get unique values for filters
  const uniqueBoards = Array.from(new Set(students.map(s => s.board))).filter(Boolean);
  const uniqueGrades = Array.from(new Set(students.map(s => s.grade))).filter(Boolean);
  const uniqueBranches = Array.from(new Set(students.map(s => s.branch))).filter(Boolean);

  useEffect(() => {
    let filtered = [...students];

    // Apply filters
    if (filterBoard) {
      filtered = filtered.filter(s => s.board === filterBoard);
    }
    if (filterGrade) {
      filtered = filtered.filter(s => s.grade === filterGrade);
    }
    if (filterBranch) {
      filtered = filtered.filter(s => s.branch === filterBranch);
    }
    if (startDate || endDate) {
      filtered = filtered.filter(s => {
        const studentStartDate = s.startDate ? new Date(s.startDate) : null;
        const studentEndDate = s.endDate ? new Date(s.endDate) : null;
        
        // Check if student's period overlaps with selected date range
        const startsBeforeEnd = !endDate || !studentStartDate || studentStartDate <= endDate;
        const endsAfterStart = !startDate || !studentEndDate || studentEndDate >= startDate;
        
        return startsBeforeEnd && endsAfterStart;
      });

      // Also filter installments within date range for fee calculations
      filtered = filtered.map(student => ({
        ...student,
        feeCalculation: {
          ...student.feeCalculation,
          installments: student.feeCalculation?.installments?.filter(inst => {
            const instDate = new Date(inst.dueDate);
            return (!startDate || instDate >= startDate) && (!endDate || instDate <= endDate);
          })
        }
      }));
    }

    setFilteredStudents(filtered);
  }, [students, filterBoard, filterGrade, filterBranch, startDate, endDate]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const resetFilters = () => {
    setFilterBoard('');
    setFilterGrade('');
    setFilterBranch('');
    setStartDate(null);
    setEndDate(null);
  };

  // Initialize months data based on date range or last 12 months
  const getMonthsData = () => {
    const months = [];
    let startMonth: Date;
    let endMonth: Date;

    if (startDate && endDate) {
      // Use selected date range
      startMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
      endMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
    } else {
      // Default to last 12 months
      const today = new Date();
      startMonth = new Date(today.getFullYear(), today.getMonth() - 11, 1);
      endMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    }

    // Create array of months between start and end dates
    let currentMonth = new Date(startMonth);
    while (currentMonth <= endMonth) {
      const monthKey = currentMonth.toLocaleString('default', { month: 'short', year: 'numeric' });
      months.push({
        month: monthKey,
        expected: 0,
        received: 0
      });
      currentMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
    }
    return months;
  };

  // Calculate analytics data based on filtered students
  const totalFees = filteredStudents.reduce((sum, student) => 
    sum + (student.feeCalculation?.totalFees || 0), 0
  );

  const totalReceived = filteredStudents.reduce((sum, student) => 
    sum + (student.feeCalculation?.installments?.reduce((iSum, inst) => 
      iSum + (inst.paid ? inst.amount : 0), 0) || 0), 0
  );

  const totalDue = totalFees - totalReceived;

  const boardWiseData = uniqueBoards.map(board => ({
    name: board,
    students: filteredStudents.filter(s => s.board === board).length,
    fees: filteredStudents
      .filter(s => s.board === board)
      .reduce((sum, s) => sum + (s.feeCalculation?.totalFees || 0), 0)
  }));

  const branchWiseData = uniqueBranches.map(branch => ({
    name: branch,
    students: filteredStudents.filter(s => s.branch === branch).length,
    fees: filteredStudents
      .filter(s => s.branch === branch)
      .reduce((sum, s) => sum + (s.feeCalculation?.totalFees || 0), 0)
  }));

  const gradeWiseData = uniqueGrades.map(grade => ({
    name: grade,
    students: filteredStudents.filter(s => s.grade === grade).length,
    fees: filteredStudents
      .filter(s => s.grade === grade)
      .reduce((sum, s) => sum + (s.feeCalculation?.totalFees || 0), 0)
  }));

  const monthlyData = filteredStudents.reduce((acc: any[], student) => {
    // Start with initialized months
    const monthsData = getMonthsData();
    
    student.feeCalculation?.installments?.forEach(inst => {
      const instDate = new Date(inst.dueDate);
      
      // Check if installment is within the selected date range
      if ((!startDate || instDate >= startDate) && (!endDate || instDate <= endDate)) {
        const monthKey = instDate.toLocaleString('default', { month: 'short', year: 'numeric' });
        const monthData = monthsData.find(m => m.month === monthKey);
        if (monthData) {
          monthData.expected += inst.amount;
          if (inst.paid) {
            monthData.received += inst.amount;
          }
        }
      }
    });
    
    return monthsData;
  }, getMonthsData());

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box component="div">Fee Analytics</Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            startIcon={<Filter />}
            onClick={() => setShowFilters(!showFilters)}
            variant="outlined"
            size="small"
          >
            Filters
          </Button>
          <IconButton onClick={onClose} size="small">
            <X />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        {/* Filters Section */}
        <Collapse in={showFilters}>
          <Box sx={{ mb: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
            <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Board</InputLabel>
                <Select
                  value={filterBoard}
                  onChange={(e) => setFilterBoard(e.target.value)}
                  label="Board"
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueBoards.map(board => (
                    <MenuItem key={board} value={board}>{board}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Grade</InputLabel>
                <Select
                  value={filterGrade}
                  onChange={(e) => setFilterGrade(e.target.value)}
                  label="Grade"
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueGrades.map(grade => (
                    <MenuItem key={grade} value={grade}>{grade}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Branch</InputLabel>
                <Select
                  value={filterBranch}
                  onChange={(e) => setFilterBranch(e.target.value)}
                  label="Branch"
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueBranches.map(branch => (
                    <MenuItem key={branch} value={branch}>{branch}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>

              <Button
                variant="outlined"
                size="small"
                onClick={resetFilters}
              >
                Reset
              </Button>
            </Stack>
          </Box>
        </Collapse>

        {/* Summary Cards */}
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <Card sx={{ bgcolor: '#3498db', color: 'white' }}>
              <CardContent>
                <Typography variant="h6">Total Fees</Typography>
                <Typography variant="h4">₹{totalFees.toLocaleString()}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ bgcolor: '#2ecc71', color: 'white' }}>
              <CardContent>
                <Typography variant="h6">Total Received</Typography>
                <Typography variant="h4">₹{totalReceived.toLocaleString()}</Typography>
                <Typography variant="body2">
                  {((totalReceived / totalFees) * 100).toFixed(1)}% collected
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ bgcolor: '#e74c3c', color: 'white' }}>
              <CardContent>
                <Typography variant="h6">Total Due</Typography>
                <Typography variant="h4">₹{totalDue.toLocaleString()}</Typography>
                <Typography variant="body2">
                  {((totalDue / totalFees) * 100).toFixed(1)}% pending
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Tabs and Charts */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Monthly Collections" />
            <Tab label="Board-wise Analysis" />
            <Tab label="Branch-wise Analysis" />
            <Tab label="Grade-wise Analysis" />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Box sx={{ height: 400 }}>
            <ResponsiveContainer>
              <BarChart data={monthlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="expected" fill="#8884d8" name="Expected" />
                <Bar dataKey="received" fill="#82ca9d" name="Received" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Box sx={{ height: 400 }}>
            <ResponsiveContainer>
              <BarChart data={boardWiseData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <Tooltip />
                <Legend />
                <Bar yAxisId="left" dataKey="students" fill="#8884d8" name="Students" />
                <Bar yAxisId="right" dataKey="fees" fill="#82ca9d" name="Total Fees" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Box sx={{ height: 400 }}>
            <ResponsiveContainer>
              <BarChart data={branchWiseData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <Tooltip />
                <Legend />
                <Bar yAxisId="left" dataKey="students" fill="#8884d8" name="Students" />
                <Bar yAxisId="right" dataKey="fees" fill="#82ca9d" name="Total Fees" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <Box sx={{ height: 400 }}>
            <ResponsiveContainer>
              <BarChart data={gradeWiseData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <Tooltip />
                <Legend />
                <Bar yAxisId="left" dataKey="students" fill="#8884d8" name="Students" />
                <Bar yAxisId="right" dataKey="fees" fill="#82ca9d" name="Total Fees" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
}
