import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Student } from '../types';
import toast from 'react-hot-toast';
import { Edit, ChevronDown, ChevronUp, BarChart2, Phone, Mail, MapPin, Search, X, DollarSign } from 'lucide-react';
import StudentEditDialog from './StudentEditDialog';
import FeeAnalyticsDialog from './FeeAnalyticsDialog';
import FeeDetailsDialog from './FeeDetailsDialog';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Link
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

const STUDENT_STATUSES = ['active', 'inactive', 'cancelled', 'admission_due', 'admission_confirmed'];

export default function StudentsDB() {
  const [students, setStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBoard, setSelectedBoard] = useState<string>('');
  const [selectedGrade, setSelectedGrade] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [dateRange, setDateRange] = useState<{
    start: string | null;
    end: string | null;
  }>({ start: null, end: null });
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const [feeDetailsOpen, setFeeDetailsOpen] = useState(false);
  const [selectedFeeStudent, setSelectedFeeStudent] = useState<Student | null>(null);

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      setLoading(true);
      const q = query(collection(db, 'students'), orderBy('name'));
      const querySnapshot = await getDocs(q);
      const studentsData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        console.log('Raw student data:', data); // Debug log
        const student = {
          id: doc.id,
          name: data.name || '',
          studentId: data.studentId || '',
          grade: data.grade || '',
          board: data.board || '',
          school: data.school || '',
          status: data.status || 'active',
          subjects: data.subjects || [],
          phone: data.phone || '',
          email: data.email || '',
          address: data.address || '',
          ...data
        } as Student;
        console.log('Processed student data:', student); // Debug log
        return student;
      });
      console.log('All students data:', studentsData); // Debug log
      setStudents(studentsData);
    } catch (error) {
      console.error('Error fetching students:', error);
      toast.error('Failed to fetch students');
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (student: Student) => {
    setSelectedStudent(student);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setSelectedStudent(null);
  };

  const handleEditComplete = () => {
    fetchStudents();
  };

  const handleFeeDetailsUpdate = (updatedStudent: Student) => {
    setStudents(prevStudents => 
      prevStudents.map(student => 
        student.id === updatedStudent.id ? updatedStudent : student
      )
    );
    // Update the selected student as well to ensure GST state persists
    if (selectedFeeStudent?.id === updatedStudent.id) {
      setSelectedFeeStudent(updatedStudent);
    }
  };

  const uniqueBoards = Array.from(new Set(students.map(s => s.board))).filter(Boolean);
  const uniqueGrades = Array.from(new Set(students.map(s => s.grade))).filter(Boolean);
  const uniqueSubjects = Array.from(new Set(
    students.flatMap(s => s.subjects?.map(subject => 
      typeof subject === 'string' ? subject : subject.name
    ) || [])
  )).filter(Boolean);

  const filterStudents = useCallback(() => {
    return students.filter(student => {
      const searchLower = searchQuery.toLowerCase();
      const matchesSearch = student.name?.toLowerCase().includes(searchLower) ||
        student.email?.toLowerCase().includes(searchLower) ||
        student.phone?.includes(searchQuery) ||
        student.parentPhone?.includes(searchQuery);

      const matchesBoard = !selectedBoard || student.board === selectedBoard;
      const matchesGrade = !selectedGrade || student.grade === selectedGrade;
      const matchesStatus = !selectedStatus || student.status === selectedStatus;
      const matchesSubject = !selectedSubject || 
        student.subjects?.some(sub => 
          (typeof sub === 'string' ? sub : sub.name) === selectedSubject
        );

      const matchesDate = (!dateRange.start || !dateRange.end) ? true :
        (student.joinDate && new Date(student.joinDate) >= new Date(dateRange.start) &&
         new Date(student.joinDate) <= new Date(dateRange.end));

      return matchesSearch && matchesBoard && matchesGrade && 
             matchesStatus && matchesSubject && matchesDate;
    });
  }, [students, searchQuery, selectedBoard, selectedGrade, selectedStatus, 
      selectedSubject, dateRange]);

  const filteredStudents = filterStudents();

  const calculateStats = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, 1);

    // Calculate fiscal year start and end
    const fiscalYearStart = new Date(
      currentMonth < 3 ? currentYear - 1 : currentYear, 
      3, // April
      1
    );
    const fiscalYearEnd = new Date(
      currentMonth < 3 ? currentYear : currentYear + 1, 
      2, // March
      31
    );

    // Calculate next month's due fees
    const nextMonthDue = filteredStudents.reduce((total, student) => {
      const nextMonthInstallments = student.feeCalculation?.installments?.filter(inst => {
        const dueDate = new Date(inst.dueDate);
        return dueDate.getMonth() === nextMonth.getMonth() && 
               dueDate.getFullYear() === nextMonth.getFullYear() && 
               !inst.paid;
      }) || [];
      return total + nextMonthInstallments.reduce((sum, inst) => sum + inst.amount, 0);
    }, 0);

    // Calculate this month's due fees
    const thisMonthDue = filteredStudents.reduce((total, student) => {
      const thisMonthInstallments = student.feeCalculation?.installments?.filter(inst => {
        const dueDate = new Date(inst.dueDate);
        return dueDate.getMonth() === currentMonth && 
               dueDate.getFullYear() === currentYear && 
               !inst.paid;
      }) || [];
      return total + thisMonthInstallments.reduce((sum, inst) => sum + inst.amount, 0);
    }, 0);

    // Calculate online revenue for this month
    const onlineRevenue = filteredStudents.reduce((total, student) => {
      const thisMonthOnlinePayments = student.feeCalculation?.installments?.filter(inst => {
        const paidDate = inst.paidDate ? new Date(inst.paidDate) : null;
        return paidDate?.getMonth() === currentMonth && 
               paidDate?.getFullYear() === currentYear && 
               inst.paid && 
               inst.paymentMode === 'online';
      }) || [];
      return total + thisMonthOnlinePayments.reduce((sum, inst) => sum + inst.amount, 0);
    }, 0);

    // Calculate branch-wise revenue for past 3 months
    const branchRevenue = new Map();
    filteredStudents.forEach(student => {
      const branch = student.branch || 'Unknown';
      const revenue = student.feeCalculation?.installments?.filter(inst => {
        const paidDate = inst.paidDate ? new Date(inst.paidDate) : null;
        return paidDate && paidDate >= threeMonthsAgo && inst.paid;
      }).reduce((sum, inst) => sum + inst.amount, 0) || 0;
      
      branchRevenue.set(branch, (branchRevenue.get(branch) || 0) + revenue);
    });

    // Find highest performing branch
    let highestBranch = { name: 'N/A', revenue: 0 };
    branchRevenue.forEach((revenue, branch) => {
      if (revenue > highestBranch.revenue) {
        highestBranch = { name: branch, revenue };
      }
    });

    // Calculate total fees for fiscal year (received + due)
    const fiscalYearTotal = filteredStudents.reduce((total, student) => {
      const installments = student.feeCalculation?.installments || [];
      return total + installments.reduce((sum, inst) => {
        const dueDate = new Date(inst.dueDate);
        // Include if due date falls within fiscal year
        if (dueDate >= fiscalYearStart && dueDate <= fiscalYearEnd) {
          return sum + inst.amount;
        }
        return sum;
      }, 0);
    }, 0);

    return {
      totalStudents: filteredStudents.length,
      nextMonthDue,
      thisMonthDue,
      highestBranch,
      onlineRevenue,
      admissionPending: filteredStudents.filter(s => s.status === 'admission_due').length,
      recentJoins: filteredStudents.filter(s => {
        const joinDate = s.joinDate ? new Date(s.joinDate) : null;
        if (!joinDate) return false;
        const diffTime = Math.abs(today.getTime() - joinDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays <= 30;
      }).length,
      fiscalYearTotal
    };
  };

  const stats = calculateStats();

  const handleClearFilters = () => {
    setSearchQuery('');
    setSelectedBoard('');
    setSelectedGrade('');
    setSelectedStatus('');
    setSelectedSubject('');
    setDateRange({ start: null, end: null });
  };

  const toggleRow = (studentId: string) => {
    setExpandedRows(prev => ({
      ...prev,
      [studentId]: !prev[studentId]
    }));
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header with Analytics Button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" sx={{ 
          fontFamily: 'sweater-school', 
          color: '#2C3E50',
          borderBottom: '2px solid #3498db',
          pb: 1
        }}>
          Students Database
        </Typography>
        <Button
          variant="contained"
          startIcon={<BarChart2 />}
          onClick={() => setAnalyticsOpen(true)}
          sx={{
            bgcolor: '#3498db',
            '&:hover': {
              bgcolor: '#2980b9'
            }
          }}
        >
          View Analytics
        </Button>
      </Box>

      {/* Search and Filters */}
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by name, email, or phone..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="outlined"
              onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
              startIcon={showAdvancedFilters ? <ChevronUp /> : <ChevronDown />}
              sx={{ mr: 1 }}
            >
              Advanced Filters
            </Button>
            {(showAdvancedFilters || selectedBoard || selectedGrade || selectedStatus || 
              selectedSubject || dateRange.start || dateRange.end) && (
              <Button
                variant="outlined"
                color="error"
                onClick={handleClearFilters}
                startIcon={<X />}
              >
                Clear Filters
              </Button>
            )}
          </Grid>

          <Collapse in={showAdvancedFilters} sx={{ width: '100%' }}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Board</InputLabel>
                  <Select
                    value={selectedBoard}
                    onChange={(e) => setSelectedBoard(e.target.value)}
                    label="Board"
                  >
                    <MenuItem value="">All Boards</MenuItem>
                    {uniqueBoards.map((board) => (
                      <MenuItem key={board} value={board}>
                        {board}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Grade</InputLabel>
                  <Select
                    value={selectedGrade}
                    onChange={(e) => setSelectedGrade(e.target.value)}
                    label="Grade"
                  >
                    <MenuItem value="">All Grades</MenuItem>
                    {uniqueGrades.map((grade) => (
                      <MenuItem key={grade} value={grade}>
                        {grade}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    label="Status"
                  >
                    <MenuItem value="">All Status</MenuItem>
                    {STUDENT_STATUSES.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status.replace('_', ' ').toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Subject</InputLabel>
                  <Select
                    value={selectedSubject}
                    onChange={(e) => setSelectedSubject(e.target.value)}
                    label="Subject"
                  >
                    <MenuItem value="">All Subjects</MenuItem>
                    {uniqueSubjects.map((subject) => (
                      <MenuItem key={subject} value={subject}>
                        {subject}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack direction="row" spacing={2}>
                    <DatePicker
                      label="Join Date From"
                      value={dateRange.start}
                      onChange={(date) => setDateRange(prev => ({ ...prev, start: date }))}
                      slots={{
                        textField: (params) => <TextField {...params} fullWidth />
                      }}
                    />
                    <DatePicker
                      label="Join Date To"
                      value={dateRange.end}
                      onChange={(date) => setDateRange(prev => ({ ...prev, end: date }))}
                      slots={{
                        textField: (params) => <TextField {...params} fullWidth />
                      }}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Box>

      {/* Stats Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: '#3498db', color: 'white' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontFamily: 'sweater-school', mb: 1 }}>
                Total Students
              </Typography>
              <Typography variant="h4" sx={{ fontFamily: 'sweater-school' }}>
                {stats.totalStudents}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, opacity: 0.9 }}>
                Across all grades and boards
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: '#2ecc71', color: 'white' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontFamily: 'sweater-school', mb: 1 }}>
                Total Fees Due (Next Month)
              </Typography>
              <Typography variant="h4" sx={{ fontFamily: 'sweater-school' }}>
                ₹{stats.nextMonthDue.toLocaleString()}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, opacity: 0.9 }}>
                Expected revenue next month
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: '#e74c3c', color: 'white' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontFamily: 'sweater-school', mb: 1 }}>
                Due This Month
              </Typography>
              <Typography variant="h4" sx={{ fontFamily: 'sweater-school' }}>
                ₹{stats.thisMonthDue.toLocaleString()}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, opacity: 0.9 }}>
                Pending payments this month
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: '#f39c12', color: 'white' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontFamily: 'sweater-school', mb: 1 }}>
                High Performing Branch
              </Typography>
              <Typography variant="h4" sx={{ fontFamily: 'sweater-school' }}>
                {stats.highestBranch.name}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, opacity: 0.9 }}>
                ₹{stats.highestBranch.revenue.toLocaleString()} in last 3 months
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Second Row */}
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: '#9b59b6', color: 'white' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontFamily: 'sweater-school', mb: 1 }}>
                Online Revenue
              </Typography>
              <Typography variant="h4" sx={{ fontFamily: 'sweater-school' }}>
                ₹{stats.onlineRevenue.toLocaleString()}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, opacity: 0.9 }}>
                Online payments this month
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: '#16a085', color: 'white' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontFamily: 'sweater-school', mb: 1 }}>
                Recent Joins
              </Typography>
              <Typography variant="h4" sx={{ fontFamily: 'sweater-school' }}>
                {stats.recentJoins}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, opacity: 0.9 }}>
                In the last 30 days
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: '#d35400', color: 'white' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontFamily: 'sweater-school', mb: 1 }}>
                Admission Pending
              </Typography>
              <Typography variant="h4" sx={{ fontFamily: 'sweater-school' }}>
                {stats.admissionPending}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, opacity: 0.9 }}>
                New applications to process
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: '#8e44ad', color: 'white' }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontFamily: 'sweater-school', mb: 1 }}>
                Fiscal Year Total
              </Typography>
              <Typography variant="h4" sx={{ fontFamily: 'sweater-school' }}>
                ₹{stats.fiscalYearTotal.toLocaleString()}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, opacity: 0.9 }}>
                Total fees Apr {stats.fiscalYearTotal === 0 ? '' : (new Date().getMonth() < 3 ? new Date().getFullYear() - 1 : new Date().getFullYear())} - Mar {stats.fiscalYearTotal === 0 ? '' : (new Date().getMonth() < 3 ? new Date().getFullYear() : new Date().getFullYear() + 1)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Enhanced Students Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Grade</TableCell>
              <TableCell>Board</TableCell>
              <TableCell>School</TableCell>
              <TableCell>Contact Info</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Subjects</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStudents.map((student) => (
              <React.Fragment key={student.id}>
                <TableRow>
                  <TableCell>{student.name}</TableCell>
                  <TableCell>{student.studentId}</TableCell>
                  <TableCell sx={{ 
                    bgcolor: 'rgba(0, 0, 0, 0.04)',
                    minWidth: '100px'
                  }}>
                    {student.grade || '-'}
                  </TableCell>
                  <TableCell>{student.board}</TableCell>
                  <TableCell>{student.school}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {student.phone && (
                        <Tooltip title={`Phone: ${student.phone}`}>
                          <IconButton size="small" component={Link} href={`tel:${student.phone}`}>
                            <Phone size={16} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {student.email && (
                        <Tooltip title={`Email: ${student.email}`}>
                          <IconButton size="small" component={Link} href={`mailto:${student.email}`}>
                            <Mail size={16} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {student.address && (
                        <Tooltip title={student.address}>
                          <IconButton size="small">
                            <MapPin size={16} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: student.status === 'active' ? 'success.main' :
                               student.status === 'inactive' ? 'error.main' :
                               student.status === 'admission_due' ? 'warning.main' :
                               student.status === 'admission_confirmed' ? 'info.main' :
                               'text.secondary'
                      }}
                    >
                      {student.status?.toUpperCase()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {student.subjects?.map((subject, index) => (
                        <Tooltip 
                          key={index}
                          title={
                            typeof subject !== 'string' && subject.startDate && subject.endDate
                              ? `${new Date(subject.startDate).toLocaleDateString()} - ${new Date(subject.endDate).toLocaleDateString()}`
                              : ''
                          }
                        >
                          <Chip
                            label={typeof subject === 'string' ? subject : subject.name}
                            size="small"
                            sx={{ bgcolor: '#e3f2fd' }}
                          />
                        </Tooltip>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="View Fee Details">
                        <IconButton 
                          onClick={() => {
                            setSelectedFeeStudent(student);
                            setFeeDetailsOpen(true);
                          }}
                          sx={{ color: '#3498db' }}
                        >
                          <DollarSign size={20} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Student">
                        <IconButton onClick={() => handleEditClick(student)}>
                          <Edit size={20} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Show Details">
                        <IconButton onClick={() => toggleRow(student.id)}>
                          {expandedRows[student.id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Collapse in={expandedRows[student.id]} timeout="auto" unmountOnExit>
                      <Box sx={{ p: 2 }}>
                        {/* Fee details section */}
                        <div className="mt-2 space-y-1 text-sm">
                          {/* Subject-wise fees */}
                          {student.feeCalculation?.subjectFees?.map((subjectFee, index) => (
                            <div key={index} className="flex justify-between">
                              <span>{subjectFee.subject}</span>
                              <span>₹{Math.round(subjectFee.monthlyFee).toLocaleString()}/month</span>
                            </div>
                          ))}
                          
                          {/* Total calculations */}
                          <div className="mt-2 pt-2 border-t">
                            <div>Subtotal: ₹{student.feeCalculation?.subtotal?.toLocaleString()}</div>
                            {student.feeCalculation?.discount?.subjectDiscount?.amount > 0 && (
                              <div>Multi-subject Discount (-{student.feeCalculation.discount.subjectDiscount.percentage}%): 
                                -₹{student.feeCalculation.discount.subjectDiscount.amount.toLocaleString()}</div>
                            )}
                            {student.feeCalculation?.oneToOne?.enabled && (
                              <div>1:1 Mode (+{student.feeCalculation.oneToOne.percentage}%)</div>
                            )}
                            {student.feeCalculation?.gst?.enabled && (
                              <div>GST: ₹{student.feeCalculation.gst.amount.toLocaleString()}</div>
                            )}
                            <div className="font-medium">Total: ₹{student.feeCalculation?.totalFees.toLocaleString()}</div>
                          </div>
                        </div>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" gutterBottom component="div" sx={{ fontWeight: 'bold' }}>
                              Contact Information
                            </Typography>
                            <Stack spacing={1}>
                              {student.contacts?.map((contact, index) => (
                                <Box key={index}>
                                  <Typography variant="body2">
                                    <strong>{contact.name}</strong> ({contact.relation})
                                  </Typography>
                                  <Typography variant="body2">
                                    Phone: {contact.phone}
                                  </Typography>
                                </Box>
                              ))}
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" gutterBottom component="div" sx={{ fontWeight: 'bold' }}>
                              Additional Information
                            </Typography>
                            <Typography variant="body2">
                              <strong>Branch:</strong> {student.branch || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Joined:</strong> {student.joinDate ? new Date(student.joinDate).toLocaleDateString() : 'N/A'}
                            </Typography>
                            {student.notes && (
                              <Typography variant="body2">
                                <strong>Notes:</strong> {student.notes}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialogs */}
      <StudentEditDialog
        open={editDialogOpen}
        student={selectedStudent}
        onClose={handleEditClose}
        onUpdate={handleEditComplete}
      />
      <FeeAnalyticsDialog
        open={analyticsOpen}
        onClose={() => setAnalyticsOpen(false)}
        students={students}
      />
      <FeeDetailsDialog
        open={feeDetailsOpen}
        onClose={() => setFeeDetailsOpen(false)}
        student={selectedFeeStudent}
        onUpdate={handleFeeDetailsUpdate}
      />
    </Box>
  );
}
