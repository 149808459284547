import { useState, useEffect } from 'react';
import { 
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { User } from '../types';
import toast from 'react-hot-toast';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          console.log('Firebase user:', firebaseUser);
          // Special handling for admin user
          if (firebaseUser.email === 'admin@questo.com') {
            console.log('Setting admin user');
            const adminUser = {
              id: firebaseUser.uid,
              email: firebaseUser.email,
              name: 'Admin',
              role: 'admin'
            };
            console.log('Admin user object:', adminUser);
            setUser(adminUser);
          } else {
            console.log('Getting teacher data');
            const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
            if (userDoc.exists()) {
              const userData = {
                id: firebaseUser.uid,
                email: firebaseUser.email!,
                ...userDoc.data()
              } as User;
              console.log('Teacher user object:', userData);
              setUser(userData);
            }
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          toast.error('Error loading user data');
        }
      } else {
        console.log('No firebase user');
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      console.log('Attempting sign in with:', email);
      await signInWithEmailAndPassword(auth, email, password);
      
      // Special handling for admin user
      if (email === 'admin@questo.com') {
        console.log('Admin login attempt');
        if (password !== 'libral@500') {
          await firebaseSignOut(auth);
          toast.error('Invalid admin password');
          return;
        }
        
        // Force set admin user immediately after login
        const adminUser = {
          id: auth.currentUser!.uid,
          email: 'admin@questo.com',
          name: 'Admin',
          role: 'admin'
        };
        console.log('Setting admin user on login:', adminUser);
        setUser(adminUser);
        toast.success('Signed in as admin');
      } else {
        // For teachers, get their data from Firestore
        console.log('Getting teacher data on login');
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser!.uid));
        if (userDoc.exists()) {
          const userData = {
            id: auth.currentUser!.uid,
            email: auth.currentUser!.email!,
            ...userDoc.data()
          } as User;
          console.log('Teacher user object on login:', userData);
          setUser(userData);
          toast.success('Signed in successfully');
        } else {
          await firebaseSignOut(auth);
          toast.error('User not found');
        }
      }
    } catch (error: any) {
      console.error('Sign in error:', error);
      let errorMessage = 'An error occurred during sign in';
      
      // Handle specific Firebase Auth errors
      switch (error.code) {
        case 'auth/invalid-email':
          errorMessage = 'Invalid email address format';
          break;
        case 'auth/user-not-found':
          errorMessage = 'No account found with this email';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Incorrect password';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Too many failed attempts. Please try again later';
          break;
        case 'auth/user-disabled':
          errorMessage = 'This account has been disabled';
          break;
        case 'auth/network-request-failed':
          errorMessage = 'Network error. Please check your internet connection';
          break;
      }
      
      toast.error(errorMessage);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      console.log('Attempting sign out');
      await firebaseSignOut(auth);
      toast.success('Signed out successfully');
    } catch (error) {
      console.error('Sign out error:', error);
      toast.error('Failed to sign out');
      throw error;
    }
  };

  return {
    user,
    loading,
    signIn,
    signOut
  };
}