// Create audio element lazily
let audio: HTMLAudioElement | null = null;

export const playCoinSound = async () => {
  try {
    // Create audio element on first use
    if (!audio) {
      audio = new Audio('/src/assets/sounds/coin.mp3');
      audio.volume = 0.5; // Set volume to 50%
    }

    // Reset and play
    audio.currentTime = 0;
    await audio.play();
  } catch (error) {
    console.error('Error playing coin sound:', error);
  }
};
