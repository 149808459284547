import React, { useEffect, useRef } from 'react';
import { Clock } from 'lucide-react';
import { format } from 'date-fns';

interface AttendanceButtonProps {
  type: 'IN' | 'OUT';
  lastTimestamp: Date | null;
  onPress: () => void;
  disabled?: boolean;
  autoLogoutStatus?: 'normal' | 'overtime' | 'late';
}

export default function AttendanceButton({ 
  type, 
  lastTimestamp, 
  onPress,
  disabled = false,
  autoLogoutStatus = 'normal'
}: AttendanceButtonProps) {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    // Create audio element with cache-busting query parameter
    const audio = new Audio('/sounds/attendance.mp3?v=' + Date.now());
    audio.volume = 1.0; // Set volume to 100%
    audioRef.current = audio;

    // Cleanup on unmount
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const handleClick = () => {
    if (!disabled) {
      // Play sound with error handling
      if (audioRef.current) {
        audioRef.current.currentTime = 0; // Reset audio to start
        audioRef.current.play().catch(error => {
          console.error('Error playing sound:', error);
          // Continue with the click handler even if sound fails
        });
      }
      // Call the original onPress handler
      onPress();
    }
  };

  const getStatusColor = () => {
    if (autoLogoutStatus === 'overtime' || autoLogoutStatus === 'late') {
      return 'bg-pink-700 hover:bg-pink-800 active:bg-pink-700 shadow-[0_10px_0_0_rgb(157,23,77)]';
    }
    return type === 'IN' ? 'attendance-btn-in' : 'attendance-btn-out';
  };

  const getTimestampColor = () => {
    if (autoLogoutStatus === 'overtime' || autoLogoutStatus === 'late') {
      return 'text-pink-700';
    }
    return 'text-gray-600';
  };

  return (
    <div className="flex flex-col items-center group">
      <button
        onClick={handleClick}
        disabled={disabled}
        className={`attendance-btn ${getStatusColor()} disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100`}
      >
        <Clock className="w-12 h-12 mb-4 group-hover:rotate-12 transition-transform" />
        <span className="text-2xl font-bold tracking-widest">{type}</span>
      </button>
      {lastTimestamp && (
        <div className="mt-6 px-6 py-3 bg-white rounded-xl shadow-md">
          <p className={`text-sm font-medium ${getTimestampColor()}`}>
            Last {type}: {format(lastTimestamp, 'hh:mm a')}
            {autoLogoutStatus === 'overtime' && ' (Auto-logout: Over 10 hours)'}
            {autoLogoutStatus === 'late' && ' (Auto-logout: Past 10 PM IST)'}
          </p>
        </div>
      )}
    </div>
  );
}