import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { format, eachDayOfInterval, subDays, subMonths, differenceInMinutes, differenceInHours, startOfMonth, endOfMonth, isSunday, isToday, addMonths, addHours, addMinutes } from 'date-fns';
import toast from 'react-hot-toast';
import { Calendar, ChevronDown, ChevronUp, Download } from 'lucide-react';
import * as XLSX from 'xlsx';
import LeaveForm from './LeaveForm';

interface AttendanceRecord {
  date: Date;
  status: 'present' | 'absent' | 'leave' | 'weekend' | 'in_progress' | 'not_logged';
  punchInTime?: Date;
  punchOutTime?: Date;
  workingHours?: string | number;
  leaveReason?: string;
  inLocation?: {
    latitude: number;
    longitude: number;
    address?: string;
  };
  outLocation?: {
    latitude: number;
    longitude: number;
    address?: string;
  };
  isSunday?: boolean;
}

interface MonthlyRecord {
  month: string;
  totalWorkingDays: number;
  totalWorkingHours: number;
  totalLeaves: number;
  averageHours: string;
}

export default function TeacherAttendance() {
  const { user } = useAuth();
  const [recentAttendance, setRecentAttendance] = useState<AttendanceRecord[]>([]);
  const [monthlyRecords, setMonthlyRecords] = useState<MonthlyRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(() => new Date());

  useEffect(() => {
    const updateCurrentTime = () => {
      const now = new Date();
      setCurrentTime(now);
    };

    updateCurrentTime();

    const timer = setInterval(updateCurrentTime, 60000);
    return () => clearInterval(timer);
  }, []);

  const isWithinGracePeriod = () => {
    const hours = currentTime.getHours();
    return hours >= 0 && hours < 18;
  };

  useEffect(() => {
    const fetchAttendance = async () => {
      if (!user?.id) return;

      try {
        const todayDate = format(currentTime, 'yyyy-MM-dd');
        const today = new Date(todayDate);
        const thirtyDaysAgo = subDays(today, 30);
        const sixMonthsAgo = subMonths(today, 6);

        const todayStart = new Date(todayDate);
        const todayQuery = query(
          collection(db, 'attendance'),
          where('teacherId', '==', user.id),
          where('timestamp', '>=', Timestamp.fromDate(todayStart))
        );

        const todaySnapshot = await getDocs(todayQuery);
        const hasTodayRecord = !todaySnapshot.empty;
        const isGracePeriod = isWithinGracePeriod();

        const attendanceQuery = query(
          collection(db, 'attendance'),
          where('teacherId', '==', user.id),
          where('timestamp', '>=', Timestamp.fromDate(thirtyDaysAgo))
        );

        const attendanceSnapshot = await getDocs(attendanceQuery);
        const attendanceMap = new Map();

        console.log('Fetching attendance records:', {
          teacherId: user.id,
          startDate: format(thirtyDaysAgo, 'yyyy-MM-dd'),
          endDate: format(today, 'yyyy-MM-dd'),
          recordsFound: attendanceSnapshot.size
        });

        attendanceSnapshot.docs.forEach(doc => {
          const data = doc.data();
          const date = format(data.timestamp.toDate(), 'yyyy-MM-dd');
          console.log('Processing record:', {
            date,
            type: data.type,
            docId: doc.id
          });
          const record = attendanceMap.get(date) || {
            inTime: null,
            outTime: null,
            inLocation: null,
            outLocation: null,
            status: 'absent',
            leaveReason: null
          };

          console.log('Processing record for date:', date, 'type:', data.type);

          if (data.type === 'IN') {
            record.inTime = data.timestamp.toDate();
            record.inLocation = data.location;
            record.status = 'in_progress';
          } else if (data.type === 'OUT') {
            record.outTime = data.timestamp.toDate();
            record.outLocation = data.location;
            record.status = 'present';
          } else if (data.type === 'LEAVE') {
            record.status = 'leave';
            record.leaveReason = data.leaveReason;
          }

          attendanceMap.set(date, record);
        });

        const recentDays = eachDayOfInterval({
          start: thirtyDaysAgo,
          end: today,
        }).reverse();

        const recentRecords = recentDays.map(date => {
          const dateStr = format(date, 'yyyy-MM-dd');
          const isCurrentDay = dateStr === todayDate;
          const record = attendanceMap.get(dateStr);

          // Handle current day with grace period first
          if (isCurrentDay) {
            if (!hasTodayRecord && isGracePeriod) {
              return {
                date,
                status: 'not_logged' as const
              };
            }
          }

          if (isSunday(date)) {
            return {
              date,
              status: 'weekend' as const,
              isSunday: true
            };
          }

          if (record) {
            if (record.status === 'leave') {
              return {
                date,
                status: 'leave' as const,
                leaveReason: record.leaveReason
              };
            }

            if (record.inTime) {
              const workingHours = record.outTime 
                ? calculateWorkingHours(record.inTime, record.outTime)
                : isCurrentDay 
                  ? calculateWorkingHours(record.inTime, currentTime)
                  : 'In Progress';

              return {
                date,
                status: record.outTime ? 'present' : 'in_progress',
                punchInTime: record.inTime,
                punchOutTime: record.outTime,
                workingHours,
                inLocation: record.inLocation,
                outLocation: record.outLocation
              };
            }
          }

          return {
            date,
            status: isCurrentDay && isGracePeriod ? 'not_logged' : 'absent' as const,
            inLocation: null,
            outLocation: null
          };
        });

        console.log('Recent records:', recentRecords); 
        setRecentAttendance(recentRecords);

        const months: MonthlyRecord[] = [];
        let currentDate = sixMonthsAgo;

        while (currentDate < thirtyDaysAgo) {
          const monthStart = startOfMonth(currentDate);
          const monthEnd = endOfMonth(currentDate);
          const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });
          
          let totalWorkingDays = 0;
          let totalWorkingHours = 0;
          let totalLeaves = 0;
          
          daysInMonth.forEach(day => {
            if (!isSunday(day)) {
              const record = attendanceMap.get(format(day, 'yyyy-MM-dd'));
              if (record?.inTime && record?.outTime) {
                totalWorkingDays++;
                const hours = differenceInHours(record.outTime, record.inTime);
                totalWorkingHours += hours;
              } else if (record?.status === 'leave') {
                totalLeaves++;
              }
            }
          });

          months.push({
            month: format(monthStart, 'MMMM yyyy'),
            totalWorkingDays,
            totalWorkingHours,
            totalLeaves,
            averageHours: totalWorkingDays > 0 ? (totalWorkingHours / totalWorkingDays).toFixed(1) : '0'
          });

          currentDate = addMonths(currentDate, 1);
        }

        setMonthlyRecords(months.reverse());
      } catch (error) {
        console.error('Error fetching attendance:', error);
        toast.error('Failed to load attendance history');
      } finally {
        setLoading(false);
      }
    };

    fetchAttendance();
  }, [user?.id, currentTime]);

  const calculateWorkingHours = (inTime: Date, outTime: Date) => {
    const minutes = differenceInMinutes(outTime, inTime);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const exportToExcel = () => {
    try {
      const exportData = recentAttendance
        .filter(record => record.status === 'present')
        .map(record => ({
          'Date': format(record.date, 'dd/MM/yyyy'),
          'Status': record.status,
          'Punch In': record.punchInTime ? format(record.punchInTime, 'hh:mm a') : '-',
          'Punch In Location': record.inLocation?.address || '-',
          'Punch Out': record.punchOutTime ? format(record.punchOutTime, 'hh:mm a') : '-',
          'Punch Out Location': record.outLocation?.address || '-',
          'Working Hours': record.workingHours || '-'
        }));

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Attendance');

      const colWidths = Object.keys(exportData[0] || {}).map(key => ({
        wch: Math.max(
          key.length,
          ...exportData.map(row => String(row[key]).length)
        )
      }));
      ws['!cols'] = colWidths;

      const fileName = `attendance_${format(new Date(), 'yyyy-MM-dd')}.xlsx`;
      XLSX.writeFile(wb, fileName);
      toast.success('Excel file downloaded successfully');
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      toast.error('Failed to export data');
    }
  };

  const toggleMonthExpand = (index: number) => {
    setMonthlyRecords(prevRecords => 
      prevRecords.map((record, i) => 
        i === index ? { ...record, expanded: !record.expanded } : record
      )
    );
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'present':
        return 'bg-green-100 text-green-800';
      case 'leave':
        return 'bg-yellow-100 text-yellow-800';
      case 'in_progress':
        return 'bg-blue-100 text-blue-800';
      case 'weekend':
        return 'bg-gray-100 text-gray-800';
      case 'not_logged':
        return 'bg-gray-100 text-gray-500';
      case 'absent':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-500';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'present':
        return 'Present';
      case 'leave':
        return 'Leave';
      case 'in_progress':
        return 'In Progress';
      case 'weekend':
        return 'Weekend';
      case 'not_logged':
        return 'Not Logged In Yet';
      case 'absent':
        return 'Absent';
      default:
        return 'Not Logged In Yet';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Leave Form */}
      <LeaveForm />

      {/* Recent Attendance (30 days) */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-xl font-bold text-secondary mb-4">Recent Attendance (Last 30 Days)</h2>
        <div className="space-y-4">
          {recentAttendance.map((record, index) => (
            <div 
              key={format(record.date, 'yyyy-MM-dd')} 
              className={`p-4 rounded-lg ${getStatusColor(record.status)}`}
            >
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                <div>
                  <div className="font-semibold flex items-center">
                    {format(record.date, 'dd MMM yyyy')} {format(record.date, 'EEEE')}
                  </div>
                  {record.status !== 'not_logged' && record.status !== 'weekend' && record.status !== 'leave' && (
                    <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                      {record.punchInTime && (
                        <div className="flex flex-col bg-white/50 rounded-lg p-3">
                          <span className="font-medium text-gray-700">Check In</span>
                          <span className="text-lg font-semibold text-blue-800">
                            {format(record.punchInTime, 'hh:mm a')}
                          </span>
                          {record.inLocation?.address && (
                            <span className="text-sm text-gray-600 mt-1">
                              📍 {record.inLocation.address}
                            </span>
                          )}
                        </div>
                      )}
                      {record.punchOutTime && (
                        <div className="flex flex-col bg-white/50 rounded-lg p-3">
                          <span className="font-medium text-gray-700">Check Out</span>
                          <span className="text-lg font-semibold text-blue-800">
                            {format(record.punchOutTime, 'hh:mm a')}
                          </span>
                          {record.outLocation?.address && (
                            <span className="text-sm text-gray-600 mt-1">
                              📍 {record.outLocation.address}
                            </span>
                          )}
                        </div>
                      )}
                      {record.workingHours && (
                        <div className="md:col-span-2 text-sm font-medium text-gray-700 mt-1">
                          ⏱️ Working Hours: {record.workingHours}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(record.status)}`}>
                  {getStatusText(record.status)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Monthly Records */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold text-secondary mb-6">Monthly History</h2>
        <div className="space-y-4">
          {monthlyRecords.map((month, index) => (
            <div key={index} className="border rounded-lg">
              <button
                onClick={() => toggleMonthExpand(index)}
                className="w-full p-4 flex items-center justify-between hover:bg-gray-50"
              >
                <div className="flex-1">
                  <h3 className="text-lg font-semibold">{month.month}</h3>
                  <div className="grid grid-cols-3 gap-4 mt-2 text-sm text-gray-600">
                    <div>Working Days: {month.totalWorkingDays} days</div>
                    <div>Total Hours: {month.totalWorkingHours} hours</div>
                    <div>Leaves: {month.totalLeaves} days</div>
                  </div>
                </div>
                {month.expanded ? (
                  <ChevronUp className="w-5 h-5 text-gray-500" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
              
              {month.expanded && (
                <div className="border-t p-4 space-y-3">
                  <p className="text-sm text-gray-600">Average Hours per Day: {month.averageHours}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
