import { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, query, where, limit, Timestamp, updateDoc, writeBatch, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { format, differenceInHours, addHours, addMinutes, isAfter, setHours, setMinutes, subDays, isSameDay, isSunday, isWeekend, utcToZonedTime, getHours } from 'date-fns';
import { useAuth } from './useAuth';
import toast from 'react-hot-toast';
import { AttendanceLog, GeoLocation } from '../types';

export function useAttendance(userId: string) {
  const [lastInTime, setLastInTime] = useState<Date | null>(null);
  const [lastOutTime, setLastOutTime] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [autoLogoutStatus, setAutoLogoutStatus] = useState<'normal' | 'overtime' | 'late'>('normal');
  const { signOut, currentUser } = useAuth();
  const MAX_WORK_HOURS = 10;

  // Get geolocation with address
  const getLocation = async (): Promise<GeoLocation> => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser'));
        return;
      }

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            // Get address using Google Maps Geocoding API
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD40wrAjRXfGpFOyDP3j2iPofq9Pmjzt_I`
            );
            const data = await response.json();
            const address = data.results[0]?.formatted_address;
            
            resolve({
              latitude,
              longitude,
              address
            });
          } catch (error) {
            // If geocoding fails, still return coordinates without address
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          }
        },
        (error) => {
          reject(new Error('Failed to get location: ' + error.message));
        }
      );
    });
  };

  const isWithinGracePeriod = () => {
    // Use the provided time
    const now = new Date('2024-12-12T00:13:32+05:30');
    const hours = now.getHours();
    return hours >= 0 && hours < 18; // Between 12 AM and 6 PM IST
  };

  const processAttendanceRecord = (record: any, date: Date) => {
    if (!record) {
      if (isWeekend(date)) {
        return { status: 'weekend', isSunday: isSunday(date) };
      }
      
      // Check if it's today and within grace period
      const isToday = isSameDay(date, new Date());
      if (isToday && isWithinGracePeriod()) {
        return { status: 'not_logged' };
      }
      
      return { status: 'absent' };
    }

    if (record.type === 'leave') {
      return { status: 'leave', reason: record.reason };
    }

    if (!record.out && isSameDay(date, new Date())) {
      return { status: 'in_progress', inTime: record.in };
    }

    return { 
      status: 'present', 
      inTime: record.in,
      outTime: record.out 
    };
  };

  // Auto signout check timer
  useEffect(() => {
    if (!lastInTime || lastOutTime) return;

    const checkWorkDuration = () => {
      const now = new Date();
      const hoursWorked = differenceInHours(now, lastInTime);
      
      // Check for overtime (more than 10 hours)
      if (hoursWorked >= MAX_WORK_HOURS) {
        setAutoLogoutStatus('overtime');
        handleAttendance('OUT');
        toast.error('Maximum work duration (10 hours) reached. You will be signed out.');
        setTimeout(() => {
          signOut();
        }, 2000);
        return;
      }

      // Check for 10 PM IST cutoff
      const istTime = addMinutes(addHours(now, 5), 30); // Convert to IST
      const cutoffTime = setHours(setMinutes(new Date(istTime), 0), 22); // 10 PM IST
      
      if (isAfter(istTime, cutoffTime)) {
        setAutoLogoutStatus('late');
        handleAttendance('OUT');
        toast.error('It is past 10 PM IST. You will be signed out.');
        setTimeout(() => {
          signOut();
        }, 2000);
      }
    };

    const timer = setInterval(checkWorkDuration, 60000); // Check every minute
    return () => clearInterval(timer);
  }, [lastInTime, lastOutTime, signOut]);

  useEffect(() => {
    if (!userId) return;

    const loadTodayAttendance = async () => {
      try {
        const today = format(new Date(), 'yyyy-MM-dd');
        const attendanceRef = collection(db, 'attendance');
        
        // Query for IN record
        const inQuery = query(
          attendanceRef,
          where('teacherId', '==', userId),
          where('date', '==', today),
          where('type', '==', 'IN'),
          limit(1)
        );

        // Query for OUT record
        const outQuery = query(
          attendanceRef,
          where('teacherId', '==', userId),
          where('date', '==', today),
          where('type', '==', 'OUT'),
          limit(1)
        );

        // Query for LEAVE record
        const leaveQuery = query(
          attendanceRef,
          where('teacherId', '==', userId),
          where('date', '==', today),
          where('type', '==', 'LEAVE'),
          limit(1)
        );

        const [inSnapshot, outSnapshot, leaveSnapshot] = await Promise.all([
          getDocs(inQuery),
          getDocs(outQuery),
          getDocs(leaveQuery)
        ]);

        if (!inSnapshot.empty) {
          const inData = inSnapshot.docs[0].data();
          setLastInTime(inData.timestamp.toDate());
        }

        if (!outSnapshot.empty) {
          const outData = outSnapshot.docs[0].data();
          setLastOutTime(outData.timestamp.toDate());
        }

        // Check for past days without OUT punch
        try {
          console.log('Checking for past days without OUT punch...');
          const thirtyDaysAgo = subDays(new Date(), 30);
          const pastDaysQuery = query(
            attendanceRef,
            where('teacherId', '==', userId),
            where('date', '>=', format(thirtyDaysAgo, 'yyyy-MM-dd')),
            where('date', '<', today)
          );

          const pastDaysSnapshot = await getDocs(pastDaysQuery);
          console.log(`Found ${pastDaysSnapshot.size} attendance records to process`);
          const pastDays = new Map();

          // First pass: Collect all IN and OUT records
          pastDaysSnapshot.docs.forEach(doc => {
            const data = doc.data();
            const date = data.date;
            
            if (!pastDays.has(date)) {
              pastDays.set(date, {
                inTime: data.type === 'IN' ? data.timestamp.toDate() : null,
                outTime: data.type === 'OUT' ? data.timestamp.toDate() : null,
                status: data.type === 'LEAVE' ? 'leave' : null
              });
            } else {
              const record = pastDays.get(date);
              if (data.type === 'IN') record.inTime = data.timestamp.toDate();
              if (data.type === 'OUT') record.outTime = data.timestamp.toDate();
              if (data.type === 'LEAVE') record.status = 'leave';
            }
          });

          // Second pass: Mark automatic leave for days with IN but no OUT
          const batch = writeBatch(db);
          let updatesNeeded = 0;

          pastDays.forEach((record, date) => {
            if (record.inTime && !record.outTime && !record.status) {
              const newRef = doc(attendanceRef);
              batch.set(newRef, {
                teacherId: userId,
                type: 'OUT',
                timestamp: Timestamp.fromDate(addHours(record.inTime, MAX_WORK_HOURS)),
                date,
                status: 'auto_out',
                reason: 'Automatic OUT - No manual punch out'
              });
              updatesNeeded++;
            }
          });

          if (updatesNeeded > 0) {
            await batch.commit();
            console.log('Batch updates committed successfully');
            toast.success('Updated past attendance records');
          } else {
            console.log('No updates needed for past attendance records');
          }
        } catch (error) {
          console.error('Error processing past attendance:', error);
          toast.error('Failed to process past attendance records');
        }
      } catch (error) {
        console.error('Error loading attendance:', error);
        toast.error('Failed to load attendance data');
      } finally {
        setIsLoading(false);
      }
    };

    loadTodayAttendance();
  }, [userId]);

  useEffect(() => {
    if (!userId) return;

    const checkAndMarkLeave = async () => {
      try {
        const now = new Date();
        const today = format(now, 'yyyy-MM-dd');
        
        // Check if any attendance record exists for today
        const attendanceRef = collection(db, 'attendance');
        const todayQuery = query(
          attendanceRef,
          where('teacherId', '==', userId),
          where('date', '==', today)
        );

        const snapshot = await getDocs(todayQuery);
        const records = snapshot.docs.map(doc => doc.data());
        
        // If there's already an IN, OUT, or LEAVE record for today, don't mark as leave
        if (records.some(record => ['IN', 'OUT', 'LEAVE'].includes(record.type))) {
          return;
        }

        // Get current time in IST
        const istTime = addMinutes(addHours(now, 5), 30);
        // Set cutoff time to 10 PM IST
        const cutoffTime = setHours(setMinutes(new Date(istTime), 0), 22);
        
        // Only proceed if it's past 10 PM IST
        if (!isAfter(istTime, cutoffTime)) return;

        // Add leave record in attendance collection
        const attendanceData = {
          teacherId: userId,
          type: 'LEAVE',
          timestamp: Timestamp.fromDate(now),
          date: today,
          status: 'leave',
          leaveReason: 'Automatic leave - No login by 10 PM IST'
        };

        await addDoc(attendanceRef, attendanceData);
        console.log('Marked as leave due to no login by 10 PM IST');
      } catch (error) {
        console.error('Error in automatic leave marking:', error);
      }
    };

    // Check every minute
    const timer = setInterval(checkAndMarkLeave, 60000);
    
    // Initial check
    checkAndMarkLeave();

    return () => clearInterval(timer);
  }, [userId]);

  // Function to mark automatic leave for all teachers
  const markAutomaticLeaveForAllTeachers = async () => {
    try {
      if (!currentUser) {
        console.log('User not authenticated, skipping automatic leave marking');
        return;
      }

      const today = format(new Date(), 'yyyy-MM-dd');
      const now = new Date();

      // Get all teachers
      const usersRef = collection(db, 'users');
      const teachersSnapshot = await getDocs(query(usersRef, where('role', '==', 'teacher')));
      
      // Get current time in IST
      const istTime = addMinutes(addHours(now, 5), 30);
      // Set cutoff time to 10 PM IST
      const cutoffTime = setHours(setMinutes(new Date(istTime), 0), 22);
      
      // Only proceed if it's past 10 PM IST
      if (!isAfter(istTime, cutoffTime)) {
        console.log('Not yet cutoff time, skipping automatic leave marking');
        return;
      }

      const batch = writeBatch(db);
      const attendanceRef = collection(db, 'attendance');
      let leaveMarked = 0;

      for (const teacherDoc of teachersSnapshot.docs) {
        const teacherId = teacherDoc.id;
        try {
          // Check if any attendance record exists for today
          const todayQuery = query(
            attendanceRef,
            where('teacherId', '==', teacherId),
            where('date', '==', today)
          );

          const snapshot = await getDocs(todayQuery);
          const records = snapshot.docs.map(doc => doc.data());
          
          // If there's no IN, OUT, or LEAVE record for today, mark as leave
          if (!records.some(record => ['IN', 'OUT', 'LEAVE'].includes(record.type))) {
            const newAttendanceRef = doc(attendanceRef);
            batch.set(newAttendanceRef, {
              teacherId,
              type: 'LEAVE',
              timestamp: Timestamp.fromDate(now),
              date: today,
              status: 'leave',
              leaveReason: 'Automatic leave - No login by 10 PM IST',
              createdBy: currentUser.uid,
              createdAt: Timestamp.fromDate(now)
            });
            leaveMarked++;
          }
        } catch (error) {
          console.error(`Error processing teacher ${teacherId}:`, error);
          continue;
        }
      }

      if (leaveMarked > 0) {
        await batch.commit();
        console.log(`Marked automatic leave for ${leaveMarked} teachers`);
      } else {
        console.log('No teachers needed automatic leave marking');
      }
    } catch (error) {
      console.error('Error in automatic leave marking for all teachers:', error);
    }
  };

  // Run automatic leave check for all teachers every minute
  useEffect(() => {
    const timer = setInterval(markAutomaticLeaveForAllTeachers, 60000);
    
    // Initial check
    markAutomaticLeaveForAllTeachers();

    return () => clearInterval(timer);
  }, [currentUser]);

  // Modified handleAttendance to consider grace period
  const handleAttendance = async (type: 'IN' | 'OUT') => {
    if (!userId) return;

    try {
      const now = new Date();
      const today = format(now, 'yyyy-MM-dd');

      // For OUT type, check if work duration exceeds 10 hours
      if (type === 'OUT' && lastInTime) {
        const hoursWorked = differenceInHours(now, lastInTime);
        if (hoursWorked > MAX_WORK_HOURS) {
          now.setHours(lastInTime.getHours() + MAX_WORK_HOURS);
          toast.error(`Maximum work duration is ${MAX_WORK_HOURS} hours. Attendance will be marked accordingly.`);
        }
      }

      // Check if attendance already marked
      const checkQuery = query(
        collection(db, 'attendance'),
        where('teacherId', '==', userId),
        where('date', '==', today),
        where('type', '==', type)
      );

      const checkSnapshot = await getDocs(checkQuery);
      if (!checkSnapshot.empty) {
        toast.error(`You've already marked ${type} for today`);
        return;
      }

      // For OUT, check if IN exists
      if (type === 'OUT' && !lastInTime) {
        toast.error("You need to mark 'IN' before marking 'OUT'");
        return;
      }

      // Get current location
      let location: GeoLocation;
      try {
        location = await getLocation();
      } catch (error) {
        toast.error('Failed to get location. Please enable location services.');
        return;
      }

      const attendanceData: Omit<AttendanceLog, 'id'> = {
        teacherId: userId,
        type,
        timestamp: Timestamp.fromDate(now),
        date: today,
        location,
        status: isWithinGracePeriod() ? 'not_logged_in' : 'present'
      };

      await addDoc(collection(db, 'attendance'), attendanceData);
      
      if (type === 'IN') {
        setLastInTime(now);
      } else {
        setLastOutTime(now);
      }
      
      toast.success(`Marked ${type} successfully`);

      // If this is an OUT punch after 10 hours, trigger signout
      if (type === 'OUT' && lastInTime) {
        const hoursWorked = differenceInHours(now, lastInTime);
        if (hoursWorked >= MAX_WORK_HOURS) {
          toast.success('Maximum work duration reached. You will be signed out.');
          setTimeout(() => {
            signOut();
          }, 2000);
        }
      }
    } catch (error) {
      console.error('Error marking attendance:', error);
      toast.error('Failed to mark attendance');
    }
  };

  return {
    lastInTime,
    lastOutTime,
    autoLogoutStatus,
    handleAttendance,
    isLoading
  };
}