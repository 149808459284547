import React from 'react';
import { X, User, Mail, Calendar, IndianRupee, Clock, BookOpen, Phone, MapPin } from 'lucide-react';
import WorkHoursDisplay from './WorkHoursDisplay';

interface Teacher {
  id: string;
  name: string;
  email: string;
  startDate?: string;
  subjects?: string[];
  workHours?: {
    [key: string]: { start: string; end: string };
  };
  salaryType?: 'hourly' | 'monthly';
  monthlySalary?: string;
  hourlyRate?: string;
  aboutMe?: string;
  photo?: string;
  phone?: string;
  address?: string;
}

interface TeacherPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  teacher: Teacher;
}

export default function TeacherPreviewModal({
  isOpen,
  onClose,
  teacher
}: TeacherPreviewModalProps) {
  if (!isOpen) return null;

  const formatDate = (dateString?: string) => {
    if (!dateString) return 'Not set';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      return 'Invalid date';
    }
  };

  const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="relative">
          {/* Header with gradient background */}
          <div className="bg-gradient-to-r from-blue-500 to-blue-600 p-8 rounded-t-xl">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-white hover:text-blue-100 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
            
            <div className="flex items-center space-x-6">
              <div className="w-24 h-24 bg-white rounded-full ring-4 ring-white/50 shadow-lg flex items-center justify-center">
                {teacher.photo ? (
                  <img
                    src={teacher.photo}
                    alt={teacher.name}
                    className="w-full h-full rounded-full object-cover"
                  />
                ) : (
                  <User className="w-12 h-12 text-blue-500" />
                )}
              </div>
              <div>
                <h2 className="text-2xl font-bold text-white">{teacher.name}</h2>
                <p className="text-blue-100 flex items-center mt-2">
                  <Mail className="w-4 h-4 mr-2" />
                  {teacher.email}
                </p>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="p-6 space-y-8">
            {/* Basic Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900">Basic Information</h3>
                <div className="space-y-3">
                  <p className="flex items-center text-gray-600">
                    <Calendar className="w-5 h-5 mr-3 text-blue-500" />
                    <span>Joined on {formatDate(teacher.startDate)}</span>
                  </p>
                  {teacher.phone && (
                    <p className="flex items-center text-gray-600">
                      <Phone className="w-5 h-5 mr-3 text-blue-500" />
                      <span>{teacher.phone}</span>
                    </p>
                  )}
                  {teacher.address && (
                    <p className="flex items-center text-gray-600">
                      <MapPin className="w-5 h-5 mr-3 text-blue-500" />
                      <span>{teacher.address}</span>
                    </p>
                  )}
                  {(teacher.salaryType && (teacher.monthlySalary || teacher.hourlyRate)) && (
                    <p className="flex items-center text-gray-600">
                      <IndianRupee className="w-5 h-5 mr-3 text-blue-500" />
                      <span>
                        {teacher.salaryType === 'monthly'
                          ? `₹${teacher.monthlySalary}/month`
                          : `₹${teacher.hourlyRate}/hour`}
                      </span>
                    </p>
                  )}
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900">Salary Information</h3>
                <div className="space-y-3">
                  <p className="flex items-center text-gray-600">
                    <IndianRupee className="w-5 h-5 mr-3 text-blue-500" />
                    <span>
                      {teacher.salaryType === 'monthly' 
                        ? `₹${teacher.monthlySalary} per month`
                        : `₹${teacher.hourlyRate} per hour`
                      }
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* Subjects */}
            {teacher.subjects && teacher.subjects.length > 0 && (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900 flex items-center">
                  <BookOpen className="w-5 h-5 mr-2 text-blue-500" />
                  Subjects
                </h3>
                <div className="flex flex-wrap gap-2">
                  {teacher.subjects.map((subject) => (
                    <span
                      key={subject}
                      className="px-3 py-1.5 bg-blue-50 text-blue-700 rounded-full text-sm font-medium"
                    >
                      {subject}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {/* Work Hours */}
            {teacher.workHours && Object.keys(teacher.workHours).length > 0 && (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900">Work Hours</h3>
                <WorkHoursDisplay workHours={teacher.workHours} expanded={true} />
              </div>
            )}

            {/* About Me */}
            {teacher.aboutMe && (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900">About</h3>
                <p className="text-gray-600 whitespace-pre-wrap">{teacher.aboutMe}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
