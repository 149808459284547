import React, { useState, useRef, useEffect, ChangeEvent, useCallback, useMemo } from 'react';
import { Save, Plus, X, Download, Upload, Phone } from 'lucide-react';
import { collection, addDoc, writeBatch, doc, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { 
  Student, Board, PhoneNumber, PhoneRelation, 
  BASE_MONTHLY_FEE, IB_BASE_MONTHLY_FEE, GRADE_LEVELS, FEE_DISCOUNTS,
  SubjectFee, FeeCalculation, FeeInstallment
} from '../types';
import { generateExcelTemplate, parseExcelData } from '../utils/excelUtils';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Autocomplete, TextField, IconButton } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

// Add jsPDF types
declare module 'jspdf' {
  interface jsPDF {
    autoTable: (options: any) => void;
  }
}

const BOARDS: Board[] = ['IGCSE', 'CBSE', 'SSC', 'NIOS', 'IB', 'AS/A IBDP', 'Others'];
const BRANCHES = ['Goregaon West', 'Goregaon East', 'Online', 'Borivali', 'Kandivali', 'Others'] as const;
const GRADES = [
  'Playschool',
  'Nurserry',
  'Jr. KG',
  'Sr. KG',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15'
] as const;
const SUBJECTS = [
  // Languages
  'English - First Language',
  'English as a Second Language',
  'French',
  'Spanish',
  'German',
  'Mandarin Chinese',
  'Hindi',
  'Marathi',
  'Sanskrit',
  
  // Sciences
  'Biology',
  'Chemistry',
  'Physics',
  'Combined Science',
  'Co-ordinated Sciences',
  'Environmental Management',
  
  // Mathematics
  'Mathematics - Core and Extended',
  'Additional Mathematics',
  'International Mathematics',
  
  // Humanities and Social Sciences
  'Economics',
  'Geography',
  'History',
  'Global Perspectives',
  'Sociology',
  'Psychology',
  
  // Commerce and Business
  'Accounting',
  'Business Studies',
  'Commerce',
  
  // Technology
  'Computer Science',
  'Design and Technology',
  'ICT',
  
  // Arts and Physical Education
  'Art and Design',
  'Drama',
  'Music',
  'Physical Education (PE)',
  
  // Others
  'Phonics',
  'UOI (Unit of inquiry)'
];

const PHONE_RELATIONS: PhoneRelation[] = [
  'Father',
  'Mother',
  'Guardian',
  'Self',
  'Brother',
  'Sister',
  'Uncle',
  'Aunt',
  'Grandfather',
  'Grandmother',
  'Other'
];

type Branch = typeof BRANCHES[number];

const initialStudentData: Omit<Student, 'id'> = {
  studentId: '',
  name: '',
  grade: GRADES[0],
  board: BOARDS[0],
  branch: BRANCHES[0],
  school: '',
  status: 'Admission Due',
  startDate: new Date().toISOString().split('T')[0],
  endDate: new Date().toISOString().split('T')[0],
  subjects: [],
  phoneNumbers: [],
  photo: null,
  whatsappGroupName: '',
  whatsappLink: '',
  createdAt: new Date().toISOString(),
  oneToOneEnabled: false,
  oneToOnePercentage: 0,
  feeCalculation: {
    baseMonthlyFee: 0,
    subtotal: 0,
    discount: {
      subjectDiscount: {
        percentage: 0,
        amount: 0
      },
      scholarshipDiscount: {
        percentage: 0,
        amount: 0
      }
    },
    gst: {
      baseAmount: 0,
      amount: 0,
      gstEnabled: false
    },
    oneToOne: {
      enabled: false,
      percentage: 0
    },
    totalFees: 0,
    installments: [],
    dailyRate: 0,
    totalDays: 0,
    periodFee: 0
  },
  contacts: []
};

// Subject fee constants
const SUBJECT_FEES = {
  // Core Subjects
  'Mathematics': 2000,
  'Physics': 2000,
  'Chemistry': 2000,
  'Biology': 2000,
  'Science': 2000,
  'Economics': 2000,
  'Business Studies': 2000,
  'Accountancy': 2000,
  // Special Subject
  'Computer Science': 2500,
  // Languages & Others
  'English': 1500,
  'Hindi': 1500,
  'Social Studies': 1500,
  'Environmental Science': 1500,
  'Political Science': 1500,
  'Geography': 1500,
  'History': 1500,
  // Default for any other subject
  'default': 1500
};

export default function StudentForm() {
  const [students, setStudents] = useState<Omit<Student, 'id'>[]>([{...initialStudentData}]);
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>[]>([{}]);
  const [photoError, setPhotoError] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showGST, setShowGST] = useState(true);
  const [showScholarship, setShowScholarship] = useState(false);
  const [scholarshipPercentage, setScholarshipPercentage] = useState(10);
  const [oneToOneMode, setOneToOneMode] = useState(false);
  const [fee11Percentage, setFee11Percentage] = useState(10);
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const [showUSD, setShowUSD] = useState(false);
  const [usdRate, setUsdRate] = useState<number | null>(null);
  const MARKUP_PERCENTAGE = 3;

  // Memoize the calculateFees function
  const calculateFees = useCallback((student: Student) => {
    if (!student.subjects?.length || !student.startDate || !student.endDate) {
      return {
        baseMonthlyFee: 0,
        subtotal: 0,
        discount: {
          subjectDiscount: { amount: 0, percentage: 0 },
          scholarshipDiscount: { amount: 0, percentage: 0 }
        },
        gst: {
          baseAmount: 0,
          amount: 0,
          gstEnabled: showGST
        },
        oneToOne: {
          enabled: false,
          percentage: 0
        },
        totalFees: 0,
        subjectFees: [],
        installments: []
      };
    }

    // Calculate total days
    const startDate = new Date(student.startDate);
    const endDate = new Date(student.endDate);
    const totalDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;

    // Get base rate based on board
    const getBaseRate = () => {
      const isOnline = student.branch === 'Online';
      if (isOnline) return 1500;

      switch (student.board) {
        case 'IGCSE':
          return 1200;
        case 'IB':
          return 2500;
        case 'NIOS':
          return 3000;
        case 'CBSE':
        case 'SSC':
          return 800;
        default:
          return 800; // Default to CBSE/SSC rate
      }
    };

    // Get grade multiplier (10% increase after grade 1)
    const getGradeMultiplier = () => {
      const earlyGrades = ['Playschool', 'Nurserry', 'Jr. KG', 'Sr. KG', '1'];
      if (earlyGrades.includes(student.grade)) {
        return 1;
      }
      
      // For numerical grades
      const gradeNum = parseInt(student.grade);
      if (!isNaN(gradeNum) && gradeNum > 1) {
        // Calculate 10% increase for each grade after 1
        return Math.pow(1.1, gradeNum - 1);
      }
      
      return 1; // Default multiplier
    };

    // Calculate base rate with grade multiplier
    const baseMonthlyRate = getBaseRate() * getGradeMultiplier();
    
    // Calculate subject-wise fees - each subject has its own independent fee
    const subjectFees = student.subjects.map(subject => {
      // Apply 1:1 mode increase if enabled
      const monthlyRate = student.oneToOneEnabled ? 
        baseMonthlyRate * (1 + (student.oneToOnePercentage / 100)) : 
        baseMonthlyRate;
      
      const dailyRate = monthlyRate / 30; // Monthly rate divided by 30 days
      const totalFee = dailyRate * totalDays;

      return {
        subject: typeof subject === 'string' ? subject : subject.name,
        baseMonthlyFee: baseMonthlyRate,
        monthlyFee: monthlyRate,
        dailyRate,
        totalFee
      };
    });

    // Calculate total base monthly fee (sum of all subject monthly fees)
    const totalBaseMonthlyFee = baseMonthlyRate * student.subjects.length;

    // Calculate subtotal (sum of all subject total fees)
    const subtotal = subjectFees.reduce((sum, subject) => sum + subject.totalFee, 0);

    // Calculate multi-subject discount (10% for 3 or more subjects)
    const subjectDiscount = {
      percentage: student.subjects.length >= 3 ? 10 : 0,
      amount: Math.round(subtotal * (student.subjects.length >= 3 ? 0.1 : 0))
    };

    // Calculate scholarship discount
    const scholarshipAmount = showScholarship ? Math.round(subtotal * (scholarshipPercentage / 100)) : 0;
    const scholarshipDiscount = {
      amount: scholarshipAmount,
      percentage: showScholarship ? scholarshipPercentage : 0
    };

    // Calculate base amount after all discounts
    const baseAmount = subtotal - subjectDiscount.amount - scholarshipDiscount.amount;

    // Calculate GST
    const gstAmount = showGST ? Math.round(baseAmount * 0.18) : 0;

    // Calculate total fees
    const totalFees = baseAmount + gstAmount;

    return {
      baseMonthlyFee: Math.round(totalBaseMonthlyFee),
      subtotal: Math.round(subtotal),
      discount: {
        subjectDiscount,
        scholarshipDiscount,
      },
      gst: {
        baseAmount: Math.round(baseAmount),
        amount: gstAmount,
        gstEnabled: showGST
      },
      oneToOne: {
        enabled: student.oneToOneEnabled,
        percentage: student.oneToOnePercentage
      },
      totalFees: Math.round(totalFees),
      subjectFees: subjectFees.map(fee => ({
        ...fee,
        monthlyFee: Math.round(fee.monthlyFee),
        dailyRate: Math.round(fee.dailyRate),
        totalFee: Math.round(fee.totalFee)
      })),
      installments: calculateInstallments(totalFees, student.startDate)
    };
  }, [showGST, scholarshipPercentage]); // Add scholarshipPercentage to dependencies

  // Initialize component state
  useEffect(() => {
    if (!isInitialized && students.length > 0) {
      const today = new Date().toISOString().split('T')[0];
      
      // Initialize dates
      setStudents(prev => prev.map(student => ({
        ...student,
        startDate: student.startDate || today,
        endDate: student.endDate || today
      })));

      // Initialize GST state from first student
      if (students[0].feeCalculation?.gst?.gstEnabled !== undefined) {
        setShowGST(students[0].feeCalculation.gst.gstEnabled);
      }

      setIsInitialized(true);
    }
  }, [students, isInitialized]);

  // Handle fee calculation updates
  const feeState = useMemo(() => ({
    showGST,
    oneToOneMode,
    fee11Percentage,
    scholarshipPercentage,
    showScholarship
  }), [showGST, oneToOneMode, fee11Percentage, scholarshipPercentage, showScholarship]);

  useEffect(() => {
    if (isInitialized) {
      const newStudents = students.map(student => ({
        ...student,
        feeCalculation: calculateFees({
          ...student,
          oneToOneEnabled: oneToOneMode,
          oneToOnePercentage: fee11Percentage
        })
      }));

      // Only update if there are actual changes
      if (JSON.stringify(newStudents) !== JSON.stringify(students)) {
        setStudents(newStudents);
      }
    }
  }, [feeState, isInitialized, calculateFees]);

  // GST toggle handler
  const handleGSTToggle = useCallback(() => {
    setShowGST(prev => !prev);
  }, []);

  const handleGSTChange = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleGSTToggle();
  }, [handleGSTToggle]);

  // Initialize dates
  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setStudents(prev => prev.map(student => ({
      ...student,
      startDate: student.startDate || today,
      endDate: student.endDate || today
    })));
  }, []);

  // Update fee calculations when relevant state changes
  const updateFeeCalculations = useCallback(() => {
    setStudents(prevStudents => prevStudents.map(student => ({
      ...student,
      feeCalculation: calculateFees({
        ...student,
        oneToOneEnabled: oneToOneMode,
        oneToOnePercentage: fee11Percentage
      })
    })));
  }, [calculateFees, oneToOneMode, fee11Percentage]);

  useEffect(() => {
    updateFeeCalculations();
  }, [updateFeeCalculations, scholarshipPercentage, showScholarship]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = students.map(student => {
      const studentErrors: Record<string, string> = {};

      // Validate Student Name
      if (!student.name.trim()) {
        studentErrors.name = 'Student name is required';
        isValid = false;
      }

      // Validate Phone Numbers (at least one required)
      if (!student.phoneNumbers.length) {
        studentErrors.phoneNumbers = 'At least one phone number is required';
        isValid = false;
      } else {
        // Validate each phone number
        const hasValidNumber = student.phoneNumbers.some(phone => 
          phone.number.trim() && phone.name.trim() && phone.relation
        );
        if (!hasValidNumber) {
          studentErrors.phoneNumbers = 'Complete phone number details are required';
          isValid = false;
        }
      }

      // Validate Grade
      if (!student.grade) {
        studentErrors.grade = 'Grade is required';
        isValid = false;
      }

      // Validate Board
      if (!student.board) {
        studentErrors.board = 'Board is required';
        isValid = false;
      }

      // Validate Start Date
      if (!student.startDate) {
        studentErrors.startDate = 'Start date is required';
        isValid = false;
      }

      // Validate End Date
      if (!student.endDate) {
        studentErrors.endDate = 'End date is required';
        isValid = false;
      }

      return studentErrors;
    });

    setErrors(newErrors);
    return isValid;
  };

  const generateStudentId = async (board: string, grade: string): Promise<string> => {
    try {
      // Get current count of students with same board and grade
      const studentsRef = collection(db, 'students');
      const q = query(
        studentsRef,
        where('board', '==', board),
        where('grade', '==', grade)
      );
      const querySnapshot = await getDocs(q);
      const count = querySnapshot.size + 1;

      // Generate ID in format: BOARD-GRADE-COUNT (e.g., CBSE-10-001)
      const paddedCount = count.toString().padStart(3, '0');
      return `${board}-${grade}-${paddedCount}`;
    } catch (error) {
      console.error('Error generating student ID:', error);
      throw new Error('Failed to generate student ID');
    }
  };

  const calculateMonths = (startDate: string, endDate: string): number => {
    if (!startDate || !endDate) return 1;
    
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    // If dates are the same or end is before start, return 1 month minimum
    if (start >= end) return 1;
    
    // Calculate months difference
    const monthsDiff = (end.getFullYear() - start.getFullYear()) * 12 + 
                      (end.getMonth() - start.getMonth());
    
    // Add 1 to include both start and end months
    return monthsDiff + 1;
  };

  const calculateSubjectFee = (grade: string, board: Board): number => {
    // Base fee calculation based on board
    let baseFee = BASE_MONTHLY_FEE; // Default 2000
    
    if (board === 'IB' || board === 'AS/A IBDP') {
      baseFee = IB_BASE_MONTHLY_FEE; // 3500 for IB/AS/A IBDP
    }

    return baseFee;
  };

  const calculateInstallments = (totalAmount: number, startDate: string): FeeInstallment[] => {
    const start = new Date(startDate);
    
    // If total fees is less than 40k, single installment
    if (totalAmount < 40000) {
      return [{
        amount: totalAmount,
        dueDate: startDate,
        paid: false
      }];
    }

    // For fees 100k or more, try three installments
    if (totalAmount >= 100000) {
      const baseInstallment = Math.round(totalAmount / 3);
      return Array.from({ length: 3 }, (_, index) => {
        const dueDate = new Date(start);
        dueDate.setDate(start.getDate() + (index * 89)); // 89 days apart
        
        // Adjust last installment to account for rounding
        const amount = index === 2 
          ? totalAmount - (baseInstallment * 2)
          : baseInstallment;

        return {
          amount,
          dueDate: dueDate.toISOString().split('T')[0],
          paid: false
        };
      });
    }

    // For fees between 40k and 100k, two installments
    const halfAmount = Math.round(totalAmount / 2);
    return [
      {
        amount: halfAmount,
        dueDate: startDate,
        paid: false
      },
      {
        amount: totalAmount - halfAmount, // Adjust second installment for rounding
        dueDate: new Date(start.setDate(start.getDate() + 89)).toISOString().split('T')[0],
        paid: false
      }
    ];
  };

  const calculateGST = (totalAmount: number): { baseAmount: number; gstAmount: number } => {
    // For amount inclusive of 18% GST
    // If x is base amount, then x + 18% of x = total
    // x + 0.18x = total
    // 1.18x = total
    // x = total/1.18
    const baseAmount = totalAmount / 1.18;
    const gstAmount = totalAmount - baseAmount;
    return { baseAmount, gstAmount };
  };

  const calculateSubjectDiscount = (numSubjects: number, subtotal: number) => {
    let discountPercentage = 0;
    if (numSubjects >= 5) {
      discountPercentage = 20;
    } else if (numSubjects >= 3) {
      discountPercentage = 10;
    }
    return {
      percentage: discountPercentage,
      amount: (subtotal * discountPercentage) / 100
    };
  };

  const calculateScholarshipDiscount = (subtotal: number) => {
    let discountPercentage = 0;
    if (showScholarship) {
      discountPercentage = scholarshipPercentage;
    }
    return {
      percentage: discountPercentage,
      amount: (subtotal * discountPercentage) / 100
    };
  };

  const calculateDaysBetweenDates = (startDate: string, endDate: string): number => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // First validate the form
    if (!validateForm()) {
      // Only show error if there are actual validation errors
      const hasErrors = errors.some(error => Object.keys(error).length > 0);
      if (hasErrors) {
        toast.error('Please fill in all required fields');
        return;
      }
    }

    try {
      setIsUploading(true);
      const batch = writeBatch(db);

      // Process each student
      for (const student of students) {
        // Generate student ID
        const studentId = await generateStudentId(student.board, student.grade);
        const studentData = {
          ...student,
          studentId,
          subjects: student.subjects || [], // Ensure subjects is at least an empty array
          createdAt: new Date().toISOString()
        };

        // Create student document
        const studentRef = doc(collection(db, 'students'));
        batch.set(studentRef, studentData);
      }

      // Commit the batch
      await batch.commit();

      toast.success('Students added successfully!');
      // Reset form
      setStudents([{ ...initialStudentData }]);
      setErrors([{}]);
      setPhotoError('');
    } catch (error) {
      console.error('Error adding students:', error);
      toast.error('Failed to add students. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const addStudent = () => {
    setStudents([...students, { ...initialStudentData }]);
  };

  const removeStudent = (index: number) => {
    setStudents(students.filter((_, i) => i !== index));
  };

  const handleFieldChange = (index: number, field: keyof Student, value: any) => {
    setStudents(prev => {
      const newStudents = [...prev];
      newStudents[index] = {
        ...newStudents[index],
        [field]: value
      };
      return newStudents;
    });
  };

  const handlePhoneChange = (studentIndex: number, phoneIndex: number, field: keyof PhoneNumber, value: string) => {
    setStudents(prevStudents => {
      const newStudents = [...prevStudents];
      const student = {...newStudents[studentIndex]};
      const phones = [...(student.phoneNumbers || [])];
      
      // Ensure phone entry exists
      while (phones.length <= phoneIndex) {
        phones.push({
          name: '',
          number: '',
          relation: 'Father'
        });
      }
      
      phones[phoneIndex] = {
        ...phones[phoneIndex],
        [field]: field === 'number' ? formatPhoneNumber(value) : value
      };
      
      student.phoneNumbers = phones;
      newStudents[studentIndex] = student;
      return newStudents;
    });
  };

  const addPhoneNumber = (index: number) => {
    setStudents(prevStudents => {
      const newStudents = [...prevStudents];
      const student = {...newStudents[index]};
      student.phoneNumbers = [
        ...(student.phoneNumbers || []),
        { name: '', number: '', relation: 'Father' }
      ];
      newStudents[index] = student;
      return newStudents;
    });
  };

  const removePhoneNumber = (studentIndex: number, phoneIndex: number) => {
    if (students[studentIndex].phoneNumbers.length <= 1) return;
    
    setStudents(prevStudents => {
      const newStudents = [...prevStudents];
      const student = {...newStudents[studentIndex]};
      student.phoneNumbers = student.phoneNumbers.filter((_, i) => i !== phoneIndex);
      newStudents[studentIndex] = student;
      return newStudents;
    });
  };

  const handleSubjectChange = (index: number, value: string) => {
    const student = students[index];
    const subjects = [...student.subjects];
    subjects[index] = value;

    // Update the student with new subjects and recalculate fees
    const updatedStudent = {
      ...student,
      subjects: subjects.filter(Boolean) // Remove empty subjects
    };

    // Recalculate fees with new subjects
    const feeCalculation = calculateFees(updatedStudent);
    updatedStudent.feeCalculation = feeCalculation;

    const newStudents = [...students];
    newStudents[index] = updatedStudent;
    setStudents(newStudents);
  };

  const handleAddSubject = (studentIndex: number) => {
    const student = students[studentIndex];
    const updatedStudent = {
      ...student,
      subjects: [...student.subjects, '']
    };

    // Recalculate fees with new subjects
    const feeCalculation = calculateFees(updatedStudent);
    updatedStudent.feeCalculation = feeCalculation;

    const newStudents = [...students];
    newStudents[studentIndex] = updatedStudent;
    setStudents(newStudents);
  };

  const handleRemoveSubject = (studentIndex: number, subjectIndex: number) => {
    const student = students[studentIndex];
    const subjects = [...student.subjects];
    subjects.splice(subjectIndex, 1);

    // Update the student with removed subject and recalculate fees
    const updatedStudent = {
      ...student,
      subjects
    };

    // Recalculate fees with updated subjects
    const feeCalculation = calculateFees(updatedStudent);
    updatedStudent.feeCalculation = feeCalculation;

    const newStudents = [...students];
    newStudents[studentIndex] = updatedStudent;
    setStudents(newStudents);
  };

  const updateSubjectFees = (index: number, selectedSubjects: string[]) => {
    const student = students[index];
    const updatedStudent = {
      ...student,
      subjects: selectedSubjects,
    };
    const feeCalculation = calculateFees(updatedStudent);
    updatedStudent.feeCalculation = feeCalculation;
    
    const newStudents = [...students];
    newStudents[index] = updatedStudent;
    setStudents(newStudents);
  };

  const handleDateChange = (index: number, field: 'startDate' | 'endDate', value: string) => {
    const student = students[index];
    
    // Update the student with the new date
    const updatedStudent = { ...student, [field]: value };
    
    // If end date is before start date, adjust it
    if (field === 'startDate' && updatedStudent.endDate && value > updatedStudent.endDate) {
      updatedStudent.endDate = value;
    }
    if (field === 'endDate' && updatedStudent.startDate && value < updatedStudent.startDate) {
      updatedStudent.startDate = value;
    }
    
    // Recalculate fees with new dates
    const feeCalculation = calculateFees(updatedStudent);
    updatedStudent.feeCalculation = feeCalculation;
    
    const newStudents = [...students];
    newStudents[index] = updatedStudent;
    setStudents(newStudents);
  };

  const handlePhotoChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const options = {
        maxSizeMB: 0.5,
        minSizeMB: 0.05,
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(file, options);
        const fileSizeInKB = compressedFile.size / 1024;
        if (fileSizeInKB < 50 || fileSizeInKB > 500) {
          setPhotoError('File size must be between 50KB and 500KB after compression.');
          handleFieldChange(0, 'photo', null);
        } else {
          setPhotoError('');
          handleFieldChange(0, 'photo', compressedFile);
        }
      } catch (error) {
        setPhotoError('Error compressing the image.');
        console.error(error);
      }
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);
      const parsedStudents = await parseExcelData(file);
      
      // Use batched writes for better performance
      const batch = writeBatch(db);
      const studentsRef = collection(db, 'students');

      parsedStudents.forEach((student) => {
        const docRef = doc(studentsRef);
        batch.set(docRef, student);
      });

      await batch.commit();
      toast.success(`Successfully added ${parsedStudents.length} students`);
      
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error: any) {
      console.error('Error uploading students:', error);
      toast.error(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} / ${month} / ${year}`;
  };

  const formatPhoneNumber = (value: string) => {
    // Remove all non-numeric characters
    const numbers = value.replace(/[^\d+]/g, '');
    
    // Ensure it starts with + if it doesn't
    if (!numbers.startsWith('+')) {
      return '+' + numbers;
    }
    return numbers;
  };

  const generateInvoice = (student: Student) => {
    try {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });
      
      // Add logo
      const logoPath = 'src/assets/images/Logo.png';
      const logoWidth = 30;
      const logoHeight = 30;
      const pageWidth = doc.internal.pageSize.width;
      const logoX = (pageWidth - logoWidth) / 2;
      
      doc.addImage(logoPath, 'PNG', logoX, 10, logoWidth, logoHeight);

      let yPos = 50;

      // Header section
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      
      const currentDate = new Date().toLocaleDateString('en-IN');
      doc.text(`Date: ${currentDate}`, pageWidth - 20, yPos, { align: 'right' });
      
      // Add currency indicator
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text(`All amounts are in ${showUSD ? 'USD' : 'INR'}`, 20, yPos);
      yPos += 10;

      doc.text('Student Details:', 20, yPos);
      yPos += 5;
      
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(9);
      doc.text(`Name: ${student.name}`, 25, yPos += 5);
      doc.text(`Grade: ${student.grade}`, 25, yPos += 5);
      doc.text(`Board: ${student.board}`, 25, yPos += 5);
      yPos += 5;

      // Period details
      doc.setFont('helvetica', 'bold');
      doc.text('Period:', 20, yPos += 5);
      doc.setFont('helvetica', 'normal');
      doc.text(`${formatDate(student.startDate)} to ${formatDate(student.endDate)}`, 25, yPos += 5);
      doc.text(`(${calculateDaysBetweenDates(student.startDate, student.endDate)} days)`, 25, yPos += 5);
      yPos += 5;

      // Subject-wise fee breakdown
      doc.setFont('helvetica', 'bold');
      doc.text('Subject-wise Fees:', pageWidth / 2, yPos += 5, { align: 'center' });
      yPos += 5;

      // Format amount without currency symbol
      const formatAmount = (amount: number) => {
        if (showUSD) {
          return convertToUSD(amount);
        }
        return amount.toLocaleString();
      };

      // Create subject fee table
      const subjectRows = student.subjects.map((subject, index) => {
        const feeInfo = student.feeCalculation?.subjectFees[index];
        return [
          subject,
          calculateDaysBetweenDates(student.startDate, student.endDate),
          formatAmount(feeInfo?.totalFee || 0)
        ];
      });

      // Calculate table width and margins for centering
      const tableWidth = 130; // Total width of all columns
      const leftMargin = (pageWidth - tableWidth) / 2;

      // @ts-ignore
      doc.autoTable({
        startY: yPos,
        head: [['Subject', 'Days', 'Total']],
        body: subjectRows,
        theme: 'striped',
        styles: {
          fontSize: 8,
          cellPadding: 2,
          halign: 'center'
        },
        headStyles: {
          fontSize: 8,
          fillColor: [240, 240, 240],
          textColor: [0, 0, 0],
          fontStyle: 'bold',
          halign: 'center'
        },
        columnStyles: {
          0: { cellWidth: 60, halign: 'center' },
          1: { cellWidth: 30, halign: 'center' },
          2: { cellWidth: 40, halign: 'center' }
        },
        margin: { left: leftMargin }
      });

      // @ts-ignore
      yPos = doc.lastAutoTable.finalY + 10;

      // Fee breakdown
      const feeBreakdown = [
        ['Subtotal', formatAmount(student.feeCalculation?.subtotal || 0)]
      ];

      // Add scholarship if enabled
      if (showScholarship) {
        feeBreakdown.push([
          `Scholarship (${scholarshipPercentage}%)`,
          `-${formatAmount(student.feeCalculation?.discount?.scholarshipDiscount?.amount || 0)}`
        ]);
      }

      // Add base amount after discounts
      feeBreakdown.push(['Base Amount', formatAmount(student.feeCalculation?.gst?.baseAmount || 0)]);

      // Add GST if enabled
      if (showGST) {
        feeBreakdown.push(['GST (18%)', formatAmount(student.feeCalculation?.gst?.amount || 0)]);
      }

      // Add total
      feeBreakdown.push(['Total Amount', formatAmount(student.feeCalculation?.totalFees || 0)]);

      // Calculate summary table width and margins
      const summaryTableWidth = 130;
      const summaryLeftMargin = (pageWidth - summaryTableWidth) / 2;

      // @ts-ignore
      doc.autoTable({
        startY: yPos,
        head: [],
        body: feeBreakdown,
        theme: 'plain',
        styles: {
          fontSize: 9,
          cellPadding: 3,
          lineColor: [200, 200, 200],
          lineWidth: 0.1,
          halign: 'center'
        },
        columnStyles: {
          0: { cellWidth: 80, halign: 'left', fontStyle: 'bold' },
          1: { cellWidth: 50, halign: 'center' }
        },
        margin: { left: summaryLeftMargin }
      });

      // @ts-ignore
      yPos = doc.lastAutoTable.finalY + 10;

      // Payment Schedule
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('Payment Schedule:', pageWidth / 2, yPos, { align: 'center' });
      yPos += 5;

      const installmentData = student.feeCalculation?.installments?.map((inst, idx) => [
        `Installment ${idx + 1}`,
        formatDate(inst.dueDate),
        formatAmount(inst.amount)
      ]) || [];

      // Calculate installment table width and margins
      const installmentTableWidth = 150;
      const installmentLeftMargin = (pageWidth - installmentTableWidth) / 2;

      // @ts-ignore
      doc.autoTable({
        startY: yPos,
        head: [['', 'Due Date', 'Amount']],
        body: installmentData,
        theme: 'striped',
        styles: {
          fontSize: 9,
          cellPadding: 3,
          halign: 'center'
        },
        headStyles: {
          fontSize: 9,
          fillColor: [240, 240, 240],
          textColor: [0, 0, 0],
          fontStyle: 'bold',
          halign: 'center'
        },
        columnStyles: {
          0: { cellWidth: 60, halign: 'center' },
          1: { cellWidth: 50, halign: 'center' },
          2: { cellWidth: 40, halign: 'center' }
        },
        margin: { left: installmentLeftMargin }
      });

      // Footer
      const footerY = doc.internal.pageSize.height - 40;
      doc.setDrawColor(200, 200, 200);
      doc.line(20, footerY - 5, pageWidth - 20, footerY - 5);

      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
        
      let footerLeftY = footerY;
      doc.text('Contact Details:', 20, footerLeftY);
      doc.text('Email: contact@inkstall.com', 20, footerLeftY += 5);
      doc.text('GPay: 9820351334', 20, footerLeftY += 5);

      let footerRightY = footerY;
      doc.text('Bank Account Details:', pageWidth - 80, footerRightY);
      doc.text('Account Name: Inkstall Solutions LLP', pageWidth - 80, footerRightY += 5);
      doc.text('Account No: 50200078489380', pageWidth - 80, footerRightY += 5);
      doc.text('IFSC Code: HDFC0000212', pageWidth - 80, footerRightY += 5);

      doc.save(`${student.name}_invoice.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF. Please try again.');
    }
  };

  const handleAddStudent = () => {
    setStudents([...students, { ...initialStudentData }]);
    setErrors([...errors, {}]);
  };

  const handleContactChange = (studentIndex: number, contactIndex: number, field: keyof { name: string; phone: string; relation: string }, value: string) => {
    setStudents(prevStudents => {
      const newStudents = [...prevStudents];
      const student = {...newStudents[studentIndex]};
      const contacts = [...(student.contacts || [])];
      
      // Ensure contact entry exists
      while (contacts.length <= contactIndex) {
        contacts.push({
          name: '',
          phone: '',
          relation: 'Parent'
        });
      }
      
      contacts[contactIndex] = {
        ...contacts[contactIndex],
        [field]: value
      };
      
      student.contacts = contacts;
      newStudents[studentIndex] = student;
      return newStudents;
    });
  };

  const addContact = (index: number) => {
    setStudents(prevStudents => {
      const newStudents = [...prevStudents];
      const student = {...newStudents[index]};
      student.contacts = [
        ...(student.contacts || []),
        { name: '', phone: '', relation: 'Parent' }
      ];
      newStudents[index] = student;
      return newStudents;
    });
  };

  const removeContact = (studentIndex: number, contactIndex: number) => {
    if (students[studentIndex].contacts.length <= 1) return;
    
    setStudents(prevStudents => {
      const newStudents = [...prevStudents];
      const student = {...newStudents[studentIndex]};
      student.contacts = student.contacts.filter((_, i) => i !== contactIndex);
      newStudents[studentIndex] = student;
      return newStudents;
    });
  };

  // Handle scholarship toggle
  const handleScholarshipToggle = useCallback((enabled: boolean) => {
    setShowScholarship(enabled);
    // Force immediate recalculation
    const newStudents = students.map(student => ({
      ...student,
      feeCalculation: calculateFees({
        ...student,
        oneToOneEnabled: oneToOneMode,
        oneToOnePercentage: fee11Percentage,
        showScholarship: enabled
      })
    }));
    setStudents(newStudents);
  }, [students, oneToOneMode, fee11Percentage, calculateFees]);

  const handleUpdateStudent = (updatedStudent: Student) => {
    const updatedStudents = students.map(s => 
      s.studentId === updatedStudent.studentId ? {
        ...updatedStudent,
        feeCalculation: calculateFees({
          ...updatedStudent,
          oneToOneEnabled: oneToOneMode,
          oneToOnePercentage: fee11Percentage,
          showScholarship: updatedStudent.showScholarship
        })
      } : s
    );
    setStudents(updatedStudents);
  };

  const fetchUSDRate = async () => {
    try {
      const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
      const data = await response.json();
      const rate = data.rates.INR;
      // Add 3% markup
      const rateWithMarkup = rate * (1 + MARKUP_PERCENTAGE / 100);
      setUsdRate(rateWithMarkup);
    } catch (error) {
      console.error('Error fetching USD rate:', error);
      toast.error('Failed to fetch USD rate. Please try again.');
    }
  };

  const convertToUSD = (amountInINR: number) => {
    if (!usdRate) return amountInINR;
    return (amountInINR / usdRate).toFixed(2);
  };

  useEffect(() => {
    if (showUSD && !usdRate) {
      fetchUSDRate();
    }
  }, [showUSD]);

  const formatFeeAmount = (amount: number) => {
    if (showUSD) {
      return `$${convertToUSD(amount)}`;
    }
    return `₹${amount.toLocaleString()}`;
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 mb-6">
          <div className="flex items-center">
            <Plus className="w-5 h-5 mr-2 text-secondary" />
            <h2 className="text-xl font-bold text-secondary">Add New Students</h2>
          </div>
          <div className="flex flex-wrap gap-3">
            <button
              type="button"
              onClick={() => generateExcelTemplate()}
              className="btn-primary flex items-center bg-accent"
            >
              <Download className="w-4 h-4 mr-2" />
              Download Template
            </button>
            <label className="btn-primary flex items-center cursor-pointer">
              <Upload className="w-4 h-4 mr-2" />
              {isUploading ? 'Uploading...' : 'Upload Excel'}
              <input
                ref={fileInputRef}
                type="file"
                accept=".xlsx,.xls"
                className="hidden"
                onChange={handleFileUpload}
                disabled={isUploading}
              />
            </label>
            <button
              type="button"
              onClick={addStudent}
              className="btn-primary flex items-center"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Manually
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {students.map((student, index) => (
            <div key={index} className="relative border border-gray-200 rounded-lg p-4">
              {students.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeStudent(index)}
                  className="absolute top-2 right-2 p-1 text-gray-400 hover:text-red-500"
                >
                  <X className="w-5 h-5" />
                </button>
              )}
              
              <div className="space-y-4">
                {/* Student Basic Details */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {/* Name */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Student Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      value={student.name}
                      onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                      placeholder="Enter student name"
                      className="w-full h-10 pl-3 pr-3 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      required
                    />
                  </div>

                  {/* Grade */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Grade <span className="text-red-500">*</span>
                    </label>
                    <select
                      value={student.grade}
                      onChange={(e) => handleFieldChange(index, 'grade', e.target.value)}
                      className="w-full h-10 pl-3 pr-10 text-sm border border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 rounded-md"
                      required
                    >
                      <option value="">Select Grade</option>
                      {GRADES.map((grade) => (
                        <option key={grade} value={grade}>
                          {grade}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Branch */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Branch <span className="text-red-500">*</span>
                    </label>
                    <select
                      value={student.branch}
                      onChange={(e) => handleFieldChange(index, 'branch', e.target.value)}
                      className="w-full h-10 pl-3 pr-10 text-sm border border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 rounded-md"
                      required
                    >
                      <option value="">Select Branch</option>
                      {BRANCHES.map((branch) => (
                        <option key={branch} value={branch}>
                          {branch}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* School, Board and Status */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {/* School */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      School
                    </label>
                    <input
                      type="text"
                      value={student.school}
                      onChange={(e) => handleFieldChange(index, 'school', e.target.value)}
                      placeholder="Enter school name"
                      className="w-full h-10 pl-3 pr-3 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                    />
                  </div>

                  {/* Board */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Board <span className="text-red-500">*</span>
                    </label>
                    <select
                      value={student.board}
                      onChange={(e) => handleFieldChange(index, 'board', e.target.value)}
                      className="w-full h-10 pl-3 pr-10 text-sm border border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 rounded-md"
                      required
                    >
                      <option value="">Select Board</option>
                      {BOARDS.map((board) => (
                        <option key={board} value={board}>
                          {board}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Status */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Status
                    </label>
                    <select
                      value={student.status}
                      onChange={(e) => handleFieldChange(index, 'status', e.target.value)}
                      className="w-full h-10 pl-3 pr-10 text-sm border border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 rounded-md"
                    >
                      <option value="Admission Due">Admission Due</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                </div>

                {/* Date Range */}
                <div className="flex flex-wrap items-center gap-4">
                  {/* Start Date */}
                  <div className="flex items-center gap-2">
                    <label className="block text-sm font-medium text-gray-700">Start Date</label>
                    <input
                      type="date"
                      value={student.startDate}
                      onChange={(e) => handleDateChange(index, 'startDate', e.target.value)}
                      className="w-[160px] h-10 pl-3 pr-3 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                    />
                  </div>

                  {/* End Date */}
                  <div className="flex items-center gap-2">
                    <label className="block text-sm font-medium text-gray-700">End Date</label>
                    <input
                      type="date"
                      value={student.endDate}
                      onChange={(e) => handleDateChange(index, 'endDate', e.target.value)}
                      className="w-[160px] h-10 pl-3 pr-3 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                    />
                  </div>
                </div>

                {/* Fee Controls and Subjects */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Fee Controls Section */}
                  <div className="space-y-4 p-4 bg-gray-50 rounded-lg">
                    <h4 className="text-sm font-semibold text-gray-700 mb-3">Fee Settings</h4>
                    <div className="flex flex-wrap gap-6">
                      {/* GST Toggle */}
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-gray-600 min-w-[30px]">GST</span>
                        <button
                          type="button"
                          onClick={handleGSTChange}
                          className={`relative inline-flex h-6 w-11 items-center rounded-full transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-1 ${
                            showGST 
                              ? 'bg-green-500 focus:ring-green-400' 
                              : 'bg-gray-200 focus:ring-gray-400'
                          }`}
                        >
                          <span
                            className={`inline-block h-4 w-4 transform rounded-full bg-white shadow-lg transition-transform duration-200 ease-in-out ${
                              showGST ? 'translate-x-6' : 'translate-x-1'
                            }`}
                          />
                        </button>
                      </div>

                      {/* Scholarship Toggle */}
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-gray-600 min-w-[80px]">Scholarship</span>
                        <button
                          type="button"
                          onClick={() => handleScholarshipToggle(!showScholarship)}
                          className={`relative inline-flex h-6 w-11 items-center rounded-full transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-1 ${
                            showScholarship 
                              ? 'bg-blue-500 focus:ring-blue-400' 
                              : 'bg-gray-200 focus:ring-gray-400'
                          }`}
                        >
                          <span
                            className={`inline-block h-4 w-4 transform rounded-full bg-white shadow-lg transition-transform duration-200 ease-in-out ${
                              showScholarship ? 'translate-x-6' : 'translate-x-1'
                            }`}
                          />
                        </button>
                        {showScholarship && (
                          <select
                            value={scholarshipPercentage}
                            onChange={(e) => setScholarshipPercentage(Number(e.target.value))}
                            className="w-[80px] h-9 pl-2 pr-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          >
                            <option value="10">10%</option>
                            <option value="20">20%</option>
                            <option value="30">30%</option>
                            <option value="40">40%</option>
                            <option value="50">50%</option>
                          </select>
                        )}
                      </div>

                      {/* 1:1 Mode Toggle */}
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-gray-600 min-w-[25px]">1:1</span>
                        <button
                          type="button"
                          onClick={() => setOneToOneMode(!oneToOneMode)}
                          className={`relative inline-flex h-6 w-11 items-center rounded-full transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-1 ${
                            oneToOneMode 
                              ? 'bg-purple-500 focus:ring-purple-400' 
                              : 'bg-gray-200 focus:ring-gray-400'
                          }`}
                        >
                          <span
                            className={`inline-block h-4 w-4 transform rounded-full bg-white shadow-lg transition-transform duration-200 ease-in-out ${
                              oneToOneMode ? 'translate-x-6' : 'translate-x-1'
                            }`}
                          />
                        </button>
                        {oneToOneMode && (
                          <select
                            value={fee11Percentage}
                            onChange={(e) => setFee11Percentage(Number(e.target.value))}
                            className="w-[80px] h-9 pl-2 pr-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                          >
                            <option value="10">10%</option>
                            <option value="20">20%</option>
                            <option value="30">30%</option>
                            <option value="40">40%</option>
                            <option value="50">50%</option>
                            <option value="60">60%</option>
                            <option value="70">70%</option>
                            <option value="80">80%</option>
                            <option value="90">90%</option>
                            <option value="100">100%</option>
                            <option value="110">110%</option>
                            <option value="120">120%</option>
                            <option value="130">130%</option>
                            <option value="140">140%</option>
                            <option value="150">150%</option>
                            <option value="160">160%</option>
                            <option value="170">170%</option>
                            <option value="180">180%</option>
                            <option value="190">190%</option>
                            <option value="200">200%</option>
                          </select>
                        )}
                      </div>

                      {/* USD Mode Toggle */}
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-gray-600 min-w-[25px]">$</span>
                        <button
                          type="button"
                          onClick={() => setShowUSD(!showUSD)}
                          className={`relative inline-flex h-6 w-11 items-center rounded-full transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-1 ${
                            showUSD 
                              ? 'bg-green-600 focus:ring-green-400' 
                              : 'bg-gray-200 focus:ring-gray-400'
                          }`}
                        >
                          <span
                            className={`inline-block h-4 w-4 transform rounded-full bg-white shadow-lg transition-transform duration-200 ease-in-out ${
                              showUSD ? 'translate-x-6' : 'translate-x-1'
                            }`}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Subjects Selection */}
                  <div className="space-y-2">
                    <h4 className="text-sm font-semibold text-gray-700 mb-3">Selected Subjects</h4>
                    <Autocomplete
                      multiple
                      id="subjects"
                      options={SUBJECTS}
                      value={student.subjects || []}
                      onChange={(_, newValue) => updateSubjectFees(index, newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select subjects"
                          size="small"
                          className="w-full"
                        />
                      )}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>

              {/* Display generated ID if available */}
              {student.studentId && (
                <div className="mt-4 p-3 bg-gray-50 rounded-lg">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Student ID
                  </label>
                  <div className="text-lg font-mono font-semibold text-accent">
                    {student.studentId}
                  </div>
                  <p className="text-xs text-gray-500 mt-1">
                    Format: YY-BOARD-GRADE-SEQUENCE
                  </p>
                </div>
              )}
              
              {/* Fee Details */}
              {student.subjects.length > 0 && (
                <div className="mt-4">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-semibold">Fee Details</h3>
                    <button
                      onClick={() => generateInvoice(students[index])}
                      className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                      <Download className="w-4 h-4 mr-2" />
                      Download Invoice
                    </button>
                  </div>
                  <div className="space-y-2">
                    {/* Subject-wise Fee Breakdown */}
                    <div className="mb-4">
                      <h4 className="text-sm font-medium text-gray-900 mb-2 text-center">Subject-wise Fees</h4>
                      {student.startDate && student.endDate && (
                        <div className="text-xs text-gray-500 mb-2 text-center">
                          Period: {formatDate(student.startDate)} to {formatDate(student.endDate)} ({calculateDaysBetweenDates(student.startDate, student.endDate)} days)
                        </div>
                      )}
                      <div className="space-y-2">
                        {student.subjects.map((subject, subjectIndex) => (
                          <div key={subjectIndex} className="flex justify-between items-center text-sm border-b border-gray-100 py-2">
                            <div>
                              <span className="font-medium">{subject}</span>
                              <div className="text-xs text-gray-600">
                                {formatFeeAmount(student.feeCalculation?.subjectFees[subjectIndex].totalFee)}
                              </div>
                            </div>
                            <div className="text-right">
                              <span className="font-medium">{formatFeeAmount(student.feeCalculation?.subjectFees[subjectIndex].totalFee)}</span>
                              <div className="text-xs text-gray-600">
                                for {calculateDaysBetweenDates(student.startDate, student.endDate)} days
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      
                      <div className="mt-4 pt-4 border-t">
                        <div className="flex justify-between items-center text-sm font-medium">
                          <span>Subtotal:</span>
                          <span>{formatFeeAmount(student.feeCalculation?.subtotal || 0)}</span>
                        </div>
                        
                        {student.feeCalculation?.discount?.subjectDiscount?.percentage > 0 && (
                          <div className="flex justify-between items-center text-sm text-red-600">
                            <span>Subject Discount ({student.feeCalculation.discount.subjectDiscount.percentage}%):</span>
                            <span>-{formatFeeAmount(student.feeCalculation.discount.subjectDiscount.amount || 0)}</span>
                          </div>
                        )}
                        
                        {showScholarship && (
                          <div className="flex justify-between items-center text-sm text-red-600">
                            <span>Scholarship ({scholarshipPercentage}%):</span>
                            <span>-{formatFeeAmount(student.feeCalculation?.discount?.scholarshipDiscount?.amount || 0)}</span>
                          </div>
                        )}
                        
                        <div className="flex justify-between items-center text-sm">
                          <span>Base Amount:</span>
                          <span>{formatFeeAmount(student.feeCalculation?.gst?.baseAmount || 0)}</span>
                        </div>
                        
                        {showGST && (
                          <div className="flex justify-between items-center text-sm">
                            <span>GST (18%):</span>
                            <span>{formatFeeAmount(student.feeCalculation?.gst?.amount || 0)}</span>
                          </div>
                        )}
                        
                        <div className="flex justify-between items-center text-base font-semibold mt-2">
                          <span>Total Amount:</span>
                          <span>{formatFeeAmount(student.feeCalculation?.totalFees || 0)}</span>
                        </div>
                      </div>
                    </div>
                    
                    {/* Separator */}
                    <div className="border-t border-gray-200 my-4"></div>

                    {/* Payment Schedule Section */}
                    <div className="mt-4 border-t pt-4">
                      <div className="font-semibold mb-2">Payment Schedule</div>
                      <div className="space-y-2">
                        {student.feeCalculation?.installments.map((installment, idx) => (
                          <div key={idx} className="flex justify-between items-center bg-white p-2 rounded border">
                            <div>
                              <div className="font-medium">
                                {student.feeCalculation.installments.length === 1 
                                  ? 'Full Payment'
                                  : `Installment ${idx + 1} of ${student.feeCalculation.installments.length}`}
                              </div>
                              <div className="text-sm text-gray-600">
                                Due: {formatDate(installment.dueDate)}
                              </div>
                            </div>
                            <div className="flex flex-col items-end">
                              <div className="font-medium">{formatFeeAmount(installment.amount)}</div>
                              <div className="text-sm text-orange-600">
                                Pending
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              {/* Contact Information Section */}
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-4">Contact Information</h3>
                <div className="space-y-4">
                  {student.contacts?.map((contact, contactIndex) => (
                    <div key={contactIndex} className="flex flex-wrap gap-4 items-start p-4 border rounded-lg">
                      <div className="flex-1">
                        <TextField
                          label="Contact Name"
                          fullWidth
                          value={contact.name}
                          onChange={(e) => handleContactChange(index, contactIndex, 'name', e.target.value)}
                        />
                      </div>
                      <div className="flex-1">
                        <TextField
                          label="Phone Number"
                          fullWidth
                          value={contact.phone}
                          onChange={(e) => handleContactChange(index, contactIndex, 'phone', e.target.value)}
                        />
                      </div>
                      <div className="flex-1">
                        <Select
                          value={{ value: contact.relation, label: contact.relation }}
                          options={[
                            { value: 'Parent', label: 'Parent' },
                            { value: 'Guardian', label: 'Guardian' },
                            { value: 'Student', label: 'Student' },
                            { value: 'Other', label: 'Other' }
                          ]}
                          onChange={(option) => handleContactChange(index, contactIndex, 'relation', option?.value as 'Parent' | 'Guardian' | 'Student' | 'Other')}
                          className="min-w-[200px]"
                        />
                      </div>
                      <IconButton 
                        onClick={() => removeContact(index, contactIndex)}
                        className="text-red-500"
                      >
                        <X size={20} />
                      </IconButton>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => addContact(index)}
                    className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700"
                  >
                    <Plus size={16} />
                    Add Contact
                  </button>
                </div>
              </div>

              {/* Subject Selection */}
              <div className="space-y-4">
                {student.subjects.map((subject, subjectIndex) => (
                  <div key={subjectIndex} className="flex flex-col space-y-2">
                    <div className="flex items-center space-x-2">
                      <select
                        value={subject}
                        onChange={(e) => handleSubjectChange(index, e.target.value)}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                      >
                        <option value="">Select Subject</option>
                        {SUBJECTS.map((subj) => (
                          <option key={subj} value={subj}>
                            {subj}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        onClick={() => handleRemoveSubject(index, subjectIndex)}
                        className="p-2 text-red-600 hover:text-red-800"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              {/* Phone Numbers Section */}
              <div className="space-y-4 mt-4">
                <div className="flex items-center justify-between">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone Numbers <span className="text-red-500">*</span>
                  </label>
                  <button
                    type="button"
                    onClick={() => addPhoneNumber(index)}
                    className="text-sm text-accent hover:text-accent-dark flex items-center"
                  >
                    <Plus className="w-4 h-4 mr-1" />
                    Add Phone Number
                  </button>
                </div>
                
                {errors[index]?.phoneNumbers && (
                  <p className="text-red-500 text-xs">{errors[index].phoneNumbers}</p>
                )}

                {student.phoneNumbers.map((phone, phoneIndex) => (
                  <div key={phoneIndex} className="flex items-start space-x-4 bg-gray-50 p-3 rounded-lg relative">
                    <div className="flex-grow grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div>
                        <label className="block text-xs text-gray-500 mb-1">
                          Number (with country code) <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          value={phone.number}
                          onChange={(e) => handlePhoneChange(index, phoneIndex, 'number', formatPhoneNumber(e.target.value))}
                          placeholder="+91XXXXXXXXXX"
                          className="input-field"
                          required
                        />
                      </div>
                      
                      <div>
                        <label className="block text-xs text-gray-500 mb-1">
                          Relation <span className="text-red-500">*</span>
                        </label>
                        <select
                          value={phone.relation}
                          onChange={(e) => handlePhoneChange(index, phoneIndex, 'relation', e.target.value as PhoneRelation)}
                          className="input-field"
                          required
                        >
                          <option value="">Select Relation</option>
                          {PHONE_RELATIONS.map(relation => (
                            <option key={relation} value={relation}>
                              {relation}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label className="block text-xs text-gray-500 mb-1">
                          Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          value={phone.name}
                          onChange={(e) => handlePhoneChange(index, phoneIndex, 'name', e.target.value)}
                          placeholder="Contact person's name"
                          className="input-field"
                          required
                        />
                      </div>
                    </div>

                    <button
                      type="button"
                      onClick={() => removePhoneNumber(index, phoneIndex)}
                      className="text-gray-400 hover:text-red-500 absolute top-2 right-2"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>

              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Student Photo
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  className="input-field"
                />
                {photoError && (
                  <p className="text-red-500 text-xs mt-1">{photoError}</p>
                )}
              </div>
            </div>
          ))}
          {students.length > 0 && (
            <button
              type="submit"
              className="w-full mt-6 btn-primary flex items-center justify-center"
            >
              <Save className="w-5 h-5 mr-2" />
              Save {students.length > 1 ? 'All Students' : 'Student'}
            </button>
          )}
        </form>
      </div>
    </div>
  );
}