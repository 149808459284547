import React, { useState, useEffect } from 'react';
import { CheckCircle, Upload, Plus, X, FileText } from 'lucide-react';
import { collection, addDoc, Timestamp, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Subject, Student } from '../types';
import StudentSelect from './StudentSelect';
import { useAuth } from '../hooks/useAuth';
import { useTeacherPoints } from '../hooks/useTeacherPoints';
import toast from 'react-hot-toast';
import { uploadFileToNextcloud, getNextcloudPath } from '../utils/nextcloud';

interface StudentData {
  studentId: string;
  studentName: string;
  grade: string;
}

interface FormData {
  subject: string;
  chapterNames: string;
  notes: string;
  proposedTestDate: string;
  totalMarks: string;
  testFile: File | null;
  testUrl: string;
  testPath: string;
}

interface TestSubmission {
  studentName: string;
  studentGrade: string;
  subject: string;
  chapterNames: string;
  totalMarks: number;
  testPath: string;
  submissionDateTime: string;
}

const SUBJECTS: Subject[] = [
  // Languages
  'English - First Language',
  'English as a Second Language',
  'French',
  'Spanish',
  'German',
  'Mandarin Chinese',
  'Hindi',
  'Marathi',
  'Sanskrit',
  
  // Sciences
  'Biology',
  'Chemistry',
  'Physics',
  'Combined Science',
  'Co-ordinated Sciences',
  'Environmental Management',
  
  // Mathematics
  'Mathematics - Core and Extended',
  'Additional Mathematics',
  'International Mathematics',
  
  // Humanities and Social Sciences
  'Economics',
  'Geography',
  'History',
  'Global Perspectives',
  'Sociology',
  'Psychology',
  
  // Commerce and Business
  'Accounting',
  'Business Studies',
  'Commerce',
  
  // Technology
  'Computer Science',
  'Design and Technology',
  'ICT',
  
  // Arts and Physical Education
  'Art and Design',
  'Drama',
  'Music',
  'Physical Education (PE)',
  
  // Others
  'Phonics',
  'UOI (Unit of inquiry)'
];

const initialStudentData: StudentData = {
  studentId: '',
  studentName: '',
  grade: ''
};

const initialFormData: FormData = {
  subject: '',
  chapterNames: '',
  notes: '',
  proposedTestDate: '',
  totalMarks: '',
  testFile: null,
  testUrl: '',
  testPath: ''
};

const calculatePoints = (marks: number): number => {
  if (marks < 20 || marks > 140) {
    return 0;
  }

  // Normalize marks to a 0-1 range for scaling
  const normalized_marks = (marks - 20) / (140 - 20);  // Maps 20 to 0 and 140 to 1

  // Non-linear scaling: Boost rewards for marks in the 80-140 range
  let points: number;
  if (marks <= 80) {
    // Slowly increase for marks <= 80
    points = 100 + 300 * (Math.pow(normalized_marks, 1.5));
  } else {
    // Accelerate growth for marks > 80
    points = 400 + 100 * (Math.pow(normalized_marks - 0.5, 2));
  }

  // Ensure points are capped at 500
  return Math.min(500, Math.round(points));
};

export default function TestSubmissionForm() {
  const { user } = useAuth();
  const { addPoints } = useTeacherPoints(user?.id || '', user?.name || '');
  const [formData, setFormData] = useState<FormData>({
    subject: '',
    chapterNames: '',
    notes: '',
    proposedTestDate: '',
    totalMarks: '',
    testFile: null,
    testUrl: '',
    testPath: ''
  });

  const getTestFileLocation = (subject: string, grade: string) => {
    return `https://drive.inkstall.us/index.php/apps/files/files/454071?dir=/Tests%20and%20K-Sheets/${subject}/Test%20Sheet/${grade}`;
  };

  const [students, setStudents] = useState<StudentData[]>([{ ...initialStudentData }]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [testSubmissions, setTestSubmissions] = useState<TestSubmission[]>([]);
  const [isLoadingSubmissions, setIsLoadingSubmissions] = useState(false);
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const addStudent = () => {
    if (students.length < 6) {
      setStudents([...students, { ...initialStudentData }]);
    }
  };

  const removeStudent = (index: number) => {
    if (students.length > 1) {
      setStudents(students.filter((_, i) => i !== index));
    }
  };

  const updateStudent = (index: number, selectedStudent: Student) => {
    const updatedStudents = [...students];
    updatedStudents[index] = {
      studentId: selectedStudent.id,
      studentName: selectedStudent.name,
      grade: selectedStudent.grade
    };
    setStudents(updatedStudents);
    setErrors({ ...errors, student: '' });
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    let isValid = true;

    // Validate that at least one student is selected
    if (!students.some(student => student.studentId)) {
      newErrors.student = 'Please select at least one student';
      isValid = false;
    }

    if (!formData.subject) {
      newErrors.subject = 'Please select a subject';
      isValid = false;
    }

    if (!formData.chapterNames.trim()) {
      newErrors.chapterNames = 'Please enter chapter names';
      isValid = false;
    }

    if (!formData.notes.trim()) {
      newErrors.notes = 'Please enter notes';
      isValid = false;
    }

    if (!formData.proposedTestDate) {
      newErrors.proposedTestDate = 'Please select a test date';
      isValid = false;
    }

    if (!formData.totalMarks) {
      newErrors.totalMarks = 'Please enter total marks';
      isValid = false;
    } else {
      const marks = parseInt(formData.totalMarks);
      if (isNaN(marks) || marks < 20 || marks > 140) {
        newErrors.totalMarks = 'Total marks must be between 20 and 140';
        isValid = false;
      }
    }

    if (!formData.testFile) {
      newErrors.testFile = 'Please upload a test file';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      toast.error('Please fill in all required fields');
      return;
    }

    try {
      setIsSubmitting(true);
      const now = Timestamp.now();
      const submissionDateTime = new Date().toLocaleString();
      const marks = parseInt(formData.totalMarks);
      const points = calculatePoints(marks);

      // Create a test submission for each selected student
      const validStudents = students.filter(student => student.studentId);
      
      const testSubmissions = validStudents.map(student => ({
        teacherId: user!.id,
        teacherName: user!.name,
        studentId: student.studentId,
        studentName: student.studentName,
        studentGrade: student.grade,
        subject: formData.subject,
        chapterNames: formData.chapterNames.trim(),
        notes: formData.notes.trim(),
        proposedTestDate: formData.proposedTestDate,
        totalMarks: marks,
        points: points,
        submissionDateTime,
        testPath: formData.testPath,
        createdAt: now,
        updatedAt: now
      }));

      // Remove any undefined or null values
      const cleanedSubmissions = testSubmissions.map(submission => 
        Object.fromEntries(
          Object.entries(submission).filter(([_, value]) => value !== undefined && value !== null)
        )
      );

      await Promise.all(cleanedSubmissions.map(submission => 
        addDoc(collection(db, 'testSubmissions'), submission)
      ));

      // Add points for each student
      await Promise.all(validStudents.map(() => addPoints('test', points)));

      toast.success(`Test submission${validStudents.length > 1 ? 's' : ''} successful`);
      setFormData(initialFormData);
      setStudents([{ ...initialStudentData }]);
    } catch (error) {
      console.error('Error submitting test:', error);
      toast.error('Failed to submit test. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !user?.id) return;

    const allowedTypes = [
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/pdf', // .pdf
      'image/jpeg', // .jpg, .jpeg
      'image/png', // .png
    ];

    if (!allowedTypes.includes(file.type)) {
      toast.error('Invalid file type. Please upload a .doc, .docx, .pdf, .jpeg, .png, or .jpg file');
      return;
    }

    try {
      setIsUploading(true);
      
      if (!formData.subject || !students.some(student => student.grade)) {
        toast.error('Please select a subject and at least one student before uploading test file');
        return;
      }

      // Get the first student's grade (remove "Grade " prefix if present)
      const studentGrade = students[0].grade.replace('Grade ', '');
      
      // Clean up subject name
      const cleanSubject = formData.subject
        .replace(' - First Language', '')
        .replace(' - Core and Extended', '')
        .replace(' as a Second Language', '');

      // Generate a unique filename with timestamp
      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}-${file.name}`;
      
      // Generate Nextcloud path for test submissions
      const nextcloudPath = getNextcloudPath(
        cleanSubject,
        studentGrade,
        'Test Sheet',
        uniqueFileName
      );
      console.log('File will be saved to:', nextcloudPath);

      // Upload to Nextcloud
      await uploadFileToNextcloud(file, nextcloudPath);

      setFormData(prev => ({
        ...prev,
        testFile: file,
        testUrl: 'Stored in Nextcloud',
        testPath: nextcloudPath
      }));

      toast.success(`Test file uploaded successfully to: ${nextcloudPath}`);
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload test file. Please try again.');
    } finally {
      setIsUploading(false);
      if (event.target) {
        event.target.value = ''; // Reset the input
      }
    }
  };

  const handleRemoveFile = () => {
    setFormData(prev => ({
      ...prev,
      testFile: null,
      testUrl: '',
      testPath: ''
    }));
    toast.success('File reference removed successfully');
  };

  useEffect(() => {
    const fetchTestSubmissions = async () => {
      if (!user?.id) return;
      
      setIsLoadingSubmissions(true);
      try {
        const q = query(
          collection(db, 'testSubmissions'),
          where('teacherId', '==', user.id),
          orderBy('createdAt', 'desc')
        );
        
        const querySnapshot = await getDocs(q);
        const submissions = querySnapshot.docs.map(doc => doc.data() as TestSubmission);
        setTestSubmissions(submissions);
      } catch (error) {
        console.error('Error fetching test submissions:', error);
        toast.error('Failed to load test submissions');
      } finally {
        setIsLoadingSubmissions(false);
      }
    };

    fetchTestSubmissions();
  }, [user?.id]);

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <h2 className="text-xl font-bold text-gray-700 mb-2">Please Log In</h2>
          <p className="text-gray-500">You must be logged in to submit tests.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto py-8 space-y-8">
      <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-md p-6 space-y-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <CheckCircle className="w-6 h-6 text-accent mr-2" />
            <h2 className="text-xl font-bold text-secondary">Test Submission Form</h2>
          </div>
          <div className="text-sm text-gray-500">
            Submitting as: <span className="font-medium text-accent">
              {user.role === 'admin' ? 'Admin' : user.name}
            </span>
          </div>
        </div>

        <div className="space-y-6">
          {/* Current Date Display */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Submission Date
            </label>
            <input
              type="text"
              value={currentDate}
              disabled
              className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-50 py-2 px-3 shadow-sm focus:border-accent focus:outline-none focus:ring-1 focus:ring-accent sm:text-sm"
            />
          </div>

          {/* Proposed Test Date */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Proposed Test Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              value={formData.proposedTestDate}
              onChange={(e) => {
                setFormData({ ...formData, proposedTestDate: e.target.value });
                setErrors({ ...errors, proposedTestDate: '' });
              }}
              min={new Date().toISOString().split('T')[0]}
              required
              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-accent focus:outline-none focus:ring-1 focus:ring-accent sm:text-sm"
            />
            {errors.proposedTestDate && (
              <p className="mt-1 text-sm text-red-600">{errors.proposedTestDate}</p>
            )}
          </div>

          {/* Total Marks */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Total Marks (20-140) <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              value={formData.totalMarks}
              onChange={(e) => {
                setFormData({ ...formData, totalMarks: e.target.value });
                setErrors({ ...errors, totalMarks: '' });
              }}
              min="20"
              max="140"
              required
              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-accent focus:outline-none focus:ring-1 focus:ring-accent sm:text-sm"
            />
            {formData.totalMarks && !isNaN(parseInt(formData.totalMarks)) && (
              <div className="mt-2 text-sm">
                <span className="font-medium text-accent">
                  Points: {calculatePoints(parseInt(formData.totalMarks))}
                </span>
                <span className="text-gray-500 ml-2">
                  {parseInt(formData.totalMarks) <= 80 
                    ? "(Points increase slowly up to 80 marks)"
                    : "(Bonus points for marks above 80!)"}
                </span>
              </div>
            )}
            {errors.totalMarks && (
              <p className="mt-1 text-sm text-red-600">{errors.totalMarks}</p>
            )}
          </div>

          {/* Student Selection */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <label className="block text-sm font-medium text-gray-700">
                Students <span className="text-red-500">*</span>
              </label>
              {students.length < 6 && (
                <button
                  type="button"
                  onClick={addStudent}
                  className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-accent hover:bg-accent-dark focus:outline-none"
                >
                  <Plus className="h-4 w-4 mr-1" />
                  Add Student
                </button>
              )}
            </div>
            
            {students.map((student, index) => (
              <div key={index} className="space-y-2">
                <div className="flex items-center space-x-2">
                  <div className="flex-grow">
                    <StudentSelect
                      onSelect={(selected) => updateStudent(index, selected)}
                    />
                  </div>
                  {students.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeStudent(index)}
                      className="p-1 text-red-500 hover:text-red-700"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  )}
                </div>
                {student.grade && (
                  <div className="flex items-center space-x-2 ml-2">
                    <span className="text-sm text-gray-700 bg-gray-100 px-2 py-1 rounded">
                      {student.grade.replace('Grade ', '')}
                    </span>
                  </div>
                )}
              </div>
            ))}
            {errors.student && (
              <p className="mt-1 text-sm text-red-600">{errors.student}</p>
            )}
          </div>

          {/* Subject Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Subject <span className="text-red-500">*</span>
            </label>
            <select
              value={formData.subject}
              onChange={(e) => {
                setFormData({ ...formData, subject: e.target.value });
                setErrors({ ...errors, subject: '' });
              }}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-accent focus:outline-none focus:ring-1 focus:ring-accent sm:text-sm"
            >
              <option value="">Select Subject</option>
              {SUBJECTS.map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
            {errors.subject && (
              <p className="mt-1 text-sm text-red-600">{errors.subject}</p>
            )}
          </div>

          {/* Chapter Names */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Chapter Name(s) <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.chapterNames}
              onChange={(e) => {
                setFormData({ ...formData, chapterNames: e.target.value });
                setErrors({ ...errors, chapterNames: '' });
              }}
              placeholder="Enter chapter names"
              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-accent focus:outline-none focus:ring-1 focus:ring-accent sm:text-sm"
            />
            {errors.chapterNames && (
              <p className="mt-1 text-sm text-red-600">{errors.chapterNames}</p>
            )}
          </div>

          {/* Notes */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Notes <span className="text-red-500">*</span>
            </label>
            <textarea
              value={formData.notes}
              onChange={(e) => {
                setFormData({ ...formData, notes: e.target.value });
                setErrors({ ...errors, notes: '' });
              }}
              placeholder="Enter notes"
              rows={4}
              className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-accent focus:outline-none focus:ring-1 focus:ring-accent sm:text-sm"
            />
            {errors.notes && (
              <p className="mt-1 text-sm text-red-600">{errors.notes}</p>
            )}
          </div>

          {/* Test File Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Upload Test File <span className="text-red-500">*</span>
            </label>
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-4">
                <label className="cursor-pointer bg-white px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                  <span className="flex items-center space-x-2">
                    <Upload className="h-4 w-4" />
                    <span>{isUploading ? 'Uploading...' : 'Upload File'}</span>
                  </span>
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleFileUpload}
                    accept=".doc,.docx,.pdf,.jpg,.jpeg,.png"
                    disabled={isUploading}
                  />
                </label>
                {formData.testFile && (
                  <>
                    <span className="text-sm text-gray-500">
                      {formData.testFile.name}
                    </span>
                    <button
                      type="button"
                      onClick={handleRemoveFile}
                      className="text-sm text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </>
                )}
              </div>
              {formData.testPath && (
                <div className="text-sm text-gray-500">
                  File location: {formData.testPath}
                </div>
              )}
            </div>
            {errors.testFile && (
              <p className="mt-1 text-sm text-red-600">{errors.testFile}</p>
            )}
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-accent hover:bg-accent-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Test'}
          </button>
        </div>
      </form>

      {/* Test Correction Details Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center mb-6">
          <FileText className="w-6 h-6 text-accent mr-2" />
          <h2 className="text-xl font-bold text-secondary">Test Correction Details</h2>
        </div>

        {isLoadingSubmissions ? (
          <div className="text-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-accent mx-auto"></div>
            <p className="mt-2 text-gray-500">Loading test submissions...</p>
          </div>
        ) : testSubmissions.length === 0 ? (
          <div className="text-center py-4 text-gray-500">
            No test submissions found
          </div>
        ) : (
          <div className="space-y-6">
            {testSubmissions.map((submission, index) => (
              <div 
                key={index} 
                className="border border-gray-200 rounded-lg p-4 hover:border-accent transition-colors"
              >
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm font-medium text-gray-500">Student Name(s)</p>
                    <p className="text-gray-900">{submission.studentName}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Subject</p>
                    <p className="text-gray-900">{submission.subject}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Chapter Name</p>
                    <p className="text-gray-900">{submission.chapterNames}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Total Marks</p>
                    <p className="text-gray-900">{submission.totalMarks}</p>
                  </div>
                  <div className="col-span-2">
                    <p className="text-sm font-medium text-gray-500">Test File Location</p>
                    {submission.testPath ? (
                      <a
                        href={getTestFileLocation(submission.subject, submission.studentGrade)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 underline"
                      >
                        Click here to view
                      </a>
                    ) : (
                      <p className="text-red-500">File removed</p>
                    )}
                  </div>
                  <div className="col-span-2 text-right text-sm text-gray-500">
                    Submitted on: {submission.submissionDateTime}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
