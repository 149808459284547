import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, where, updateDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { DailyUpdate } from '../types';
import { Download, Loader, Users, FileText, ExternalLink } from 'lucide-react';
import { format, subDays } from 'date-fns';
import * as XLSX from 'xlsx';
import toast from 'react-hot-toast';

interface TeacherAttendance {
  id: string;
  teacherId: string;
  teacherName: string;
  date: Date;
  punchInTime: Date;
}

export default function AdminView() {
  const [updates, setUpdates] = useState<DailyUpdate[]>([]);
  const [attendance, setAttendance] = useState<TeacherAttendance[]>([]);
  const [leaveRequests, setLeaveRequests] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [updatesData, attendanceData, leaveData] = await Promise.all([
          fetchUpdates(),
          fetchAttendance(),
          fetchLeaveRequests()
        ]);
        setUpdates(updatesData);
        setAttendance(attendanceData);
        setLeaveRequests(leaveData);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchUpdates = async () => {
    // First, get all daily updates
    const q = query(
      collection(db, 'dailyUpdates'),
      orderBy('createdAt', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    const updates = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toDate(),
      updatedAt: doc.data().updatedAt.toDate()
    })) as DailyUpdate[];

    // Get all unique student names from the updates
    const studentNames = [...new Set(updates.map(update => update.studentName))];

    // Fetch student data for all students
    const studentsQuery = query(
      collection(db, 'students'),
      where('name', 'in', studentNames)
    );
    const studentsSnapshot = await getDocs(studentsQuery);
    const studentData = new Map(
      studentsSnapshot.docs.map(doc => [doc.data().name, doc.data().whatsappGroupName])
    );

    // Merge WhatsApp group names with updates
    return updates.map(update => ({
      ...update,
      whatsappGroupName: studentData.get(update.studentName) || update.whatsappGroupName
    }));
  };

  const fetchAttendance = async () => {
    const thirtyDaysAgo = subDays(new Date(), 30);
    const q = query(
      collection(db, 'teacherAttendance'),
      where('date', '>=', thirtyDaysAgo),
      orderBy('date', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        date: data.date?.toDate?.() || new Date(),
        punchInTime: data.punchInTime?.toDate?.() || new Date()
      };
    }) as TeacherAttendance[];
  };

  const fetchLeaveRequests = async () => {
    const q = query(
      collection(db, 'leaveRequests'),
      orderBy('appliedAt', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  };

  const handleLeaveAction = async (leaveId: string, action: 'approved' | 'rejected', leaveType?: 'paid' | 'unpaid') => {
    try {
      await updateDoc(doc(db, 'leaveRequests', leaveId), {
        status: action,
        leaveType: action === 'approved' ? leaveType : undefined,
        updatedAt: new Date()
      });

      setLeaveRequests(prev => prev.map(leave => 
        leave.id === leaveId 
          ? { ...leave, status: action, leaveType: action === 'approved' ? leaveType : undefined }
          : leave
      ));

      toast.success(`Leave request ${action} successfully`);
    } catch (error) {
      console.error('Error updating leave request:', error);
      toast.error('Failed to update leave request');
    }
  };

  const exportToExcel = () => {
    try {
      const exportData = updates.map(update => ({
        'Date': format(update.createdAt, 'dd/MM/yyyy'),
        'Time': format(update.createdAt, 'HH:mm:ss'),
        'Teacher': update.teacherName || 'Admin',
        'Student Name': update.studentName,
        'Grade': update.grade,
        'Board': update.board,
        'Subject': update.subject,
        'Chapter Name': update.chapterName,
        'Notes': update.notes,
        'K-Sheet Status': update.kSheetGiven,
        'K-Sheet URL': update.kSheetUrl || 'N/A',
        'Chapter Completion': `${update.chapterCompletion}%`
      }));

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Daily Updates');

      // Auto-size columns
      const colWidths = Object.keys(exportData[0] || {}).map(key => ({
        wch: Math.max(
          key.length,
          ...exportData.map(row => String(row[key]).length)
        )
      }));
      ws['!cols'] = colWidths;

      const fileName = `daily_updates_${format(new Date(), 'yyyy-MM-dd')}.xlsx`;
      XLSX.writeFile(wb, fileName);
      toast.success('Excel file downloaded successfully');
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      toast.error('Failed to export data');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader className="w-8 h-8 animate-spin text-accent" />
      </div>
    );
  }

  return (
    <div className="p-6 space-y-8">
      {/* Leave Requests Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold text-secondary mb-6">Leave Requests</h2>
        
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Teacher</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Range</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reason</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {leaveRequests.map(leave => (
                <tr key={leave.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {leave.teacherName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(new Date(leave.startDate), 'dd/MM/yyyy')} - {format(new Date(leave.endDate), 'dd/MM/yyyy')}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {leave.reason}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      leave.status === 'approved' 
                        ? 'bg-green-100 text-green-800'
                        : leave.status === 'rejected'
                        ? 'bg-red-100 text-red-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {leave.status.charAt(0).toUpperCase() + leave.status.slice(1)}
                      {leave.status === 'approved' && leave.leaveType && ` (${leave.leaveType})`}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {leave.status === 'pending' && (
                      <div className="flex gap-2">
                        <div className="relative">
                          <button
                            onClick={() => {
                              if (confirm('Is this a paid leave?\n\nClick "Yes" for paid leave\nClick "No" for unpaid leave')) {
                                handleLeaveAction(leave.id, 'approved', 'paid');
                              } else {
                                handleLeaveAction(leave.id, 'approved', 'unpaid');
                              }
                            }}
                            className="btn-success btn-sm"
                          >
                            Approve
                          </button>
                        </div>
                        <button
                          onClick={() => handleLeaveAction(leave.id, 'rejected')}
                          className="btn-danger btn-sm"
                        >
                          Reject
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Daily Updates Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-secondary">Daily Updates Report</h2>
          <button
            onClick={exportToExcel}
            className="btn-primary flex items-center gap-2"
            disabled={updates.length === 0}
          >
            <Download className="w-5 h-5" />
            Export to Excel
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date & Time</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Teacher</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Student</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subject</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Progress</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">K-Sheet</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {updates.map(update => (
                <tr key={update.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(update.createdAt, 'dd/MM/yyyy HH:mm')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {update.teacherName || 'Admin'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {update.studentName} ({update.grade})
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {update.subject}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {update.chapterCompletion}%
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {update.kSheetGiven === 'yes' && update.kSheetUrl ? (
                      <a
                        href={update.kSheetUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 text-primary hover:text-primary/80"
                      >
                        <FileText className="w-4 h-4" />
                        <span>View K-Sheet</span>
                        <ExternalLink className="w-3 h-3" />
                      </a>
                    ) : (
                      <span className="text-gray-500">
                        {update.kSheetGiven === 'yes' ? 'File not found' : 'No K-Sheet'}
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {update.whatsappGroupName ? (
                      <button
                        className="btn-primary btn-sm flex items-center gap-2"
                        onClick={async () => {
                          try {
                            const toastId = toast.loading('Sending message...');
                            
                            // Format the message according to the template
                            const formattedMessage = `*Date*- ${format(update.createdAt, 'dd/MM/yyyy')}

*Student Name*- ${update.studentName}

*Grade*- ${update.grade}

*Subject*- ${update.subject}

*Chapter Name*- ${update.chapterName}

*Chapter progress info*- ${update.chapterProgress}

*K-Sheet*- ${update.kSheetGiven === 'yes' ? 'Given' : 'Not Given'}

*Lecture info and notes*- ${update.notes || 'No notes provided'}`;

                            console.log('Sending message to:', update.whatsappGroupName);
                            
                            const response = await fetch('/api/send-whatsapp', {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              body: JSON.stringify({
                                phoneNumber: update.whatsappGroupName,
                                message: formattedMessage
                              }),
                            });

                            const data = await response.json();
                            console.log('API Response:', data);

                            if (!response.ok) {
                              throw new Error(data.error || data.message || 'Failed to send message');
                            }

                            toast.dismiss(toastId);
                            toast.success('Message sent successfully!');
                          } catch (error: any) {
                            console.error('Error sending message:', error);
                            toast.dismiss();
                            toast.error(error.message || 'Failed to send message');
                          }
                        }}
                      >
                        Send R
                        <ExternalLink className="w-3 h-3" />
                      </button>
                    ) : (
                      <span className="text-gray-500">No WhatsApp Group</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Teacher Attendance Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center mb-6">
          <Users className="w-6 h-6 mr-2 text-primary" />
          <h2 className="text-2xl font-bold text-secondary">Teacher Attendance (Last 30 Days)</h2>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Teacher</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Punch In Time</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {attendance.map(record => (
                <tr key={record.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(record.date, 'dd/MM/yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {record.teacherName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(record.punchInTime, 'HH:mm')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}