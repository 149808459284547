import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { format, subDays, parseISO, differenceInMinutes } from 'date-fns';
import { Trophy, TrendingUp, Calendar, Target } from 'lucide-react';
import toast from 'react-hot-toast';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend
} from 'recharts';

interface TeacherPoints {
  id: string;
  teacherId: string;
  teacherName: string;
  month: string;
  dailyUpdatePoints: number;
  kSheetPoints: number;
  totalPoints: number;
  createdAt: Date;
  updatedAt: Date;
}

interface DailyData {
  date: string;
  totalPoints: number;
  dailyUpdatePoints: number;
  kSheetPoints: number;
  testPoints: number;
}

interface TeacherStats {
  averageWorkingHours: string;
  averageKSheets: number;
  points: number;
}

interface LeaderboardEntry {
  teacherId: string;
  teacherName: string;
  points: number;
}

const POINTS = {
  DAILY_UPDATE: 30,
  K_SHEET: 100
};

export default function TeacherActivity() {
  const { user } = useAuth();
  const [dailyData, setDailyData] = useState<DailyData[]>([]);
  const [todayPoints, setTodayPoints] = useState({
    dailyUpdatePoints: 0,
    kSheetPoints: 0,
    testPoints: 0,
    totalPoints: 0
  });
  const [loading, setLoading] = useState(true);

  const fetchPoints = async (teacherId: string) => {
    try {
      const dailyUpdatesRef = collection(db, 'dailyUpdates');
      const q = query(
        dailyUpdatesRef,
        where('teacherId', '==', teacherId)
      );

      const querySnapshot = await getDocs(q);
      const thirtyDaysAgo = subDays(new Date(), 30);
      return querySnapshot.docs
        .map(doc => ({
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date(),
          id: doc.id
        }))
        .filter(update => update.createdAt >= thirtyDaysAgo)
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    } catch (error) {
      console.error('Error fetching points:', error);
      toast.error('Failed to fetch activity data');
      throw error;
    }
  };

  const fetchActivityData = async () => {
    if (!user?.id) return;

    try {
      // Get today's updates
      const startOfToday = new Date();
      startOfToday.setHours(0, 0, 0, 0);
      const endOfToday = new Date();
      endOfToday.setHours(23, 59, 59, 999);

      // Get daily updates
      const todayUpdates = await fetchPoints(user.id);

      // Get test submissions
      const testSubmissionsRef = collection(db, 'testSubmissions');
      const testQuery = query(
        testSubmissionsRef,
        where('teacherId', '==', user.id)
      );
      const testSnapshot = await getDocs(testQuery);

      // Filter today's tests in memory
      const todayTests = testSnapshot.docs.filter(doc => {
        const createdAt = doc.data().createdAt?.toDate();
        return createdAt && createdAt >= startOfToday && createdAt <= endOfToday;
      });

      let todayUpdatePoints = 0;
      let todayKSheetPoints = 0;
      let todayTestPoints = 0;

      todayUpdates
        .filter(update => {
          const updateDate = update.createdAt;
          return updateDate >= startOfToday && updateDate <= endOfToday;
        })
        .forEach(update => {
          todayUpdatePoints += POINTS.DAILY_UPDATE;
          if (update.kSheetGiven === 'yes') {
            todayKSheetPoints += POINTS.K_SHEET;
          }
        });

      // Add test submission points
      todayTests.forEach(doc => {
        const test = doc.data();
        todayTestPoints += test.points || 0;
      });

      setTodayPoints({
        dailyUpdatePoints: todayUpdatePoints,
        kSheetPoints: todayKSheetPoints,
        testPoints: todayTestPoints,
        totalPoints: todayUpdatePoints + todayKSheetPoints + todayTestPoints
      });

      // Generate daily data for the last 30 days
      const thirtyDaysAgo = subDays(new Date(), 30);
      thirtyDaysAgo.setHours(0, 0, 0, 0);
      
      // Query for all updates in the last 30 days
      const monthUpdates = await fetchPoints(user.id);
      
      // Filter test submissions for last 30 days in memory
      const monthTests = testSnapshot.docs.filter(doc => {
        const createdAt = doc.data().createdAt?.toDate();
        return createdAt && createdAt >= thirtyDaysAgo;
      });
      
      // Filter and group by date in memory
      const updatesByDate = new Map<string, { 
        dailyUpdatePoints: number; 
        kSheetPoints: number;
        testPoints: number;
      }>();
      
      monthUpdates.forEach(update => {
        const date = format(update.createdAt, 'yyyy-MM-dd');
        
        if (!updatesByDate.has(date)) {
          updatesByDate.set(date, { 
            dailyUpdatePoints: 0, 
            kSheetPoints: 0,
            testPoints: 0 
          });
        }
        
        const dateStats = updatesByDate.get(date)!;
        dateStats.dailyUpdatePoints += POINTS.DAILY_UPDATE;
        if (update.kSheetGiven === 'yes') {
          dateStats.kSheetPoints += POINTS.K_SHEET;
        }
      });

      // Add test points to daily stats
      monthTests.forEach(doc => {
        const test = doc.data();
        const date = format(test.createdAt.toDate(), 'yyyy-MM-dd');
        
        if (!updatesByDate.has(date)) {
          updatesByDate.set(date, { 
            dailyUpdatePoints: 0, 
            kSheetPoints: 0,
            testPoints: 0 
          });
        }
        
        const dateStats = updatesByDate.get(date)!;
        dateStats.testPoints += test.points || 0;
      });

      // Convert to daily data array
      const last30Days = Array.from({ length: 30 }, (_, i) => {
        const date = format(subDays(new Date(), i), 'yyyy-MM-dd');
        const stats = updatesByDate.get(date) || { 
          dailyUpdatePoints: 0, 
          kSheetPoints: 0,
          testPoints: 0 
        };
        
        return {
          date,
          dailyUpdatePoints: stats.dailyUpdatePoints,
          kSheetPoints: stats.kSheetPoints,
          testPoints: stats.testPoints,
          totalPoints: stats.dailyUpdatePoints + stats.kSheetPoints + stats.testPoints
        };
      }).reverse();

      setDailyData(last30Days);
    } catch (error) {
      console.error('Error fetching points:', error);
      toast.error('Failed to load activity data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivityData();
  }, [user?.id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6 p-4">
      <div className="grid grid-cols-1 gap-6">
        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="bg-blue-50 rounded-lg p-4 shadow-md">
            <h4 className="text-sm font-medium text-blue-800 mb-2">Average Daily Updates</h4>
            <div className="text-2xl font-bold text-blue-600">
              {(dailyData.reduce((sum, day) => sum + (day.dailyUpdatePoints / POINTS.DAILY_UPDATE), 0) / 30).toFixed(1)}
            </div>
            <p className="text-sm text-blue-600">updates per day</p>
          </div>

          <div className="bg-green-50 rounded-lg p-4 shadow-md">
            <h4 className="text-sm font-medium text-green-800 mb-2">Average K-Sheets</h4>
            <div className="text-2xl font-bold text-green-600">
              {(dailyData.reduce((sum, day) => sum + (day.kSheetPoints / POINTS.K_SHEET), 0) / 30).toFixed(1)}
            </div>
            <p className="text-sm text-green-600">K-sheets per day</p>
          </div>

          <div className="bg-purple-50 rounded-lg p-4 shadow-md">
            <h4 className="text-sm font-medium text-purple-800 mb-2">Test Submissions</h4>
            <div className="text-2xl font-bold text-purple-600">
              {dailyData.reduce((sum, day) => sum + (day.testPoints > 0 ? 1 : 0), 0)}
            </div>
            <p className="text-sm text-purple-600">tests this month</p>
          </div>

          <div className="bg-yellow-50 rounded-lg p-4 shadow-md">
            <h4 className="text-sm font-medium text-yellow-800 mb-2">Total Points Today</h4>
            <div className="text-2xl font-bold text-yellow-600 flex items-center gap-2">
              <span>{todayPoints.totalPoints}</span>
              <img 
                src="https://static.wixstatic.com/media/abaee8_d543e28c553d4b91a517d1781404fc35~mv2.png" 
                alt="Points Coin"
                className="w-8 h-8 object-contain"
              />
            </div>
            <div className="text-sm text-yellow-600 flex flex-col gap-1">
              <div className="flex items-center gap-1">
                <span>Daily Updates: {todayPoints.dailyUpdatePoints}</span>
                <img 
                  src="https://static.wixstatic.com/media/abaee8_d543e28c553d4b91a517d1781404fc35~mv2.png" 
                  alt="Points Coin"
                  className="w-5 h-5 object-contain"
                />
              </div>
              <div className="flex items-center gap-1">
                <span>K-Sheets: {todayPoints.kSheetPoints}</span>
                <img 
                  src="https://static.wixstatic.com/media/abaee8_d543e28c553d4b91a517d1781404fc35~mv2.png" 
                  alt="Points Coin"
                  className="w-5 h-5 object-contain"
                />
              </div>
              <div className="flex items-center gap-1">
                <span>Test Submissions: {todayPoints.testPoints}</span>
                <img 
                  src="https://static.wixstatic.com/media/abaee8_d543e28c553d4b91a517d1781404fc35~mv2.png" 
                  alt="Points Coin"
                  className="w-5 h-5 object-contain"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Activity Charts */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-lg font-semibold mb-4">Activity Overview</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={dailyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tick={{ fontSize: 12 }}
                  interval={6}
                  tickFormatter={(value) => format(parseISO(value), 'MMM d')}
                />
                <YAxis tick={{ fontSize: 12 }} />
                <Tooltip
                  labelFormatter={(value) => format(parseISO(value as string), 'MMMM d, yyyy')}
                  formatter={(value, name) => {
                    if (name === 'dailyUpdatePoints') return [`${value} (Updates)`, 'Daily Updates'];
                    if (name === 'kSheetPoints') return [`${value} (K-Sheets)`, 'K-Sheets'];
                    if (name === 'testPoints') return [`${value} (Test Submissions)`, 'Test Submissions'];
                    return [value, name];
                  }}
                />
                <Legend />
                <Bar dataKey="dailyUpdatePoints" name="Daily Updates" fill="#FFCC00" />
                <Bar dataKey="kSheetPoints" name="K-Sheets" fill="#00CC88" />
                <Bar dataKey="testPoints" name="Test Submissions" fill="#7A288A" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Points Breakdown Table */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold text-secondary mb-6 flex items-center">
            <Trophy className="w-6 h-6 mr-2 text-primary" />
            Points Breakdown (Last 30 Days)
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Daily Updates</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">K-Sheets</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Test Submissions</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Points</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {[...dailyData].reverse().map((day, index) => (
                  <tr key={day.date} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {format(parseISO(day.date), 'MMM dd, yyyy')}
                      {index === 0 && (
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                          Today
                        </span>
                      )}
                      {index === 1 && (
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                          Yesterday
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{day.dailyUpdatePoints} points</div>
                      <div className="text-xs text-gray-500">
                        ({day.dailyUpdatePoints / POINTS.DAILY_UPDATE} updates)
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{day.kSheetPoints} points</div>
                      <div className="text-xs text-gray-500">
                        ({day.kSheetPoints / POINTS.K_SHEET} sheets)
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{day.testPoints} points</div>
                      <div className="text-xs text-gray-500">
                        ({day.testPoints > 0 ? 1 : 0} submissions)
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {day.totalPoints} points
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {day.dailyUpdatePoints > 0 && (
                        <div className="text-xs">
                          • {day.dailyUpdatePoints / POINTS.DAILY_UPDATE} daily updates × {POINTS.DAILY_UPDATE} points
                        </div>
                      )}
                      {day.kSheetPoints > 0 && (
                        <div className="text-xs">
                          • {day.kSheetPoints / POINTS.K_SHEET} K-sheets × {POINTS.K_SHEET} points
                        </div>
                      )}
                      {day.testPoints > 0 && (
                        <div className="text-xs">
                          • {day.testPoints} test submission points
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="bg-gray-50">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    30-Day Totals
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-semibold text-gray-900">
                      {dailyData.reduce((sum, day) => sum + day.dailyUpdatePoints, 0)} points
                    </div>
                    <div className="text-xs text-gray-500">
                      ({dailyData.reduce((sum, day) => sum + (day.dailyUpdatePoints / POINTS.DAILY_UPDATE), 0)} updates)
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-semibold text-gray-900">
                      {dailyData.reduce((sum, day) => sum + day.kSheetPoints, 0)} points
                    </div>
                    <div className="text-xs text-gray-500">
                      ({dailyData.reduce((sum, day) => sum + (day.kSheetPoints / POINTS.K_SHEET), 0)} sheets)
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-semibold text-gray-900">
                      {dailyData.reduce((sum, day) => sum + day.testPoints, 0)} points
                    </div>
                    <div className="text-xs text-gray-500">
                      ({dailyData.reduce((sum, day) => sum + (day.testPoints > 0 ? 1 : 0), 0)} submissions)
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {dailyData.reduce((sum, day) => sum + day.totalPoints, 0)} points
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    Total activity for past 30 days
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}