import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, orderBy, where } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Student } from '../types';
import { useAuth } from '../hooks/useAuth';

interface SheetStats {
  kSheets: {
    [subject: string]: {
      count: number;
      byTeacher: { [teacherName: string]: number };
    };
  };
  testSheets: {
    [subject: string]: {
      count: number;
      byTeacher: { [teacherName: string]: number };
    };
  };
}

interface StudentWithStats extends Student {
  stats: SheetStats;
}

export default function StudentsTracker() {
  const { user } = useAuth();
  const [students, setStudents] = useState<StudentWithStats[]>([]);
  const [loading, setLoading] = useState(true);
  const [expandedStudent, setExpandedStudent] = useState<string | null>(null);

  useEffect(() => {
    const fetchStudentsData = async () => {
      try {
        // Fetch students
        const studentsRef = collection(db, 'students');
        const studentsQuery = query(studentsRef, orderBy('name'));
        const studentsSnapshot = await getDocs(studentsQuery);
        
        // Get all students basic data
        const studentsData = studentsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          stats: {
            kSheets: {},
            testSheets: {}
          }
        } as StudentWithStats));

        // Fetch daily updates for K-sheets
        const updatesRef = collection(db, 'dailyUpdates');
        const updatesPromises = studentsData.map(async (student) => {
          const studentUpdatesQuery = query(
            updatesRef,
            where('studentId', '==', student.id)
          );
          const updatesSnapshot = await getDocs(studentUpdatesQuery);
          
          updatesSnapshot.docs.forEach(doc => {
            const update = doc.data();
            if (update.kSheetGiven === 'yes' && update.subject) {
              if (!student.stats.kSheets[update.subject]) {
                student.stats.kSheets[update.subject] = {
                  count: 0,
                  byTeacher: {}
                };
              }
              student.stats.kSheets[update.subject].count++;
              
              const teacherName = update.teacherName || 'Unknown Teacher';
              student.stats.kSheets[update.subject].byTeacher[teacherName] = 
                (student.stats.kSheets[update.subject].byTeacher[teacherName] || 0) + 1;
            }
          });
        });

        // Fetch test submissions
        const testsRef = collection(db, 'testSubmissions');
        const testsPromises = studentsData.map(async (student) => {
          const studentTestsQuery = query(
            testsRef,
            where('studentId', '==', student.id)
          );
          const testsSnapshot = await getDocs(studentTestsQuery);
          
          testsSnapshot.docs.forEach(doc => {
            const test = doc.data();
            if (test.subject) {
              if (!student.stats.testSheets[test.subject]) {
                student.stats.testSheets[test.subject] = {
                  count: 0,
                  byTeacher: {}
                };
              }
              student.stats.testSheets[test.subject].count++;
              
              const teacherName = test.teacherName || 'Unknown Teacher';
              student.stats.testSheets[test.subject].byTeacher[teacherName] = 
                (student.stats.testSheets[test.subject].byTeacher[teacherName] || 0) + 1;
            }
          });
        });

        // Wait for all data to be fetched
        await Promise.all([...updatesPromises, ...testsPromises]);
        setStudents(studentsData);
      } catch (error) {
        console.error('Error fetching students data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentsData();
  }, []);

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const getTotalSheets = (stats: SheetStats, type: 'kSheets' | 'testSheets') => {
    return Object.values(stats[type]).reduce((sum, subject) => sum + subject.count, 0);
  };

  if (!user || user.role !== 'admin') {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <h2 className="text-xl font-bold text-gray-700 mb-2">Access Denied</h2>
          <p className="text-gray-500">You don't have permission to view this page.</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <div className="w-8 h-8 border-4 border-accent border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-600">Loading students data...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto py-8 px-4">
      {/* Header */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-secondary">Students Tracker</h1>
        <span className="bg-indigo-50 text-secondary px-4 py-2 rounded-full font-medium">
          Total Students: {students.length}
        </span>
      </div>

      {/* Student Cards */}
      <div className="space-y-6">
        {students.map((student) => (
          <div key={student.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            {/* Student Header */}
            <div className="bg-gray-50 p-6 flex justify-between items-center border-b">
              <div className="flex items-center gap-4">
                <div className="w-10 h-10 bg-secondary text-white rounded-full flex items-center justify-center font-medium">
                  {getInitials(student.name)}
                </div>
                <div>
                  <h3 className="font-semibold text-secondary">{student.name}</h3>
                  <p className="text-sm text-gray-600">
                    Grade {student.grade} • {student.board}
                  </p>
                </div>
              </div>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                student.active 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-red-100 text-red-800'
              }`}>
                {student.active ? 'Active' : 'Inactive'}
              </span>
            </div>

            {/* Metrics */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 p-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-gray-600 text-sm mb-2">Total K-Sheets</p>
                <p className="text-2xl font-semibold text-secondary">
                  {getTotalSheets(student.stats, 'kSheets')}
                </p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-gray-600 text-sm mb-2">Total Test Sheets</p>
                <p className="text-2xl font-semibold text-secondary">
                  {getTotalSheets(student.stats, 'testSheets')}
                </p>
              </div>
            </div>

            {/* Sheets Details */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
              {/* K-Sheets */}
              <div className="bg-gray-50 p-6 rounded-lg">
                <h4 className="text-secondary font-semibold mb-4">K-Sheets</h4>
                <div className="space-y-3">
                  {Object.entries(student.stats.kSheets).length > 0 ? (
                    Object.entries(student.stats.kSheets).map(([subject, data]) => (
                      <div key={`k-${subject}`} className="flex justify-between py-2 border-b border-gray-200 last:border-0">
                        <span className="text-gray-700">{subject}</span>
                        <span className="text-secondary font-medium">{data.count} sheet{data.count !== 1 ? 's' : ''}</span>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-500">No K-sheets recorded</p>
                  )}
                </div>
              </div>

              {/* Test Sheets */}
              <div className="bg-gray-50 p-6 rounded-lg">
                <h4 className="text-secondary font-semibold mb-4">Test Sheets</h4>
                <div className="space-y-3">
                  {Object.entries(student.stats.testSheets).length > 0 ? (
                    Object.entries(student.stats.testSheets).map(([subject, data]) => (
                      <div key={`t-${subject}`} className="flex justify-between py-2 border-b border-gray-200 last:border-0">
                        <span className="text-gray-700">{subject}</span>
                        <span className="text-secondary font-medium">{data.count} sheet{data.count !== 1 ? 's' : ''}</span>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-500">No test sheets recorded</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {students.length === 0 && (
        <div className="text-center py-8 bg-white rounded-lg shadow-md">
          <p className="text-gray-500">No students found</p>
        </div>
      )}
    </div>
  );
}
