import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  Chip,
  Link,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Stack,
  Snackbar,
  Alert,
  Menu,
  Checkbox,
  CircularProgress,
  LinearProgress,
  Paper
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { db } from '../lib/firebase';
import { 
  collection, 
  addDoc, 
  getDocs, 
  updateDoc, 
  doc, 
  Timestamp, 
  query, 
  orderBy, 
  where, 
  deleteDoc,
  onSnapshot,
  runTransaction
} from 'firebase/firestore';
import { storage } from '../lib/firebase';
import { 
  ref, 
  uploadBytes, 
  getDownloadURL 
} from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Info as InfoIcon,
  Assignment as AssignmentIcon,
  PersonAdd as PersonAddIcon,
  OpenInNew as OpenInNewIcon,
  Close as CloseIcon,
  AttachFile as AttachFileIcon,
  Star as StarIcon,
  Person as PersonIcon,
  AccessTime as AccessTimeIcon,
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  CheckBox as CheckBoxIcon
} from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';

const CATEGORIES = ['To Do', 'In Progress', 'Completed'];

const PRIORITIES = [
  { value: 'low', label: 'Low', color: '#4caf50' },
  { value: 'medium', label: 'Medium', color: '#ff9800' },
  { value: 'high', label: 'High', color: '#f44336' }
];

const CATEGORY_STYLES = {
  'To Do': {
    gradient: 'linear-gradient(135deg, #FFD6D6 0%, #FFF3D6 100%)',
    shadow: '0 8px 32px rgba(255, 214, 214, 0.2)',
    color: '#FFD6D6'
  },
  'In Progress': {
    gradient: 'linear-gradient(135deg, #D6F5F2 0%, #E6E9EC 100%)',
    shadow: '0 8px 32px rgba(214, 245, 242, 0.2)',
    color: '#D6F5F2'
  },
  'Completed': {
    gradient: 'linear-gradient(135deg, #E8F6F1 0%, #D6EEF5 100%)',
    shadow: '0 8px 32px rgba(232, 246, 241, 0.2)',
    color: '#E8F6F1'
  }
};

const BUTTON_STYLES = {
  createTask: {
    gradient: 'linear-gradient(45deg, #FF6B6B, #4ECDC4)',
    hoverGradient: 'linear-gradient(45deg, #FF8585, #65E5DC)'
  },
  takeTask: {
    gradient: 'linear-gradient(45deg, #4ECDC4, #556270)',
    hoverGradient: 'linear-gradient(45deg, #65E5DC, #6B7A8A)'
  },
  priority: {
    gradient: 'linear-gradient(45deg, #FF6B6B, #FF8E8E)'
  },
  points: {
    gradient: 'linear-gradient(45deg, #4ECDC4, #45B7AF)'
  },
  assigned: {
    gradient: 'linear-gradient(45deg, #A8E6CF, #1B9AAA)'
  }
};

interface ChecklistItem {
  id: string;
  text: string;
  completed: boolean;
}

interface TaskFile {
  name: string;
  url: string;
  type: string;
}

interface Task {
  id?: string;
  title: string;
  description: string;
  category: string;
  priority: string;
  points: number;
  deadline: Date | Timestamp;
  assigned_to?: string;
  createdBy: string;
  createdAt: Date | Timestamp;
  checklist: ChecklistItem[];
  files: TaskFile[];
  status: string;
}

const TaskDetailsDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: (task: Partial<Task>) => void;
  task?: Task | null;
}> = ({ open, onClose, onSubmit, task }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('medium');
  const [points, setPoints] = useState(0);
  const [deadline, setDeadline] = useState<Date>(new Date());
  const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>([]);
  const [newChecklistItem, setNewChecklistItem] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [existingFiles, setExistingFiles] = useState<TaskFile[]>([]);
  const [uploading, setUploading] = useState(false);

  // Initialize form with task data when editing
  useEffect(() => {
    if (task) {
      setTitle(task.title);
      setDescription(task.description || '');
      setPriority(task.priority);
      setPoints(task.points);
      setDeadline(task.deadline instanceof Date ? task.deadline : new Date(task.deadline.seconds * 1000));
      setChecklistItems(task.checklist || []);
      setExistingFiles(task.files || []);
    } else {
      // Reset form for new task
      setTitle('');
      setDescription('');
      setPriority('medium');
      setPoints(0);
      setDeadline(new Date());
      setChecklistItems([]);
      setExistingFiles([]);
    }
  }, [task]);

  const handleClose = () => {
    setTitle('');
    setDescription('');
    setPriority('medium');
    setPoints(0);
    setDeadline(new Date());
    setChecklistItems([]);
    setNewChecklistItem('');
    setFiles([]);
    setExistingFiles([]);
    onClose();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
    }
  };

  const handleRemoveExistingFile = (fileToRemove: TaskFile) => {
    setExistingFiles(files => files.filter(f => f.url !== fileToRemove.url));
  };

  const handleAddChecklistItem = () => {
    if (newChecklistItem.trim()) {
      setChecklistItems([
        ...checklistItems,
        {
          id: Math.random().toString(36).substr(2, 9),
          text: newChecklistItem.trim(),
          completed: false
        }
      ]);
      setNewChecklistItem('');
    }
  };

  const handleRemoveChecklistItem = (id: string) => {
    setChecklistItems(checklistItems.filter(item => item.id !== id));
  };

  const handleSubmit = async () => {
    if (!title.trim()) return;
    
    setUploading(true);
    try {
      const uploadedFiles: TaskFile[] = [];
      
      // Upload new files to Firebase Storage
      for (const file of files) {
        const storageRef = ref(storage, `task-files/${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        uploadedFiles.push({
          name: file.name,
          url,
          type: file.type
        });
      }

      const updatedTask: Partial<Task> = {
        title: title.trim(),
        description: description.trim(),
        priority,
        points,
        deadline,
        checklist: checklistItems,
        files: [...existingFiles, ...uploadedFiles],
        status: task?.status || 'pending',
        category: task?.category || 'To Do',
        ...(task?.id && { id: task.id }),
        ...(task?.createdBy && { createdBy: task.createdBy }),
        ...(task?.createdAt && { createdAt: task.createdAt })
      };

      onSubmit(updatedTask);
      handleClose();
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="sm" 
        fullWidth
        aria-labelledby="task-dialog-title"
        disableEscapeKeyDown={uploading}
        keepMounted={false}
      >
        <DialogTitle id="task-dialog-title">
          {task ? 'Edit Task' : 'Create New Task'}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 1 }}>
            <TextField
              autoFocus
              label="Title"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              disabled={uploading}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={uploading}
            />
            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                value={priority}
                label="Priority"
                onChange={(e) => setPriority(e.target.value)}
                disabled={uploading}
              >
                {['low', 'medium', 'high'].map((p) => (
                  <MenuItem key={p} value={p}>
                    {p.charAt(0).toUpperCase() + p.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Points"
              type="number"
              fullWidth
              value={points}
              onChange={(e) => setPoints(Number(e.target.value))}
              disabled={uploading}
            />
            <DatePicker
              label="Deadline"
              value={deadline}
              onChange={(newValue) => newValue && setDeadline(newValue)}
              disabled={uploading}
            />
            
            {/* Checklist Section */}
            <Box>
              <Typography variant="subtitle1" gutterBottom>Checklist</Typography>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Add new item"
                  value={newChecklistItem}
                  onChange={(e) => setNewChecklistItem(e.target.value)}
                  disabled={uploading}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !uploading) {
                      handleAddChecklistItem();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={handleAddChecklistItem}
                        disabled={uploading || !newChecklistItem.trim()}
                        sx={{
                          background: 'linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)',
                          color: 'white',
                          '&:hover': {
                            background: 'linear-gradient(45deg, #FF8E8E 30%, #45B7AF 90%)'
                          }
                        }}
                      >
                        Add
                      </Button>
                    )
                  }}
                />
              </Box>
              <List>
                {checklistItems.map((item) => (
                  <ListItem
                    key={item.id}
                    secondaryAction={
                      <IconButton 
                        edge="end" 
                        onClick={() => handleRemoveChecklistItem(item.id)}
                        disabled={uploading}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={item.completed}
                        onChange={(e) => {
                          setChecklistItems(items =>
                            items.map(i =>
                              i.id === item.id
                                ? { ...i, completed: e.target.checked }
                                : i
                            )
                          );
                        }}
                        disabled={uploading}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* Existing Files Section */}
            {existingFiles.length > 0 && (
              <Box>
                <Typography variant="subtitle1" gutterBottom>Existing Files</Typography>
                <List>
                  {existingFiles.map((file, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          onClick={() => handleRemoveExistingFile(file)}
                          disabled={uploading}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemIcon>
                        <AttachFileIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={file.name}
                        secondary={file.type}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {/* New Files Section */}
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                {existingFiles.length > 0 ? 'Add More Files' : 'Upload Files'}
              </Typography>
              <Button
                component="label"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                disabled={uploading}
                sx={{ mt: 1 }}
              >
                Choose Files
                <input
                  type="file"
                  multiple
                  hidden
                  onChange={handleFileChange}
                  accept="*/*"
                />
              </Button>
              {files.length > 0 && (
                <List>
                  {Array.from(files).map((file, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <AttachFileIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={file.name}
                        secondary={file.type}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button 
            onClick={handleClose}
            disabled={uploading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!title.trim() || uploading}
            variant="contained"
            startIcon={uploading ? <CircularProgress size={20} /> : null}
            sx={{
              background: 'linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(45deg, #FF8E8E 30%, #45B7AF 90%)'
              }
            }}
          >
            {uploading ? 'Saving...' : (task ? 'Save Changes' : 'Create Task')}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

const TaskDetailsView: React.FC<{
  task: Task;
  open: boolean;
  onClose: () => void;
  onTakeTask: (taskId: string) => Promise<void>;
  onEdit: (task: Task) => void;
  onDelete: (taskId: string) => Promise<void>;
  currentUserEmail: string;
}> = ({
  task,
  open,
  onClose,
  onTakeTask,
  onEdit,
  onDelete,
  currentUserEmail
}) => {
  const theme = useTheme();

  if (!task) {
    return null;
  }

  const checklistProgress = task.checklist 
    ? Math.round((task.checklist.filter(item => item.completed).length / task.checklist.length) * 100) 
    : 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{task.title}</Typography>
          {task.createdBy === currentUserEmail && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                size="small"
                onClick={() => {
                  onEdit(task);
                  onClose();
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  onDelete(task.id!);
                  onClose();
                }}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: '8px !important' }}>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
            <Chip
              size="small"
              label={`${task.points || 0} Points`}
              sx={{
                background: '#D6FFE1',
                color: '#555'
              }}
            />
            {task.priority === 'high' && (
              <Chip
                size="small"
                label="High Priority"
                sx={{
                  background: '#FFD6D6',
                  color: '#555'
                }}
              />
            )}
          </Box>
          <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-wrap' }}>
            {task.description}
          </Typography>
        </Box>

        {task.checklist && task.checklist.length > 0 && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Progress ({checklistProgress}%)
            </Typography>
            <LinearProgress
              variant="determinate"
              value={checklistProgress}
              sx={{
                height: 8,
                borderRadius: 4,
                mb: 2,
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                '& .MuiLinearProgress-bar': {
                  borderRadius: 4
                }
              }}
            />
            <List disablePadding>
              {task.checklist.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ mb: 0.5 }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <Checkbox
                      edge="start"
                      checked={item.completed}
                      disabled
                      sx={{ 
                        color: '#666',
                        '&.Mui-checked': {
                          color: theme.palette.primary.main
                        }
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.text}
                    sx={{
                      '& .MuiListItemText-primary': {
                        color: item.completed ? '#666' : 'inherit',
                        textDecoration: item.completed ? 'line-through' : 'none'
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: '#666' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <AccessTimeIcon fontSize="small" />
              {format(task.deadline, 'MMM d, yyyy')}
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <PersonIcon fontSize="small" />
              {task.createdBy}
            </Typography>
          </Box>
          {task.files && task.files.length > 0 && (
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <AttachFileIcon fontSize="small" />
              {task.files.length} file(s)
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {!task.assigned_to && (
          <Button
            variant="contained"
            onClick={() => {
              onTakeTask(task.id!);
              onClose();
            }}
            sx={{
              background: theme.palette.primary.main,
              '&:hover': {
                background: theme.palette.primary.dark
              }
            }}
          >
            Take Task
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default function Tasks() {
  const { currentUser } = useAuth();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const [taskDetailsOpen, setTaskDetailsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [editTask, setEditTask] = useState<Task | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  useEffect(() => {
    // Set up real-time listener for tasks
    const tasksRef = collection(db, 'tasks');
    const unsubscribe = onSnapshot(tasksRef, (snapshot) => {
      const updatedTasks = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          deadline: data.deadline?.toDate() || new Date(),
          createdAt: data.createdAt?.toDate() || new Date()
        };
      }) as Task[];
      setTasks(updatedTasks);
      setLoading(false);
    }, (error) => {
      console.error("Error listening to tasks:", error);
      setSnackbar({
        open: true,
        message: 'Error loading tasks. Please refresh the page.',
        severity: 'error'
      });
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleCreateTask = async (task: Partial<Task>) => {
    try {
      const taskRef = await addDoc(collection(db, 'tasks'), {
        ...task,
        createdAt: Timestamp.fromDate(new Date()),
        deadline: Timestamp.fromDate(task.deadline as Date),
        createdBy: currentUser?.email
      });

      setSnackbar({
        open: true,
        message: 'Task created successfully!',
        severity: 'success'
      });
      setTaskDetailsOpen(false);
    } catch (err) {
      console.error('Error creating task:', err);
      setSnackbar({
        open: true,
        message: 'Failed to create task',
        severity: 'error'
      });
    }
  };

  const handleUpdateTask = async (updatedTask: Task) => {
    try {
      const taskRef = doc(db, 'tasks', updatedTask.id!);
      await updateDoc(taskRef, {
        ...updatedTask,
        deadline: Timestamp.fromDate(updatedTask.deadline)
      });

      setSnackbar({
        open: true,
        message: 'Task updated successfully!',
        severity: 'success'
      });

      setDialogOpen(false);
      setEditTask(null);
    } catch (error) {
      console.error('Error updating task:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update task. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    try {
      await deleteDoc(doc(db, 'tasks', taskId));
      setSnackbar({
        open: true,
        message: 'Task deleted successfully!',
        severity: 'success'
      });
      setTaskDetailsOpen(false);
    } catch (err) {
      console.error('Error deleting task:', err);
      setSnackbar({
        open: true,
        message: 'Failed to delete task',
        severity: 'error'
      });
    }
  };

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;
    if (source.droppableId === destination.droppableId) return;

    try {
      const taskRef = doc(db, 'tasks', draggableId);
      await updateDoc(taskRef, {
        category: destination.droppableId
      });

      setSnackbar({
        open: true,
        message: 'Task moved successfully!',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error moving task:', err);
      setSnackbar({
        open: true,
        message: 'Failed to move task',
        severity: 'error'
      });
    }
  };

  const handleTakeTask = async (taskId: string) => {
    if (!currentUser?.email) return;

    try {
      const taskRef = doc(db, 'tasks', taskId);
      
      // Use a transaction to ensure atomic updates
      await runTransaction(db, async (transaction) => {
        const taskDoc = await transaction.get(taskRef);
        
        if (!taskDoc.exists()) {
          throw new Error('Task does not exist!');
        }

        const taskData = taskDoc.data() as Task;
        
        // Check if task is already taken
        if (taskData.assigned_to) {
          throw new Error('Task has already been taken by someone else.');
        }

        // Update the task
        transaction.update(taskRef, {
          assigned_to: currentUser.email,
          category: 'In Progress',
          status: 'in-progress'
        });
      });

      setSnackbar({
        open: true,
        message: 'Task taken successfully!',
        severity: 'success'
      });
      setTaskDetailsOpen(false);
    } catch (error) {
      console.error('Error taking task:', error);
      setSnackbar({
        open: true,
        message: error instanceof Error ? error.message : 'Error taking task. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleTaskClick = (task: Task) => {
    setSelectedTask(task);
    setTaskDetailsOpen(true);
  };

  const handleEditClick = (task: Task) => {
    setEditTask(task);
    setDialogOpen(true);
    setTaskDetailsOpen(false);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: '#fafbfc', minHeight: '100vh' }}>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ 
            fontWeight: 700,
            color: '#1a1a1a',
            fontFamily: 'inherit'
          }}
        >
          Tasks Board
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setTaskDetailsOpen(true)}
          sx={{
            background: 'linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)',
            borderRadius: '8px',
            padding: '10px 24px',
            fontFamily: 'inherit',
            boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
            '&:hover': {
              background: 'linear-gradient(45deg, #FF8E8E 30%, #45B7AF 90%)',
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 20px rgba(0,0,0,0.15)'
            }
          }}
        >
          Create Task
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Grid container spacing={3}>
            {['To Do', 'In Progress', 'Done'].map((category) => (
              <Grid item xs={12} md={4} key={category}>
                <Card
                  sx={{
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.9)',
                    borderRadius: '16px',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.3)'
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {category}
                    </Typography>
                    <Droppable droppableId={category}>
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{ 
                            minHeight: 100,
                            transition: 'background-color 0.2s ease',
                            backgroundColor: snapshot.isDraggingOver ? 'rgba(255,255,255,0.4)' : 'transparent',
                            borderRadius: '12px',
                            padding: 1
                          }}
                        >
                          {tasks
                            .filter(task => task.category === category)
                            .map((task, index) => (
                              <Draggable
                                key={task.id}
                                draggableId={task.id || ''}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Paper
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    onClick={() => handleTaskClick(task)}
                                    sx={{
                                      width: '100%',
                                      height: '200px',
                                      background: '#E0F4FF',
                                      transition: 'all 0.3s ease',
                                      borderRadius: '10px',
                                      boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.1)',
                                      position: 'relative',
                                      overflow: 'hidden',
                                      mb: 2,
                                      '&:hover': {
                                        transform: 'translateY(-4px)',
                                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
                                        background: '#FFE5E5',
                                        '& .first-content': {
                                          opacity: 0,
                                          transform: 'translateY(-10px)'
                                        },
                                        '& .second-content': {
                                          opacity: 1,
                                          transform: 'translateY(0)'
                                        }
                                      }
                                    }}
                                  >
                                    {/* First Content */}
                                    <Box
                                      className="first-content"
                                      sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        transition: 'all 0.3s ease',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        p: 2,
                                        opacity: 1,
                                        transform: 'translateY(0)'
                                      }}
                                    >
                                      {/* Header Section */}
                                      <Box sx={{ mb: 1.5 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                          <Typography variant="h6" sx={{ 
                                            color: '#555', 
                                            fontWeight: 600,
                                            fontSize: '1rem'
                                          }}>
                                            {task.title}
                                          </Typography>
                                          {task.createdBy === currentUser?.email && (
                                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                              <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleEditClick(task);
                                                }}
                                                sx={{
                                                  padding: 0.5,
                                                  color: '#666',
                                                  '&:hover': {
                                                    color: '#1976d2',
                                                    backgroundColor: 'rgba(25, 118, 210, 0.04)'
                                                  }
                                                }}
                                              >
                                                <EditIcon sx={{ fontSize: '1rem' }} />
                                              </IconButton>
                                              <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleDeleteTask(task.id!);
                                                }}
                                                sx={{
                                                  padding: 0.5,
                                                  color: '#666',
                                                  '&:hover': {
                                                    color: '#d32f2f',
                                                    backgroundColor: 'rgba(211, 47, 47, 0.04)'
                                                  }
                                                }}
                                              >
                                                <DeleteIcon sx={{ fontSize: '1rem' }} />
                                              </IconButton>
                                            </Box>
                                          )}
                                        </Box>
                                        
                                        {/* Tags Section */}
                                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                          <Chip
                                            size="small"
                                            label={`${task.points || 0} Points`}
                                            sx={{
                                              height: '20px',
                                              background: '#D6FFE1',
                                              color: '#555',
                                              fontWeight: 600,
                                              '& .MuiChip-label': {
                                                px: 1,
                                                fontSize: '0.75rem'
                                              }
                                            }}
                                          />
                                          {task.priority === 'high' && (
                                            <Chip
                                              size="small"
                                              label="High Priority"
                                              sx={{
                                                height: '20px',
                                                background: '#FFD6D6',
                                                color: '#555',
                                                fontWeight: 600,
                                                '& .MuiChip-label': {
                                                  px: 1,
                                                  fontSize: '0.75rem'
                                                }
                                              }}
                                            />
                                          )}
                                        </Box>
                                      </Box>

                                      {/* Description Section */}
                                      <Typography 
                                        variant="body2" 
                                        sx={{ 
                                          color: '#666',
                                          fontSize: '0.875rem',
                                          lineHeight: 1.4,
                                          display: '-webkit-box',
                                          WebkitLineClamp: 2,
                                          WebkitBoxOrient: 'vertical',
                                          overflow: 'hidden',
                                          mb: 1.5
                                        }}
                                      >
                                        {task.description}
                                      </Typography>

                                      {/* Progress Section */}
                                      {task.checklist && task.checklist.length > 0 && (
                                        <Box sx={{ mb: 1.5 }}>
                                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                            <Typography variant="caption" sx={{ 
                                              color: '#666',
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: 0.5,
                                              fontSize: '0.75rem'
                                            }}>
                                              <CheckBoxIcon sx={{ fontSize: '0.875rem' }} />
                                              Progress
                                            </Typography>
                                            <Typography variant="caption" sx={{ color: '#666', fontSize: '0.75rem' }}>
                                              {task.checklist.filter(item => item.completed).length} / {task.checklist.length}
                                            </Typography>
                                          </Box>
                                          <LinearProgress
                                            variant="determinate"
                                            value={(task.checklist.filter(item => item.completed).length / task.checklist.length) * 100}
                                            sx={{
                                              height: 4,
                                              borderRadius: 2,
                                              backgroundColor: 'rgba(0,0,0,0.05)',
                                              '& .MuiLinearProgress-bar': {
                                                background: '#D6FFE1',
                                                borderRadius: 2
                                              }
                                            }}
                                          />
                                        </Box>
                                      )}

                                      {/* Footer Section */}
                                      <Box sx={{ 
                                        mt: 'auto',
                                        pt: 1,
                                        borderTop: '1px solid rgba(0,0,0,0.06)',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                      }}>
                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                          <Typography variant="caption" sx={{
                                            color: '#666',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5,
                                            fontSize: '0.75rem'
                                          }}>
                                            <AccessTimeIcon sx={{ fontSize: '0.875rem' }} />
                                            {format(task.deadline, 'MMM d, yyyy')}
                                          </Typography>
                                          <Typography variant="caption" sx={{
                                            color: '#666',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5,
                                            fontSize: '0.75rem'
                                          }}>
                                            <PersonIcon sx={{ fontSize: '0.875rem' }} />
                                            {task.createdBy}
                                          </Typography>
                                        </Box>
                                        {task.files?.length > 0 && (
                                          <Typography variant="caption" sx={{
                                            color: '#666',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5,
                                            fontSize: '0.75rem'
                                          }}>
                                            <AttachFileIcon sx={{ fontSize: '0.875rem' }} />
                                            {task.files.length}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>

                                    {/* Second Content (Flip Side) */}
                                    <Box
                                      className="second-content"
                                      sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        opacity: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        transition: 'all 0.3s ease',
                                        transform: 'translateY(10px)',
                                        p: 2,
                                        background: '#FFE5E5'
                                      }}
                                    >
                                      <Typography variant="h6" sx={{ mb: 2, color: '#555', fontSize: '1rem' }}>
                                        Task Details
                                      </Typography>
                                      
                                      <Box sx={{ flex: 1, overflowY: 'auto' }}>
                                        {task.checklist?.map((item, index) => (
                                          <Box 
                                            key={index} 
                                            sx={{ 
                                              display: 'flex', 
                                              alignItems: 'center', 
                                              mb: 1,
                                              '&:last-child': { mb: 0 }
                                            }}
                                          >
                                            <Checkbox
                                              checked={item.completed}
                                              size="small"
                                              sx={{ 
                                                padding: 0.5,
                                                color: '#666',
                                                '&.Mui-checked': {
                                                  color: '#4CAF50'
                                                }
                                              }}
                                            />
                                            <Typography variant="body2" sx={{ color: '#666', fontSize: '0.875rem' }}>
                                              {item.text}
                                            </Typography>
                                          </Box>
                                        ))}
                                      </Box>

                                      {category === 'To Do' && !task.assigned_to && (
                                        <Button
                                          fullWidth
                                          variant="contained"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleTakeTask(task.id!);
                                          }}
                                          sx={{
                                            mt: 2,
                                            background: '#FFB5B5',
                                            color: '#555',
                                            fontWeight: 600,
                                            '&:hover': {
                                              background: '#FFC4C4'
                                            }
                                          }}
                                        >
                                          Take Task
                                        </Button>
                                      )}
                                    </Box>
                                  </Paper>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DragDropContext>
      )}
      <TaskDetailsDialog
        open={taskDetailsOpen}
        onClose={() => {
          setTaskDetailsOpen(false);
          setSelectedTask(null);
        }}
        onSubmit={handleCreateTask}
        task={selectedTask}
      />

      {editTask && (
        <TaskDetailsView
          task={editTask}
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            setEditTask(null);
          }}
          onTakeTask={handleTakeTask}
          onEdit={handleUpdateTask}
          onDelete={handleDeleteTask}
          currentUserEmail={currentUser?.email || ''}
        />
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          variant="filled"
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
