import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../lib/firebase';
import { X, Upload, Loader2, FileText, Lock } from 'lucide-react';
import { Subject } from '../types';
import toast from 'react-hot-toast';

interface Teacher {
  id: string;
  name: string;
  email: string;
  startDate?: string;
  subjects?: string[];
  workHours?: {
    [key: string]: { start: string; end: string };
  };
  salaryType?: 'hourly' | 'monthly';
  monthlySalary?: string;
  hourlyRate?: string;
  aboutMe?: string;
  photo?: string;
  offerLetter?: string;
}

interface EditTeacherModalProps {
  isOpen: boolean;
  onClose: () => void;
  teacher: Teacher;
  onSave: () => void;
}

const DEFAULT_WORK_HOURS = {
  monday: { start: '09:00', end: '17:00' },
  tuesday: { start: '09:00', end: '17:00' },
  wednesday: { start: '09:00', end: '17:00' },
  thursday: { start: '09:00', end: '17:00' },
  friday: { start: '09:00', end: '17:00' },
  saturday: { start: '09:00', end: '17:00' },
  sunday: { start: '09:00', end: '17:00' }
};

export default function EditTeacherModal({
  isOpen,
  onClose,
  teacher,
  onSave
}: EditTeacherModalProps) {
  const [formData, setFormData] = useState<Teacher>({
    ...teacher,
    subjects: teacher.subjects || [],
    workHours: teacher.workHours || DEFAULT_WORK_HOURS,
    startDate: teacher.startDate || new Date().toISOString(),
    salaryType: teacher.salaryType || 'monthly',
    monthlySalary: teacher.monthlySalary || '',
    hourlyRate: teacher.hourlyRate || '',
    aboutMe: teacher.aboutMe || ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const [isUploadingOfferLetter, setIsUploadingOfferLetter] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    setFormData({
      ...teacher,
      subjects: teacher.subjects || [],
      workHours: teacher.workHours || DEFAULT_WORK_HOURS,
      startDate: teacher.startDate || new Date().toISOString(),
      salaryType: teacher.salaryType || 'monthly',
      monthlySalary: teacher.monthlySalary || '',
      hourlyRate: teacher.hourlyRate || '',
      aboutMe: teacher.aboutMe || ''
    });
  }, [teacher]);

  const handlePhotoUpload = async (file: File) => {
    try {
      setIsUploadingPhoto(true);
      
      // Create a reference to the file in Firebase Storage
      const storageRef = ref(storage, `teacher-photos/${teacher.id}/${file.name}`);
      
      // Upload the file
      await uploadBytes(storageRef, file);
      
      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);
      
      // Update the form data with the new photo URL
      setFormData(prev => ({
        ...prev,
        photo: downloadURL
      }));
      
      toast.success('Photo uploaded successfully');
    } catch (error: any) {
      console.error('Error uploading photo:', error);
      toast.error(error.message || 'Failed to upload photo');
    } finally {
      setIsUploadingPhoto(false);
    }
  };

  const handleOfferLetterUpload = async (file: File) => {
    try {
      setIsUploadingOfferLetter(true);
      
      // Create a reference to the file in Firebase Storage
      const storageRef = ref(storage, `offer-letters/${teacher.id}/${file.name}`);
      
      // Upload the file
      await uploadBytes(storageRef, file);
      
      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);
      
      // Update the form data with the new offer letter URL
      setFormData(prev => ({
        ...prev,
        offerLetter: downloadURL
      }));
      
      toast.success('Offer letter uploaded successfully');
    } catch (error: any) {
      console.error('Error uploading offer letter:', error);
      toast.error(error.message || 'Failed to upload offer letter');
    } finally {
      setIsUploadingOfferLetter(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const teacherRef = doc(db, 'users', teacher.id);
      await updateDoc(teacherRef, {
        ...formData,
        updatedAt: new Date().toISOString()
      });

      toast.success('Teacher updated successfully');
      onSave();
      onClose();
    } catch (error: any) {
      console.error('Error updating teacher:', error);
      toast.error(error.message || 'Failed to update teacher');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubjectChange = (subject: Subject) => {
    setFormData(prev => ({
      ...prev,
      subjects: prev.subjects?.includes(subject)
        ? prev.subjects.filter(s => s !== subject)
        : [...(prev.subjects || []), subject]
    }));
  };

  const handlePasswordChange = async () => {
    try {
      setPasswordError('');
      
      if (newPassword !== confirmPassword) {
        setPasswordError('New passwords do not match');
        return;
      }

      if (newPassword.length < 6) {
        setPasswordError('Password must be at least 6 characters long');
        return;
      }

      // Test server connection first
      try {
        const testResponse = await fetch('http://localhost:6001/test', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          },
          credentials: 'include'
        });
        
        console.log('Test response status:', testResponse.status);
        const testData = await testResponse.json();
        console.log('Test response data:', testData);
        
        if (!testResponse.ok) {
          throw new Error('Server test failed');
        }
      } catch (error) {
        console.error('Server connection test failed:', error);
        throw new Error('Cannot connect to server. Please try again later.');
      }

      // Update password
      const response = await fetch('http://localhost:6001/api/admin/update-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          userId: teacher.id,
          newPassword: newPassword
        }),
      });

      console.log('Password update response status:', response.status);
      const responseText = await response.text();
      console.log('Password update response text:', responseText);

      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        console.error('Error parsing response:', e, 'Response text:', responseText);
        throw new Error('Invalid server response');
      }

      if (!response.ok || !data.success) {
        throw new Error(data.message || 'Failed to update password');
      }
      
      toast.success(data.message || 'Password updated successfully');
      setShowPasswordModal(false);
      setNewPassword('');
      setConfirmPassword('');
    } catch (error: any) {
      console.error('Error changing password:', error);
      setPasswordError(error.message || 'Failed to change password');
      toast.error(error.message || 'Failed to change password');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-bold text-gray-900">Edit Teacher</h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Photo Upload */}
            <div className="flex items-center space-x-4">
              <div className="relative w-24 h-24 bg-gray-100 rounded-full flex items-center justify-center overflow-hidden">
                {formData.photo ? (
                  <img
                    src={formData.photo}
                    alt={formData.name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <Upload className="w-8 h-8 text-gray-400" />
                )}
                {isUploadingPhoto && (
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <Loader2 className="w-6 h-6 text-white animate-spin" />
                  </div>
                )}
              </div>
              <div>
                <p className="text-sm font-medium text-gray-700 mb-1">Photo</p>
                <label className="inline-block">
                  <span className="px-3 py-1.5 bg-blue-50 text-blue-600 rounded-md cursor-pointer hover:bg-blue-100 transition-colors">
                    Choose File
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        handlePhotoUpload(file);
                      }
                    }}
                    disabled={isUploadingPhoto}
                  />
                </label>
              </div>
            </div>

            {/* Basic Info */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, name: e.target.value }))
                  }
                  className="input-field"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, email: e.target.value }))
                  }
                  className="input-field"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Start Date
                </label>
                <input
                  type="date"
                  value={formData.startDate ? new Date(formData.startDate).toISOString().split('T')[0] : ''}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, startDate: new Date(e.target.value).toISOString() }))
                  }
                  className="input-field"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Monthly Salary (₹)
                </label>
                <div className="flex items-center space-x-4">
                  <select
                    value={formData.salaryType}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        salaryType: e.target.value as 'monthly' | 'hourly'
                      }))
                    }
                    className="input-field w-32"
                  >
                    <option value="monthly">Monthly</option>
                    <option value="hourly">Hourly</option>
                  </select>
                  <input
                    type="number"
                    value={
                      formData.salaryType === 'monthly'
                        ? formData.monthlySalary
                        : formData.hourlyRate
                    }
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [prev.salaryType === 'monthly'
                          ? 'monthlySalary'
                          : 'hourlyRate']: e.target.value
                      }))
                    }
                    className="input-field flex-1"
                    placeholder={`Enter ${
                      formData.salaryType === 'monthly' ? 'monthly salary' : 'hourly rate'
                    }`}
                  />
                </div>
              </div>
            </div>

            {/* About Me */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                About Me
              </label>
              <textarea
                value={formData.aboutMe}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, aboutMe: e.target.value }))
                }
                className="input-field min-h-[100px]"
                placeholder="Write a brief description..."
              />
            </div>

            {/* Subjects */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3">
                Subjects
              </label>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {[
                  'Psychology',
                  'Global Perspectives',
                  'Physical Education',
                  'Geography',
                  'Combined Science',
                  'Phonics',
                  'Environmental Management',
                  'Economics',
                  'French',
                  'Mathematics - Core and Extended',
                  'Computer Science',
                  'Commerce',
                  'History',
                  'Sanskrit',
                  'Spanish',
                  'UOI',
                  'Art and Design',
                  'International Mathematics',
                  'German',
                  'Marathi',
                  'Music',
                  'English - First Language',
                  'Drama',
                  'English as a Second Language',
                  'Sociology',
                  'Physics',
                  'Accounting',
                  'Mandarin Chinese',
                  'Design and Technology',
                  'Chemistry',
                  'Hindi',
                  'ICT',
                  'Co-ordinated Sciences',
                  'Biology',
                  'Business Studies',
                  'Additional Mathematics'
                ].map((subject) => (
                  <label key={subject} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={formData.subjects?.includes(subject) || false}
                      onChange={() => handleSubjectChange(subject as Subject)}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-sm text-gray-700">{subject}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* Work Hours */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Work Hours
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {Object.entries(formData.workHours || DEFAULT_WORK_HOURS).map(([day, hours]) => (
                  <div key={day} className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-700 capitalize">
                      {day}
                    </h4>
                    <div className="flex items-center space-x-4">
                      <input
                        type="time"
                        value={hours.start}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            workHours: {
                              ...(prev.workHours || DEFAULT_WORK_HOURS),
                              [day]: {
                                ...hours,
                                start: e.target.value
                              }
                            }
                          }))
                        }
                        className="input-field"
                      />
                      <input
                        type="time"
                        value={hours.end}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            workHours: {
                              ...(prev.workHours || DEFAULT_WORK_HOURS),
                              [day]: {
                                ...hours,
                                end: e.target.value
                              }
                            }
                          }))
                        }
                        className="input-field"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Offer Letter Upload */}
            <div className="border-t pt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Documents</h3>
              <div className="flex items-center space-x-4">
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-700 mb-1">Offer Letter (Optional)</p>
                  <div className="flex items-center space-x-4">
                    {formData.offerLetter ? (
                      <div className="flex items-center space-x-2 text-sm text-gray-600">
                        <FileText className="w-4 h-4" />
                        <a 
                          href={formData.offerLetter} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-700"
                        >
                          View Offer Letter
                        </a>
                      </div>
                    ) : (
                      <p className="text-sm text-gray-500">No file chosen</p>
                    )}
                    <label className="inline-block">
                      <span className="px-3 py-1.5 bg-blue-50 text-blue-600 rounded-md cursor-pointer hover:bg-blue-100 transition-colors">
                        Choose File
                      </span>
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        className="hidden"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            handleOfferLetterUpload(file);
                          }
                        }}
                        disabled={isUploadingOfferLetter}
                      />
                    </label>
                  </div>
                </div>
                {isUploadingOfferLetter && (
                  <Loader2 className="w-5 h-5 text-blue-600 animate-spin" />
                )}
              </div>
            </div>

            {/* Password Management */}
            <div className="border-t pt-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">Password Management</h3>
                <button
                  type="button"
                  onClick={() => setShowPasswordModal(true)}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <Lock className="w-4 h-4 mr-2" />
                  Change Password
                </button>
              </div>
            </div>

            {/* Form Actions */}
            <div className="flex items-center justify-between border-t pt-6">
              <button
                type="button"
                onClick={() => {
                  if (confirm('Are you sure you want to delete this teacher? This action cannot be undone.')) {
                    // Handle teacher deletion
                  }
                }}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-600 bg-red-50 rounded-md hover:bg-red-100"
              >
                Delete Teacher
              </button>
              <div className="flex items-center space-x-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                >
                  {isSubmitting ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Password Change Modal */}
      {showPasswordModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium text-gray-900">Change Password</h3>
              <button
                onClick={() => setShowPasswordModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {passwordError && (
              <div className="mb-4 p-2 bg-red-50 text-red-600 text-sm rounded">
                {passwordError}
              </div>
            )}

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  New Password
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  required
                />
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setShowPasswordModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handlePasswordChange}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
