import { collection, query, where, getDocs, addDoc, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import React from 'react';
import { db } from '../lib/firebase';
import { format } from 'date-fns';
import { TeacherPoints } from '../types';
import toast from 'react-hot-toast';
import { POINTS } from '../constants/points';
import { playCoinSound } from '../utils/audio';

export function useTeacherPoints(teacherId: string, teacherName: string) {
  const [currentPoints, setCurrentPoints] = useState<TeacherPoints | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (teacherId) {
      loadCurrentPoints();
    }
  }, [teacherId]);

  const loadCurrentPoints = async () => {
    if (!teacherId) return;

    try {
      setLoading(true);
      const currentMonth = format(new Date(), 'yyyy-MM');
      
      // Create a compound query
      const pointsRef = collection(db, 'teacherPoints');
      const pointsQuery = query(
        pointsRef,
        where('teacherId', '==', teacherId),
        where('month', '==', currentMonth)
      );

      const snapshot = await getDocs(pointsQuery);
      
      if (!snapshot.empty) {
        const data = snapshot.docs[0].data();
        setCurrentPoints({
          id: snapshot.docs[0].id,
          ...data,
          createdAt: data.createdAt.toDate(),
          updatedAt: data.updatedAt.toDate()
        } as TeacherPoints);
      } else {
        // Initialize points for the current month
        const initialPoints = {
          teacherId,
          teacherName,
          month: currentMonth,
          dailyUpdatePoints: 0,
          kSheetPoints: 0,
          totalPoints: 0,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now()
        };

        const docRef = await addDoc(pointsRef, initialPoints);
        setCurrentPoints({
          id: docRef.id,
          ...initialPoints,
          createdAt: initialPoints.createdAt.toDate(),
          updatedAt: initialPoints.updatedAt.toDate()
        });
      }
    } catch (error) {
      console.error('Error loading points:', error);
      // Don't show error toast here as it's not critical for the user experience
    } finally {
      setLoading(false);
    }
  };

  const addPoints = async (type: 'dailyUpdate' | 'kSheet' | 'test', customPoints?: number) => {
    if (!teacherId || !teacherName) return;

    try {
      const currentMonth = format(new Date(), 'yyyy-MM');
      const now = Timestamp.now();
      
      if (!currentPoints) {
        // Create new points record for the month
        const newPoints = {
          teacherId,
          teacherName,
          month: currentMonth,
          dailyUpdatePoints: type === 'dailyUpdate' ? POINTS.DAILY_UPDATE : 0,
          kSheetPoints: type === 'kSheet' ? POINTS.K_SHEET : 0,
          testPoints: type === 'test' ? (customPoints || 0) : 0,
          totalPoints: type === 'test' ? (customPoints || 0) : 
                      type === 'dailyUpdate' ? POINTS.DAILY_UPDATE : POINTS.K_SHEET,
          createdAt: now,
          updatedAt: now
        };

        const docRef = await addDoc(collection(db, 'teacherPoints'), newPoints);
        setCurrentPoints({ 
          id: docRef.id, 
          ...newPoints, 
          createdAt: now.toDate(),
          updatedAt: now.toDate()
        });
      } else {
        // Update existing points
        const pointsToAdd = type === 'test' ? (customPoints || 0) :
                          type === 'dailyUpdate' ? POINTS.DAILY_UPDATE : POINTS.K_SHEET;
        const updates = {
          dailyUpdatePoints: type === 'dailyUpdate' 
            ? currentPoints.dailyUpdatePoints + POINTS.DAILY_UPDATE 
            : currentPoints.dailyUpdatePoints,
          kSheetPoints: type === 'kSheet'
            ? currentPoints.kSheetPoints + POINTS.K_SHEET
            : currentPoints.kSheetPoints,
          testPoints: type === 'test'
            ? (currentPoints.testPoints || 0) + (customPoints || 0)
            : (currentPoints.testPoints || 0),
          totalPoints: currentPoints.totalPoints + pointsToAdd,
          updatedAt: now
        };

        await updateDoc(doc(db, 'teacherPoints', currentPoints.id), updates);
        setCurrentPoints({
          ...currentPoints,
          ...updates,
          updatedAt: now.toDate()
        });
      }

      // Play coin sound when points are awarded
      await playCoinSound();

      toast.custom(
        () => (
          <div className="bg-white rounded-lg shadow-lg p-4">
            <span className="text-gray-800">
              {type === 'test' 
                ? `Earned ${customPoints} points for test submission!`
                : `Earned ${type === 'dailyUpdate' ? POINTS.DAILY_UPDATE : POINTS.K_SHEET} points!`}
            </span>
          </div>
        ),
        {
          duration: 2000,
          position: 'top-center'
        }
      );
    } catch (error) {
      console.error('Error updating points:', error);
      toast.error('Failed to update points');
    }
  };

  return {
    currentPoints,
    loading,
    addPoints
  };
}