import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Pencil, User, Mail, Calendar, IndianRupee, Eye } from 'lucide-react';
import EditTeacherModal from './EditTeacherModal';
import TeacherPreviewModal from './TeacherPreviewModal';
import WorkHoursDisplay from './WorkHoursDisplay';

interface Teacher {
  id: string;
  name: string;
  email: string;
  startDate?: string;
  subjects?: string[];
  workHours?: {
    [key: string]: { start: string; end: string };
  };
  salaryType?: 'hourly' | 'monthly';
  monthlySalary?: string;
  hourlyRate?: string;
  aboutMe?: string;
  photo?: string;
  role?: string;
}

export default function TeachersView() {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<Teacher | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchTeachers();
  }, []);

  const fetchTeachers = async () => {
    try {
      setLoading(true);
      const teachersCollection = collection(db, 'users');
      const teachersSnapshot = await getDocs(teachersCollection);
      const teachersList = teachersSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Teacher))
        .filter(user => user.role === 'teacher');
      setTeachers(teachersList);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError('Failed to fetch teachers. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (teacher: Teacher) => {
    setSelectedTeacher(teacher);
    setIsEditModalOpen(true);
  };

  const handlePreviewClick = (teacher: Teacher) => {
    setSelectedTeacher(teacher);
    setIsPreviewModalOpen(true);
  };

  const formatDate = (dateString?: string) => {
    if (!dateString) return 'Not set';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      return 'Invalid date';
    }
  };

  if (loading) {
    return (
      <div className="p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Teachers</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[1, 2, 3].map((i) => (
            <div key={i} className="relative bg-white rounded-xl shadow-lg p-6 animate-pulse">
              <div className="flex items-center space-x-4">
                <div className="w-16 h-16 bg-gray-200 rounded-full"></div>
                <div className="flex-1">
                  <div className="h-5 bg-gray-200 rounded w-1/2 mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Teachers</h1>
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Teachers</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {teachers.length === 0 ? (
          <div className="col-span-full bg-gray-50 text-gray-600 p-4 rounded-lg text-center">
            No teachers found
          </div>
        ) : (
          teachers.map((teacher) => (
            <div
              key={teacher.id}
              className="relative bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:-translate-y-1 hover:shadow-xl group"
            >
              {/* Card Header with Photo */}
              <div className="absolute inset-0 bg-gradient-to-b from-blue-500/50 to-blue-600/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              
              <div className="relative p-6">
                <div className="flex items-start space-x-4">
                  <div className="w-16 h-16 bg-gradient-to-br from-blue-500 to-blue-600 rounded-full flex items-center justify-center ring-4 ring-white shadow-lg">
                    {teacher.photo ? (
                      <img
                        src={teacher.photo}
                        alt={teacher.name}
                        className="w-full h-full rounded-full object-cover"
                      />
                    ) : (
                      <User className="w-8 h-8 text-white" />
                    )}
                  </div>
                  <div className="flex-1 min-w-0">
                    <h2 className="text-xl font-bold text-gray-900 truncate group-hover:text-blue-600 transition-colors">
                      {teacher.name || 'Unnamed Teacher'}
                    </h2>
                    <div className="mt-1 space-y-2">
                      <p className="flex items-center text-gray-600 text-sm">
                        <Mail className="w-4 h-4 mr-2 text-blue-500" />
                        <span className="truncate">{teacher.email}</span>
                      </p>
                      {teacher.startDate && (
                        <p className="flex items-center text-gray-600 text-sm">
                          <Calendar className="w-4 h-4 mr-2 text-blue-500" />
                          <span>Joined {formatDate(teacher.startDate)}</span>
                        </p>
                      )}
                      {(teacher.salaryType && (teacher.monthlySalary || teacher.hourlyRate)) && (
                        <p className="flex items-center text-gray-600 text-sm">
                          <IndianRupee className="w-4 h-4 mr-2 text-blue-500" />
                          <span>
                            {teacher.salaryType === 'monthly' ? teacher.monthlySalary + '/month' : teacher.hourlyRate + '/hour'}
                          </span>
                        </p>
                      )}
                      {/* Work Hours */}
                      {teacher.workHours && Object.keys(teacher.workHours).length > 0 && (
                        <WorkHoursDisplay workHours={teacher.workHours} />
                      )}
                    </div>
                  </div>
                </div>

                {/* Subjects */}
                {teacher.subjects && teacher.subjects.length > 0 && (
                  <div className="mt-4">
                    <div className="flex flex-wrap gap-2">
                      {teacher.subjects.slice(0, 3).map((subject) => (
                        <span
                          key={subject}
                          className="px-2 py-1 bg-blue-50 text-blue-700 rounded-full text-xs font-medium"
                        >
                          {subject}
                        </span>
                      ))}
                      {teacher.subjects.length > 3 && (
                        <span className="px-2 py-1 bg-gray-50 text-gray-600 rounded-full text-xs font-medium">
                          +{teacher.subjects.length - 3} more
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {/* Action Buttons */}
                <div className="absolute top-4 right-4 flex items-center space-x-2">
                  <button
                    onClick={() => handlePreviewClick(teacher)}
                    className="p-2 text-gray-400 hover:text-blue-600 transition-colors"
                    title="View Details"
                  >
                    <Eye className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleEditClick(teacher)}
                    className="p-2 text-gray-400 hover:text-blue-600 transition-colors"
                    title="Edit Teacher"
                  >
                    <Pencil className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {selectedTeacher && (
        <>
          <EditTeacherModal
            isOpen={isEditModalOpen}
            onClose={() => {
              setIsEditModalOpen(false);
              setSelectedTeacher(null);
            }}
            teacher={selectedTeacher}
            onSave={() => {
              fetchTeachers();
              setIsEditModalOpen(false);
              setSelectedTeacher(null);
            }}
          />
          <TeacherPreviewModal
            isOpen={isPreviewModalOpen}
            onClose={() => {
              setIsPreviewModalOpen(false);
              setSelectedTeacher(null);
            }}
            teacher={selectedTeacher}
          />
        </>
      )}
    </div>
  );
}
