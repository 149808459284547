import React, { useState, useEffect } from 'react';
import { Settings, UserPlus, Clock, Upload } from 'lucide-react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import toast from 'react-hot-toast';
import { Subject } from '../types';
import { format, parse } from 'date-fns';

interface WorkHours {
  start: string;
  end: string;
}

interface TeacherForm {
  email: string;
  password: string;
  name: string;
  photo: File | null;
  startDate: string;
  aboutMe: string;
  subjects: Subject[];
  offerLetter: File | null;
  salaryType: 'hourly' | 'monthly';
  monthlySalary: string;
  hourlyRate: string;
  workHours: {
    monday: WorkHours;
    tuesday: WorkHours;
    wednesday: WorkHours;
    thursday: WorkHours;
    friday: WorkHours;
    saturday: WorkHours;
    sunday: WorkHours;
  };
}

const initialFormData: TeacherForm = {
  email: '',
  password: '',
  name: '',
  photo: null,
  startDate: '',
  aboutMe: '',
  subjects: [],
  offerLetter: null,
  salaryType: 'monthly',
  monthlySalary: '',
  hourlyRate: '',
  workHours: {
    monday: { start: '09:00', end: '17:00' },
    tuesday: { start: '09:00', end: '17:00' },
    wednesday: { start: '09:00', end: '17:00' },
    thursday: { start: '09:00', end: '17:00' },
    friday: { start: '09:00', end: '17:00' },
    saturday: { start: '09:00', end: '17:00' },
    sunday: { start: '09:00', end: '17:00' }
  }
};

// Time format utility functions
const to24Hour = (time12h: string): string => {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');
  
  if (hours === '12') {
    hours = '00';
  }
  
  if (modifier === 'PM') {
    hours = String(parseInt(hours, 10) + 12);
  }
  
  return `${hours.padStart(2, '0')}:${minutes}`;
};

const to12Hour = (time24h: string): string => {
  try {
    const date = parse(time24h, 'HH:mm', new Date());
    return format(date, 'hh:mm aa');
  } catch (error) {
    console.error('Error converting time:', error);
    return time24h;
  }
};

export default function SettingsView() {
  const { user } = useAuth();
  const [formData, setFormData] = useState<TeacherForm>(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'photo' | 'offerLetter') => {
    const file = event.target.files?.[0];
    if (file) {
      setFormData(prev => ({ ...prev, [type]: file }));
    }
  };

  const handleSubjectChange = (subject: Subject) => {
    setFormData(prev => ({
      ...prev,
      subjects: prev.subjects.includes(subject)
        ? prev.subjects.filter(s => s !== subject)
        : [...prev.subjects, subject]
    }));
  };

  const handleWorkHoursChange = (day: keyof TeacherForm['workHours'], type: 'start' | 'end', value: string) => {
    setFormData(prev => ({
      ...prev,
      workHours: {
        ...prev.workHours,
        [day]: {
          ...prev.workHours[day],
          [type]: value
        }
      }
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      // Add teacher data to Firestore
      const teacherData = {
        name: formData.name,
        email: formData.email,
        startDate: formData.startDate,
        aboutMe: formData.aboutMe,
        subjects: formData.subjects,
        salaryType: formData.salaryType,
        monthlySalary: formData.salaryType === 'monthly' ? formData.monthlySalary : '',
        hourlyRate: formData.salaryType === 'hourly' ? formData.hourlyRate : '',
        workHours: formData.workHours,
        role: 'teacher',
        createdAt: new Date().toISOString(),
      };

      await setDoc(doc(db, 'users', userCredential.user.uid), teacherData);

      toast.success('Teacher added successfully!');
      // Reset form
      setFormData(initialFormData);
    } catch (error: any) {
      console.error('Error adding teacher:', error);
      toast.error(error.message || 'Failed to add teacher');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!user) {
    return (
      <div className="p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold text-secondary mb-4 flex items-center">
            <Settings className="w-6 h-6 mr-2" />
            Settings
          </h2>
          <p className="text-gray-500">Please sign in to access settings.</p>
        </div>
      </div>
    );
  }

  // Non-admin view
  if (user.role !== 'admin') {
    return (
      <div className="p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold text-secondary mb-4 flex items-center">
            <Settings className="w-6 h-6 mr-2" />
            Settings
          </h2>
          <p className="text-gray-500 mb-2">Welcome to your settings page!</p>
          <p className="text-gray-500">To add new teachers to the system, please contact your administrator.</p>
        </div>
      </div>
    );
  }

  // Admin view
  return (
    <div className="p-6">
      {/* Add Teacher Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-bold text-secondary flex items-center">
            <UserPlus className="w-6 h-6 mr-2" />
            Add New Teacher
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Photo Upload */}
          <div className="flex items-center space-x-4">
            <div className="w-24 h-24 bg-gray-100 rounded-full flex items-center justify-center">
              {formData.photo ? (
                <img
                  src={URL.createObjectURL(formData.photo)}
                  alt="Teacher"
                  className="w-full h-full rounded-full object-cover"
                />
              ) : (
                <Upload className="w-8 h-8 text-gray-400" />
              )}
            </div>
            <div>
              <p className="text-sm font-medium text-gray-700 mb-1">Photo</p>
              <label className="inline-block">
                <span className="text-blue-600 cursor-pointer">Choose File</span>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, 'photo')}
                  className="hidden"
                />
              </label>
              <p className="text-sm text-gray-500 ml-2 inline-block">
                {formData.photo ? formData.photo.name : 'No file chosen'}
              </p>
            </div>
          </div>

          {/* Basic Info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Teacher Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="input-field"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                className="input-field"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                type="password"
                value={formData.password}
                onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                className="input-field"
                required
                minLength={6}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={formData.startDate}
                onChange={(e) => setFormData(prev => ({ ...prev, startDate: e.target.value }))}
                className="input-field"
                required
              />
            </div>
          </div>

          {/* About Me */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              About Me
            </label>
            <textarea
              value={formData.aboutMe}
              onChange={(e) => setFormData(prev => ({ ...prev, aboutMe: e.target.value }))}
              className="input-field min-h-[100px]"
              placeholder="Write a brief description about yourself..."
            />
          </div>

          {/* Subjects */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">
              Subjects
            </label>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              {[
                'Psychology', 'Global Perspectives', 'Physical Education', 'Geography',
                'Combined Science', 'Phonics', 'Environmental Management', 'Economics',
                'French', 'Mathematics - Core and Extended', 'Computer Science', 'Commerce',
                'History', 'Sanskrit', 'Spanish', 'UOI',
                'Art and Design', 'International Mathematics', 'German', 'Marathi',
                'Music', 'English - First Language', 'Drama', 'English as a Second Language',
                'Sociology', 'Physics', 'Accounting', 'Mandarin Chinese',
                'Design and Technology', 'Chemistry', 'Hindi',
                'ICT', 'Co-ordinated Sciences', 'Biology', 'Business Studies',
                'Additional Mathematics'
              ].map((subject) => (
                <label key={subject} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.subjects.includes(subject as Subject)}
                    onChange={() => handleSubjectChange(subject as Subject)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-700">{subject}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Offer Letter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Offer Letter (Optional)
            </label>
            <div className="flex items-center space-x-2">
              <label className="inline-block">
                <span className="text-blue-600 cursor-pointer">Choose File</span>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  onChange={(e) => handleFileChange(e, 'offerLetter')}
                  className="hidden"
                />
              </label>
              <span className="text-sm text-gray-500">
                {formData.offerLetter ? formData.offerLetter.name : 'No file chosen'}
              </span>
            </div>
          </div>

          {/* Salary Type */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">
              Salary Type
            </label>
            <div className="flex items-center space-x-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="hourly"
                  checked={formData.salaryType === 'hourly'}
                  onChange={(e) => setFormData(prev => ({ ...prev, salaryType: 'hourly' }))}
                  className="text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm text-gray-700">Hourly Rate</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="monthly"
                  checked={formData.salaryType === 'monthly'}
                  onChange={(e) => setFormData(prev => ({ ...prev, salaryType: 'monthly' }))}
                  className="text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm text-gray-700">Monthly Salary</span>
              </label>
            </div>
          </div>

          {/* Salary Input Fields */}
          {formData.salaryType === 'monthly' ? (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Monthly Salary (₹)
              </label>
              <input
                type="number"
                value={formData.monthlySalary}
                onChange={(e) => setFormData(prev => ({ ...prev, monthlySalary: e.target.value }))}
                className="input-field"
                placeholder="Enter monthly salary"
              />
            </div>
          ) : (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Hourly Rate (₹)
              </label>
              <input
                type="number"
                value={formData.hourlyRate}
                onChange={(e) => setFormData(prev => ({ ...prev, hourlyRate: e.target.value }))}
                className="input-field"
                placeholder="Enter hourly rate"
              />
            </div>
          )}

          {/* Work Hours */}
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
              <Clock className="w-5 h-5 mr-2 text-gray-500" />
              Work Hours
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {Object.entries(formData.workHours).map(([day, hours]) => (
                <div key={day} className="space-y-2">
                  <h4 className="text-sm font-medium text-gray-700 capitalize">{day}</h4>
                  <div className="flex items-center space-x-4">
                    <div className="flex-1">
                      <label className="block text-xs text-gray-500 mb-1">Start Time</label>
                      <input
                        type="time"
                        value={hours.start}
                        onChange={(e) => handleWorkHoursChange(day as keyof TeacherForm['workHours'], 'start', e.target.value)}
                        className="input-field"
                      />
                      <div className="text-xs text-gray-500 mt-1">
                        {to12Hour(hours.start)}
                      </div>
                    </div>
                    <div className="flex-1">
                      <label className="block text-xs text-gray-500 mb-1">End Time</label>
                      <input
                        type="time"
                        value={hours.end}
                        onChange={(e) => handleWorkHoursChange(day as keyof TeacherForm['workHours'], 'end', e.target.value)}
                        className="input-field"
                      />
                      <div className="text-xs text-gray-500 mt-1">
                        {to12Hour(hours.end)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-yellow-400 hover:bg-yellow-500 text-black py-3 px-4 rounded-lg flex items-center justify-center space-x-2"
          >
            <UserPlus className="w-5 h-5" />
            <span>{isSubmitting ? 'Adding Teacher...' : 'Add Teacher'}</span>
          </button>
        </form>
      </div>
    </div>
  );
}